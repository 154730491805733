import * as React from 'react';
import styled from 'styled-components';
import { IDetachableInnerProps as IDetachableStoreInnerProps } from '../detachableStoreHoc';

// Props you want the resulting component to take (besides the props of the wrapped component)
interface ExternalProps {
  animationState: string;
}

// Props the HOC adds to the wrapped component
interface InjectedProps {}

// Props that are required for the wrapped component to accept
type IOriginalProps = Omit<IDetachableStoreInnerProps<any>, 'item'>;
// Options for the HOC factory that are not dependent on props values
interface IHOCOptions {}

const AnimationDiv = styled.div<{ animationState: string }>`
  transition-property: opacity;
  transition-duration: 0.4s;
  opacity: ${({ animationState }) => {
    if (animationState === 'entering') return 0;
    if (animationState === 'entered') return 1;
    if (animationState === 'exiting') return 0;
    if (animationState === 'exited') return 0;
    return 0;
  }};
`;

export const appearAnimationHoc =
  (options?: IHOCOptions) =>
  <OriginalProps extends IOriginalProps>(
    Component: React.ComponentType<OriginalProps & InjectedProps>
  ): React.ComponentType<OriginalProps & ExternalProps> => {
    class HOC extends React.PureComponent<OriginalProps & ExternalProps> {
      public render() {
        return (
          <AnimationDiv animationState={this.props.animationState}>
            <Component {...this.props} />
          </AnimationDiv>
        );
      }
    }

    return HOC;
  };
