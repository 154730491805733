import { createStandardAction } from 'typesafe-actions';
import { IAlternativeDurationLine, IBackButtonReference } from './types';

export const toggleSwallowPlayPauseOverlay = createStandardAction(
  'miscScreenObjects/toggleSwallowPlayPauseOverlay'
)<boolean>();

export const toggleAlternativeDurationLine = createStandardAction(
  'miscScreenObjects/toggleAlternativeDurationLine'
)<IAlternativeDurationLine | undefined>();

export const updateAlternativeDurationLineProgress = createStandardAction(
  'miscScreenObjects/updateAlternativeLineProgress'
)<number>();

export const appendBackButtonReference = createStandardAction(
  'miscScreenObjects/appendBackButtonReference'
)<IBackButtonReference>();

export const popBackButtonReference = createStandardAction(
  'miscScreenObjects/popBackButton'
)();
