// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slider_slider__36ilu{outline:0;position:relative;cursor:pointer;padding:0;margin:0;height:100%;width:100%}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Slider/slider.module.sass"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,iBAAA,CACA,cAAA,CACA,SAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".slider\n  outline: 0\n  position: relative\n  cursor: pointer\n  padding: 0\n  margin: 0\n  height: 100%\n  width: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slider": "slider_slider__36ilu"
};
export default ___CSS_LOADER_EXPORT___;
