import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { getStyles } from '../skins/getStyles';
import { IPlayerRootReducerShape } from '../../../store/rootPlayerStore';
import { IBackButtonReference } from '../../../store/miscScreenObjects/types';
import { getIsVideoChanging } from '../../../store/sourceConfiguration/selectors';
import { getLastBackRef } from '../../../store/selectors';
import { popBackButtonReference } from '../../../store/miscScreenObjects/actions';
import { requestNavigateToOtherNode } from '../../../store/sourceConfiguration/actions';
import styles from './styles.module.sass';
import { requestPause } from '../../../store/videoState/actions';
import { usePlayerSkin } from '../skins/PlayerSkinContext';
import { getVideoState } from '../../../store/videoState/selectors';

const BackButton = ({
  player,
  allControlsAreAlwaysVisible,
}: IDefaultPropTypes) => {
  const dispatch = useDispatch();
  const skin = usePlayerSkin();
  const { muted, volume } = useSelector(getVideoState);

  const backButtonLatestReference: IBackButtonReference | undefined =
    useSelector((state: IPlayerRootReducerShape) => {
      const { backButtonReferences } = state.miscScreenObjects;
      return backButtonReferences[backButtonReferences.length - 1];
    });

  const isVisible =
    (allControlsAreAlwaysVisible || backButtonLatestReference) &&
    player.controls.showBackButton;

  const isVideoChanging = useSelector(getIsVideoChanging);
  const lastRef = useSelector(getLastBackRef);

  const handleBackClick = useCallback(() => {
    if (isVideoChanging || !lastRef) return;

    dispatch(requestPause({ isSilent: true }));
    dispatch(popBackButtonReference());
    dispatch(
      requestNavigateToOtherNode({
        nodeId: lastRef.nodeId,
        timestamp: lastRef.timestamp,
        muted,
        volume,
      })
    );
  }, [dispatch, isVideoChanging, lastRef, muted, volume]);

  if (!isVisible) return null;

  const BackButton = skin.icons.backButton;

  return (
    <div
      className={cx(styles.backButton, skin.classes.backButton)}
      style={getStyles('backButton', skin, player)}
      onClick={handleBackClick}
    >
      <BackButton />
    </div>
  );
};

export default BackButton;
