import * as React from 'react';
import cx from 'classnames';

import PlayIcon from '../PlayIcon/PlayIcon';
import PauseIcon from '../PauseIcon/PauseIcon';

import styles from './playPauseOverlay.module.sass';
import { IPlayerRootReducerShape } from '../../../store/rootPlayerStore';
import { connect, ConnectedProps } from 'react-redux';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { setPlayPauseSilentMode } from '../../../store/videoState/actions';

interface IAddedIcon {
  timeoutHandle: number; // unique number, can be used as ID by us
  isPlayIcon: boolean;
}

interface IState {
  addedIcons: IAddedIcon[];
}

type IProps = IDefaultPropTypes & ConnectedProps<typeof hoc>;

// timeout is unreliable, add 20% to the real animation duration
const ANIMATION_DURATION = 600 * 1.2;
class PlayPauseOverlay extends React.PureComponent<IProps, IState> {
  public state: IState = {
    addedIcons: [],
  };

  public componentWillUnmount() {
    this.state.addedIcons.forEach((icon) => {
      clearTimeout(icon.timeoutHandle);
    });

    this.setState({
      addedIcons: [],
    });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const shouldAddNewPlayPause =
      !this.props.isVideoEnded &&
      !this.props.isVeryCloseToEnd &&
      (prevProps.hasStarted || !this.props.isZeroTime) &&
      this.props.paused !== prevProps.paused &&
      !this.props.swallowPlayPauseOverlay;

    // console.log({
    //   shouldAddNewPlayPause,
    //   isFastForwarding: this.props.isFastForwarding,
    //   isVideoEnded: this.props.isVideoEnded,
    //   prevPropsHasStarted: prevProps.hasStarted,
    //   isZeroTime: this.props.isZeroTime,
    //   isVeryCloseToEnd: this.props.isVeryCloseToEnd,
    //   prevZeroTime: prevProps.isZeroTime,
    //   paused: this.props.paused,
    //   prevPropsPaused: prevProps.paused,
    // });
    if (shouldAddNewPlayPause) {
      if (this.props.isSilent) {
        this.props.setPlayPauseSilentMode({ isSilent: false });
      } else {
        this.appendIcon(!this.props.paused);
      }
    }
  }

  private appendIcon(isPlayIcon: boolean) {
    const { isBlocked } = this.props;
    if (isBlocked) return;

    const timeoutHandle = window.setTimeout(
      this.removeIcon,
      ANIMATION_DURATION
    );

    this.setState({
      addedIcons: [...this.state.addedIcons, { timeoutHandle, isPlayIcon }],
    });
  }

  private removeIcon = () => {
    this.setState({
      addedIcons: this.state.addedIcons.slice(1),
    });
  };

  public render() {
    const { addedIcons } = this.state;

    return addedIcons.map(({ timeoutHandle, isPlayIcon }, i) => {
      const isTheLatest = i === addedIcons.length - 1;
      return (
        <div className={styles.wrapper} key={timeoutHandle}>
          <div
            className={cx({
              [styles.hideAnimation]: !isTheLatest,
            })}
          >
            <div>{isPlayIcon ? <PlayIcon /> : <PauseIcon />}</div>
          </div>
        </div>
      );
    });
  }
}

const mapStateToProps = (
  state: IPlayerRootReducerShape,
  ownProps: IDefaultPropTypes
) => {
  const secondsTillEnd =
    ownProps.file.metadata.duration - state.videoState.currentTime;
  return {
    swallowPlayPauseOverlay: state.miscScreenObjects.swallowPlayPauseOverlay,
    paused: state.videoState.paused,
    isVideoEnded: state.videoState.isEnded,
    hasStarted: state.videoState.hasStarted,
    isZeroTime: state.videoState.currentTime < 0.5,
    isVeryCloseToEnd: secondsTillEnd < 0.5,
    isBlocked: state.playPauseOverlay.blockedBy.length > 0,
    isSilent: state.videoState.isPlayPauseSilentMode,
  };
};

const mapDispatchToProps = {
  setPlayPauseSilentMode,
};

const hoc = connect(mapStateToProps, mapDispatchToProps);

export default hoc(PlayPauseOverlay);
