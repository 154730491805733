import React, { useState, useMemo } from 'react';

export const VideoItemsContainerContext = React.createContext<{
  container: HTMLDivElement | null;
  setContainer: (container: HTMLDivElement | null) => void;
}>({
  container: null,
  setContainer: () =>
    console.error('VideoItemsContainerContext setContainer was not set!'),
});

export const VideoItemsContainerProvider: React.SFC = ({ children }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const value = useMemo(
    () => ({ container, setContainer }),
    [container, setContainer]
  );

  return (
    <VideoItemsContainerContext.Provider value={value}>
      {children}
    </VideoItemsContainerContext.Provider>
  );
};
