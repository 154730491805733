import React, { memo, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Select } from '../Select/Select';
import styles from './styles.module.sass';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { requestSpeedChange } from '../../../store/videoState/actions';
import {
  addControlsStayVisibleBy,
  removeControlsStayVisibleBy,
} from '../../../store/videoControls/actions';
import { getSpeed } from '../../../store/videoState/selectors';
import { usePlayerSkin } from '../skins/PlayerSkinContext';

export const speedOptions = [0.5, 1, 1.5, 2, 2.5];

const SelectSpeed = ({
  player,
  player: {
    skin: { captions },
  },
}: IDefaultPropTypes) => {
  const dispatch = useDispatch();
  const speed = useSelector(getSpeed);
  const skin = usePlayerSkin();

  const handleChangeOpen = useCallback(
    (opened: boolean) => {
      if (opened) {
        dispatch(addControlsStayVisibleBy('SELECT_SPEED'));
      } else {
        dispatch(removeControlsStayVisibleBy('SELECT_SPEED'));
      }
    },
    [dispatch]
  );

  const options = useMemo(
    () =>
      speedOptions.map((option) => ({
        value: option,
        label: option === 1 ? captions.normalSpeedText : `${option}x`,
      })),
    [captions.normalSpeedText]
  );

  const buttonProps = useMemo(() => {
    const SpeedIcon = skin.icons.speed;

    return {
      config: player,
      icon: <SpeedIcon />,
      title: captions.playbackSpeedText,
    };
  }, [captions.playbackSpeedText, player, skin]);

  const handleSelect = useCallback(
    (value: number) => {
      dispatch(requestSpeedChange({ value }));
    },
    [dispatch]
  );

  if (!player.controls.showSelectSpeedButton) return null;

  return (
    <div className={styles.selectSpeedWrap}>
      <Select
        className={cx(
          skin.classes.selectSpeed__root,
          styles.selectSpeedDropdown
        )}
        buttonProps={buttonProps}
        config={player}
        options={options}
        selectedValue={speed}
        onSelect={handleSelect}
        onChangeOpen={handleChangeOpen}
      />
    </div>
  );
};

export default memo(SelectSpeed);
