import React, { memo, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { IDefaultPropTypes } from '../types/defaultPropTypes';
import { visibleAskToResumeOverlaySelector } from '../../store/askToResumeOnVisitOverlay/selectors';

const WelcomeBackLazy = React.lazy(() => import('./WelcomeBack'));

const WelcomeBackLazyWrapperComponent = (props: IDefaultPropTypes) => {
  const visibleAskToResumeOverlay = useSelector(
    visibleAskToResumeOverlaySelector
  );

  if (!visibleAskToResumeOverlay) return null;

  return (
    <Suspense fallback={null}>
      <WelcomeBackLazy {...props} />
    </Suspense>
  );
};

export const WelcomeBackLazyWrapper = memo(WelcomeBackLazyWrapperComponent);
