import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

export interface IVideoItemsOnScreenState {
  highlightedItemId: string | undefined;
  dismissedItemsIds: string[];
  rememberedDismissedItemIds: string[];
}

const initialState: IVideoItemsOnScreenState = {
  dismissedItemsIds: [],
  rememberedDismissedItemIds: [],
  highlightedItemId: undefined,
};

type IAction =
  | ActionType<typeof actions>
  | ActionType<typeof actions.resetHighlights>;

export const videoItemsReducer = (
  state: IVideoItemsOnScreenState = initialState,
  action: IAction
): IVideoItemsOnScreenState => {
  switch (action.type) {
    case getType(actions.resetHighlights):
      return { ...state, highlightedItemId: undefined };
    case getType(actions.dismissVideoItemOnScreen):
      if (action.payload.remember) {
        return {
          ...state,
          rememberedDismissedItemIds: [
            ...state.rememberedDismissedItemIds,
            action.payload.id,
          ],
        };
      } else {
        return {
          ...state,
          dismissedItemsIds: [...state.dismissedItemsIds, action.payload.id],
        };
      }

    case getType(actions.highlightItemOnScreen):
      return {
        ...state,
        highlightedItemId: action.payload,
      };
  }

  return state;
};
