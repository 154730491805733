import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { ICtaFooter } from './types';

export interface ICtaFooterShape {
  visibleCtaFooter?: ICtaFooter;
}

const initialState: ICtaFooterShape = {};

type IAction = ActionType<typeof actions>;
const reducer = (
  state: ICtaFooterShape = initialState,
  action: IAction
): ICtaFooterShape => {
  switch (action.type) {
    case getType(actions.updateVisibleCtaFooter):
      return {
        ...state,
        visibleCtaFooter: action.payload,
      };
  }

  return state;
};

export default reducer;
