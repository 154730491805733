// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".borderSkin_root__9wlNw{position:relative;width:100%;height:100%}.borderSkin_withSkin__fMYIB{border-radius:0 !important}.borderSkin_noSkin__23XGn{z-index:0}.borderSkin_hideCursor__26gxI{cursor:none}.borderSkin_iphone__3rI5V:before{content:\"\";position:absolute;background:#000;top:0;left:0;right:0;bottom:0;margin:auto;width:100%;height:98%;border-radius:20%}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/BorderSkin/borderSkin.module.sass"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CACA,UAAA,CACA,WAAA,CAEF,4BACE,0BAAA,CAEF,0BACE,SAAA,CAEF,8BACE,WAAA,CAGA,iCACE,UAAA,CACA,iBAAA,CACA,eAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":[".root\n  position: relative\n  width: 100%\n  height: 100%\n\n.withSkin\n  border-radius: 0 !important\n\n.noSkin\n  z-index: 0 // Fix flickering controls bar on Safari\n\n.hideCursor\n  cursor: none\n\n.iphone\n  &:before\n    content: ''\n    position: absolute\n    background: #000\n    top: 0\n    left: 0\n    right: 0\n    bottom: 0\n    margin: auto\n    width: 100%\n    height: 98%\n    border-radius: 20%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "borderSkin_root__9wlNw",
	"withSkin": "borderSkin_withSkin__fMYIB",
	"noSkin": "borderSkin_noSkin__23XGn",
	"hideCursor": "borderSkin_hideCursor__26gxI",
	"iphone": "borderSkin_iphone__3rI5V"
};
export default ___CSS_LOADER_EXPORT___;
