import * as React from 'react';
import styled from 'styled-components';
import { DetachedStoreContext } from '../../contexts/DetachedStoreContext';

interface IProps {}

interface IState {
  rectPosition:
    | undefined
    | {
        x1: number;
        y1: number;
        x2: number;
        y2: number;
      };
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  appearance: none;
  color: inherit;
  pointer-events: initial;
  outline: none;
`;

const Rectangle = styled.div`
  position: absolute;
  background: #1a99ff;
  opacity: 0.5;
`;

export class RectangleSelect extends React.PureComponent<IProps, IState> {
  static contextType = DetachedStoreContext;
  context!: React.ContextType<typeof DetachedStoreContext>;

  private wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: IProps) {
    super(props);

    this.state = {
      rectPosition: undefined,
    };
  }

  private handleMouseMove = (e: MouseEvent) => {
    const pos = this.calcMousePosition(e);

    if (!pos || !this.state.rectPosition || !this.context) return;

    const newRectPosition = {
      ...this.state.rectPosition,
      x2: pos.x,
      y2: pos.y,
    };

    this.context.handleRectangleSelectIntersection(newRectPosition);

    this.setState({ rectPosition: newRectPosition });
  };

  private handleMouseUp = (_e: MouseEvent) => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);

    this.setState({ rectPosition: undefined });
  };

  private handleMouseDown = (e: React.MouseEvent) => {
    const pos = this.calcMousePosition(e);

    if (!pos || !this.context) return;

    this.context.deselectAll();

    this.setState({
      rectPosition: { x1: pos.x, y1: pos.y, x2: pos.x, y2: pos.y },
    });

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  private calcMousePosition = (e: React.MouseEvent | MouseEvent) => {
    return { x: e.clientX, y: e.clientY };
  };

  render() {
    const cssRectPosition = (() => {
      if (!this.state.rectPosition || !this.wrapperRef.current) return;

      const { rectPosition } = this.state;
      const wrapperRect = this.wrapperRef.current.getBoundingClientRect();

      const left =
        Math.min(rectPosition.x1, rectPosition.x2) - wrapperRect.left;
      const top = Math.min(rectPosition.y1, rectPosition.y2) - wrapperRect.top;

      const width = Math.abs(rectPosition.x1 - rectPosition.x2);
      const height = Math.abs(rectPosition.y1 - rectPosition.y2);

      return { left, top, width, height };
    })();

    return (
      <Wrapper ref={this.wrapperRef} onMouseDown={this.handleMouseDown}>
        <Rectangle style={cssRectPosition ?? { display: 'none' }} />
      </Wrapper>
    );
  }
}

export default RectangleSelect;
