import { createSelector } from 'reselect';
import { PlayerMode } from '../components/types/defaultPropTypes';
import { ITimelineItem } from '../types/types';
import { IPlayerRootReducerShape } from './rootPlayerStore';
import {
  getPlayerMode,
  getTimelineItems,
} from './sourceConfiguration/selectors';
import { getDismissedItemIds } from './videoItems/selectors';
import { getAlternativeDurationLine } from './miscScreenObjects/selectors';
import { getCurrentTime } from './videoState/selectors';
import { PlayerExternalAPIHolder } from '../../lib/api/external/PlayerExternalAPIHolder';

export const getAreKeybindingsGlobal = (state: IPlayerRootReducerShape) => {
  return (
    state.sourceConfiguration.current?.playbackSettings.areKeybindingsGlobal ||
    state.dimensions.isFullScreen
  );
};

export const getLastBackRef = (state: IPlayerRootReducerShape) => {
  const { backButtonReferences } = state.miscScreenObjects;

  const lastReference = backButtonReferences[backButtonReferences.length - 1];

  // shouldn't happen as button is invisible
  if (!lastReference) return;

  return lastReference;
};

const getBlockingSecond = (item: ITimelineItem) => {
  // if grid is required, but user chose not to pause the video, we continue playing
  // the video up to the very last second
  if (
    (item.type === 'grid' || item.type === 'survey') &&
    item.actionsOnEnter.every((x) => x.type !== 'pause')
  ) {
    // well, almost very last second: we'll remove the grid on the last second so
    // stopping right before it leaves the screen
    return item.endSecond - 1;
  }

  return item.startSecond;
};

const getFirstBlockingItem = createSelector(
  [getDismissedItemIds, getTimelineItems, getPlayerMode],
  (dismissedItemsIds, timelineItems, playerMode): ITimelineItem | undefined => {
    if (
      !playerMode ||
      [PlayerMode.TIMELINE_EDITOR, PlayerMode.THUMBNAIL_EDITOR].includes(
        playerMode
      )
    ) {
      return;
    }

    return timelineItems
      .filter((item) => {
        // already dismissed, ignoring
        if (dismissedItemsIds.indexOf(item.id) > -1) return false;

        return (
          item.type === 'shareGate' ||
          item.type === 'turnstile' ||
          ((item.type === 'grid' || item.type === 'survey') && item.isRequired)
        );
      })
      .sort((a, b) => {
        return getBlockingSecond(a) - getBlockingSecond(b);
      })[0];
  }
);

export const getFirstBlockingSecond = createSelector(
  getFirstBlockingItem,
  getAlternativeDurationLine,
  (blockingItem, alternativeDurationLine) => {
    if (blockingItem) {
      return getBlockingSecond(blockingItem);
    }
    if (alternativeDurationLine) {
      return alternativeDurationLine.startAtTime;
    }
  }
);

export const getIsPlaybackBlocked = createSelector(
  getFirstBlockingItem,
  getCurrentTime,
  getAlternativeDurationLine,
  (blockingItem, currentTime, alternativeDurationLine) => {
    return (
      alternativeDurationLine !== undefined ||
      (blockingItem && currentTime >= getBlockingSecond(blockingItem))
    );
  }
);

export const getStoreId = (state: IPlayerRootReducerShape) => state.storeId;

export const getPlayerExternalAPI = (state: IPlayerRootReducerShape) =>
  PlayerExternalAPIHolder.instance.getApiInstance(state.storeId);
