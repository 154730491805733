import React, { forwardRef } from 'react';
import styled from 'styled-components';

const ratioToPaddingTop = (ratio?: number | 'auto') => {
  if (!ratio) return '0';

  if (ratio === 'auto') return '0';
  return 100 / ratio + '%';
};

const StyledAbsoluteDiv = styled.div.attrs(
  (): {
    style: React.CSSProperties;
  } => ({
    style: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      textAlign: 'center',
    },
  })
)`
  &&& {
    .fullscreen {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Ratio = forwardRef<
  HTMLDivElement,
  {
    children?: React.ReactNode;
    ratio: number;
    className?: string;
  }
>(({ ratio, children, className }, ref) => {
  return (
    <div
      ref={ref}
      className={className}
      style={{
        paddingTop: ratioToPaddingTop(ratio),
        position: 'relative',
        width: '100%',
      }}
    >
      <StyledAbsoluteDiv>{children}</StyledAbsoluteDiv>
    </div>
  );
});
