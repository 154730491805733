import React from 'react';
import cx from 'classnames';
import styles from './loader.module.sass';
import { LoadingIndicator } from './LoadingIndicator';

export interface ILoaderProps {
  className?: string;
  color?: string;
  screenCenter?: boolean;
  blockCenter?: boolean;
  isVisible?: boolean;
  delay?: number;
}

interface IState {
  isShowing: boolean;
}

export class Loader extends React.PureComponent<ILoaderProps, IState> {
  private timeoutHandle?: number;

  constructor(props: ILoaderProps) {
    super(props);

    this.state = {
      isShowing: typeof props.delay === 'undefined',
    };
  }

  public componentDidMount() {
    const { delay } = this.props;

    if (typeof delay !== 'undefined') {
      this.timeoutHandle = window.setTimeout(() => {
        this.setState({
          isShowing: true,
        });
      }, delay);
    }
  }

  public componentWillUnmount() {
    if (this.timeoutHandle) {
      window.clearTimeout(this.timeoutHandle);
    }
  }

  render() {
    const {
      className,
      color = '#008EFF',
      screenCenter,
      blockCenter,
      isVisible = true,
    } = this.props;

    const { isShowing } = this.state;

    if (!isShowing) return null;

    return (
      <div
        className={cx(className, styles.wrapper, {
          [styles.screenCenter]: screenCenter,
          [styles.blockCenter]: blockCenter,
        })}
      >
        {isVisible && <LoadingIndicator color={color} />}
      </div>
    );
  }
}
