import { createStandardAction } from 'typesafe-actions';

interface IDimensions {
  width: number;
  height: number;
}
export const setContainerDimensions = createStandardAction(
  'dimensions/setContainerDimensions'
)<IDimensions>();

export const setVideoDimensions = createStandardAction(
  'dimensions/setVideoDimensions'
)<IDimensions>();

export const setIsFullScreen = createStandardAction(
  'dimensions/setIsFullScreen'
)<boolean>();

export const setForceAspectRatio = createStandardAction(
  'dimensions/setForceAspectRatio'
)<number>();

export const toggleFullScreen = createStandardAction(
  'dimensions/TOGGLE_FULL_SCREEN'
)();
