import * as React from 'react';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as DotsIcon } from '../../svg/three-dots-vertical.svg';
import { IPlayerTemplate } from '../../types/player';
import Button from './Button/Button';
import { getStyles } from './skins/getStyles';
import { usePlayerSkin } from './skins/PlayerSkinContext';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const WrapperLayers = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;
const FirstLayer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  transform: translateY(0);
  opacity: 1;
  transition: all 300ms ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(20px);
      opacity: 0;
    `}
`;
const SecondLayer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  transform: translateY(-20px);
  opacity: 0;
  transition: all 300ms ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(0);
      opacity: 1;
    `}
`;

const ToggleCompactControls = ({
  firstLayerContent,
  secondLayerContent,
  config,
}: {
  firstLayerContent: React.ReactNode;
  secondLayerContent?: React.ReactNode;
  config: IPlayerTemplate;
}) => {
  const skin = usePlayerSkin();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  return (
    <Wrapper
      className={skin.classes.controls__compact_wrapper}
      style={getStyles('controls__compact_wrapper', skin, config)}
    >
      <WrapperLayers>
        <FirstLayer isOpen={isOpen}>{firstLayerContent}</FirstLayer>
        {secondLayerContent && (
          <SecondLayer isOpen={isOpen}>{secondLayerContent}</SecondLayer>
        )}
      </WrapperLayers>
      {secondLayerContent && (
        <Button config={config} onClick={handleToggle} icon={<DotsIcon />} />
      )}
    </Wrapper>
  );
};

export default ToggleCompactControls;
