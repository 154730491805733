import { Dispatch } from 'redux';
import { PlayerExternalAPI } from './PlayerExternalAPI';
import { registerPlayerExternalApi } from '../../store/videoState/actions';

export class PlayerExternalAPIHolder {
  private apiMap = new Map<string, PlayerExternalAPI>();

  public static instance = new PlayerExternalAPIHolder();

  public getApiInstance = (storeId: string) => this.apiMap.get(storeId);

  public register = (
    storeId: string,
    dispatch: Dispatch,
    apiInstance: PlayerExternalAPI
  ) => {
    // Register Player API to handle player events in saga
    dispatch(registerPlayerExternalApi({ api: apiInstance }));

    // Configure API to be able to call player API methods
    apiInstance.register(dispatch, storeId);

    this.apiMap.set(storeId, apiInstance);
  };
}
