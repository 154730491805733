import React, { useState, useCallback, useEffect } from 'react';
import noImage from '../svg/no-image.svg';

export const ImageWithFallbackComponent = (
  {
    onError,
    ...props
  }: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  ref: React.Ref<HTMLImageElement>
) => {
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const handleError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (imageLoadFailed) return;
      setImageLoadFailed(true); // to avoid infinite loop
      if (onError) {
        onError(e);
      }
    },
    [imageLoadFailed, setImageLoadFailed, onError]
  );

  useEffect(() => {
    setImageLoadFailed(false); // in case `src` is changed
  }, [props.src]);

  return (
    <img
      alt=""
      {...props}
      src={imageLoadFailed ? noImage : props.src}
      onError={handleError}
      ref={ref}
    />
  );
};

export const ImageWithFallback = React.forwardRef(ImageWithFallbackComponent);
