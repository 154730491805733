import * as React from 'react';
import { ComponentsToUseContext, IComponentsToUseContext } from './context';
import { PlayerButtonItem } from '../Button/PlayerButton';
import { PlayerShareGateItem } from '../ShareGate/PlayerShareGateItem';
import { PlayerAnnotationItem } from '../Annotation/PlayerAnnotation';
import { PlayerTurnstileItem } from '../Turnstile/PlayerTurnstile';
import { PlayerImageItem } from '../Image/PlayerImage';
import { PlayerImageAnnotationItem } from '../ImageAnnotation/PlayerImageAnnotation';
import { PlayerGridItem } from '../Grid/PlayerGrid';
import { PlayerPlainTextItem } from '../PlainText/PlayerPlainText';
import { PlayerSurveyItem } from '../Survey/PlayerSurvey';

interface IProps {
  children: React.ReactNode;
}

const components: IComponentsToUseContext = {
  PlainText: PlayerPlainTextItem,
  Button: PlayerButtonItem,
  Annotation: PlayerAnnotationItem,
  ShareGate: PlayerShareGateItem,
  Turnstile: PlayerTurnstileItem,
  Image: PlayerImageItem,
  ImageAnnotation: PlayerImageAnnotationItem,
  Grid: PlayerGridItem,
  Survey: PlayerSurveyItem,
};

// Components for the real player (embed of shared)
export class PlayerComponentsProvider extends React.PureComponent<IProps> {
  public render() {
    const { children } = this.props;

    return (
      <ComponentsToUseContext.Provider value={components}>
        {children}
      </ComponentsToUseContext.Provider>
    );
  }
}
