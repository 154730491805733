/**
 * Adds script to document if not added before
 * and wait until it's loaded
 */
export const addScript = (props: {
  id: string;
  url: string;
  onLoad: () => void;
  onError: (url: string, err: Error) => void;
  preloaderScript?: string;
}) => {
  if (document.getElementById(props.id)) {
    return;
  }

  const allScripts = document.getElementsByTagName('script');
  const preloaderScript = props.preloaderScript
    ? Array.from(allScripts).find((script) =>
        script.src.includes(preloaderScript)
      )
    : undefined;

  const script = document.createElement('script');
  script.id = props.id;
  script.defer = true;

  try {
    script.src = props.url;
    script.onload = props.onLoad;

    if (preloaderScript) {
      preloaderScript.parentNode?.insertBefore(
        script,
        preloaderScript.nextSibling
      );
    } else {
      document.body.appendChild(script);
    }
  } catch (e) {
    props.onError(props.url, e);
  }
};
