import { isInternetExplorerBefore } from './ieDetection';

// Copied from: https://github.com/nygardk/react-share/blob/master/src/utils/createShareButton.jsx

export const windowOpen = (
  url: string,
  options: any = {},
  onShareWindowClose: (shareDialog: Window | null) => void
) => {
  const { name, height = 400, width = 550 } = options;
  const left =
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2;
  const top =
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2;

  const config = {
    height,
    width,
    left,
    top,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
  };

  const shareDialog = window.open(
    url,
    isInternetExplorerBefore(10) ? '' : name,
    Object.entries(config)
      .map(([k, v]) => `${k}=${v}`)
      .join(', ')
  );

  if (onShareWindowClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval);
          onShareWindowClose(shareDialog);
        }
      } catch (e) {
        // do nothing
      }
    }, 300);
  }

  return shareDialog;
};
