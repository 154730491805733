import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { IDetachableInnerProps as IDetachableStoreInnerProps } from '../detachableStoreHoc';
import { useSelector } from 'react-redux';
import { ITimelineItem } from '../../../types/types';
import { IPlayerRootReducerShape } from '../../../store/rootPlayerStore';

// Props you want the resulting component to take (besides the props of the wrapped component)
interface ExternalProps {
  item: ITimelineItem;
}

// Props the HOC adds to the wrapped component
export interface InjectedProps {}

// Props that are required for the wrapped component to accept
type IOriginalProps = Omit<IDetachableStoreInnerProps<any>, 'item'>;
// Options for the HOC factory that are not dependent on props values
interface IHOCOptions {}

// const breathingAnimation = keyframes`
//   0% {
//     transform: scale(1);
//     animation-timing-function: ease-in-out;
//   }
//
//   50% {
//     transform: scale(1.3);
//     animation-timing-function: ease-in-out;
//   }
//
//   100% {
//     transform: scale(1);
//     animation-timing-function: ease-in-out;
//   }
// `;

const disappearingAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
    animation-timing-function: ease-in-out;
  }

  100% {
    opacity: 0;
    transform: scale(0.7);
    animation-timing-function: ease-in-out;
  }
`;

const AnimationDiv = styled.div<{
  isHighlighted: boolean;
  isHidden: boolean;
  disableHideAnimation: boolean;
}>`
  // transform: ${({ isHighlighted }) => `scale(${isHighlighted ? 2 : 1})`};
  animation-name: ${({ isHighlighted, isHidden }) => {
    if (isHighlighted) {
      return 'none';
    } else if (isHidden) {
      return disappearingAnimation;
    } else {
      return 'none';
    }
  }};
  animation-duration: ${({ isHighlighted, disableHideAnimation }) =>
    disableHideAnimation ? 0 : isHighlighted ? 1 : 0.5}s;
  animation-iteration-count: ${({ isHighlighted }) =>
    isHighlighted ? 'infinite' : 1};
  animation-fill-mode: forwards;
  pointer-events: ${({ isHidden, isHighlighted }) =>
    isHidden || isHighlighted ? 'none' : 'auto'};
`;

const highlightItemHoc =
  (options?: IHOCOptions) =>
  <OriginalProps extends IOriginalProps>(
    Component: React.ComponentType<OriginalProps & InjectedProps>
  ): React.ComponentType<OriginalProps & ExternalProps> => {
    const HOC: React.FC<OriginalProps & ExternalProps> = (props) => {
      const { item } = props;
      const selectedItemId = useSelector(
        (state: IPlayerRootReducerShape) =>
          state.videoItemsOnScreen.highlightedItemId
      );

      const isFunnel = useSelector(
        (state: IPlayerRootReducerShape) =>
          !!state.sourceConfiguration?.current?.funnelId
      );

      let isHighlighted = false;
      let isHidden = false;
      if (typeof selectedItemId !== 'undefined') {
        isHighlighted = selectedItemId === item.id;
        isHidden = !isHighlighted;
      }

      return (
        <AnimationDiv
          isHighlighted={isHighlighted}
          isHidden={isHidden}
          disableHideAnimation={isFunnel}
        >
          <Component {...props} />
        </AnimationDiv>
      );
    };

    return HOC;
  };

export default highlightItemHoc;
