// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_backButton__35LO5{position:absolute;z-index:40;top:10px;left:10px;cursor:pointer}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/BackButton/styles.module.sass"],"names":[],"mappings":"AAAA,0BACE,iBAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA,CACA,cAAA","sourcesContent":[".backButton\n  position: absolute\n  z-index: 40\n  top: 10px\n  left: 10px\n  cursor: pointer\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "styles_backButton__35LO5"
};
export default ___CSS_LOADER_EXPORT___;
