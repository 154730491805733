import { groupBy, orderBy } from 'lodash-es';
import { Channel } from 'redux-saga';
import { call, delay, flush, take } from 'redux-saga/effects';
import { PromiseType } from 'utility-types';
import {
  createSessionAnalyticsEvents,
  fetchSessionId,
} from '../../../api/analytics';
import {
  eAnalyticsEventType,
  IAnalyticsSessionEvent,
} from '../../../types/analytics';
import { IConfigIds } from '../../sourceConfiguration/selectors';
import { getUserInfo } from './getUserInfo';

export type ISessionEventsWithConfig = {
  ev: IAnalyticsSessionEvent;
  config: IConfigIds;
  prevEv?: IAnalyticsSessionEvent;
  prevConfig?: IConfigIds;
};

export type SessionDescriptor = {
  shouldRecreateSession: boolean;
  isFinished: boolean;
  sessionId?: string;
};

// // After finish event happened we can't send new events anymore(API will return error)
const filterEventsAfterFinish = (events: IAnalyticsSessionEvent[]) => {
  const evs: IAnalyticsSessionEvent[] = [];

  for (const ev of events) {
    evs.push(ev);

    if (ev.name === eAnalyticsEventType.finish) {
      break;
    }
  }

  return evs;
};

const sendEventSequenceNumberMap = new Map<string, number>();

function* sendSessionEvents(
  sessions: Record<string, SessionDescriptor>,
  events: ISessionEventsWithConfig[],
  byBeacon = false
) {
  const toResend: ISessionEventsWithConfig[] = [];

  // sends sessions to the correct sessionId
  const grouped = groupBy(events, (ev) => ev.config.id);
  for (const [configId, events] of Object.entries(grouped)) {
    const sessionDescriptor = sessions[configId];
    if (!sessionDescriptor.sessionId) {
      toResend.push(...events);

      continue;
    }

    if (events.length === 0) {
      continue;
    }

    const sessionId = sessionDescriptor.sessionId;
    const eventsToSend = filterEventsAfterFinish(
      events.map(({ ev }) => {
        // When resending we should send sequence number one more time
        // so just getting first event sequence number
        if (!sendEventSequenceNumberMap[sessionId]) {
          sendEventSequenceNumberMap[sessionId] = 1;
        }

        const newSequenceNumber = sendEventSequenceNumberMap[sessionId];
        sendEventSequenceNumberMap[sessionId]++;

        return {
          ...ev,
          // Avoid skipping sequence numbers when sending events
          sequenceNumber:
            newSequenceNumber === ev.sequenceNumber
              ? ev.sequenceNumber
              : newSequenceNumber,
        };
      })
    );

    const result: PromiseType<ReturnType<typeof createSessionAnalyticsEvents>> =
      yield call(createSessionAnalyticsEvents, {
        events: eventsToSend,
        sessionId,
        byBeacon: byBeacon,
      });

    if (
      result.success &&
      events.find((ev) => ev.ev.name === eAnalyticsEventType.finish)
    ) {
      sessions[configId].isFinished = true;
    }

    if (!result.success) {
      sessions[configId].shouldRecreateSession = true;
      toResend.push(...events);

      sendEventSequenceNumberMap[sessionId] -= eventsToSend.length;
    }
  }

  return toResend;
}

/**
 * Sanitize events that cannot be run in a row without opposite action
 * (for ex. we cannot play or pause twice in a row)
 */
const sanitizeEvents = (allEvents: ISessionEventsWithConfig[]) => {
  const grouped = groupBy(allEvents, (ev) => ev.config.id);
  let newAllEvents: ISessionEventsWithConfig[] = [];

  for (const [, events] of Object.entries(grouped)) {
    const orderedEvents = orderBy(events, (event) => event.ev.sequenceNumber, [
      'asc',
    ]);

    let prevEvent: IAnalyticsSessionEvent | undefined = undefined;
    let prevConfig: IConfigIds | undefined = undefined;
    const sanitizedEvents = orderedEvents.reduce<ISessionEventsWithConfig[]>(
      (acc, event) => {
        prevEvent = event.prevEv;
        prevConfig = event.prevConfig;

        if (!prevEvent || prevConfig?.id !== event.config.id) {
          acc.push(event);
          return acc;
        }

        if (
          (event.ev.name === 'play' && prevEvent.name === 'play') || // avoid double play
          (event.ev.name === 'pause' && prevEvent.name === 'pause') || // avoid double pause
          (event.ev.name === 'playing' && prevEvent.name === 'pause') // avoid playing after pause (should play first)
        ) {
          return acc;
        }

        acc.push(event);
        return acc;
      },
      []
    );
    newAllEvents = newAllEvents.concat(sanitizedEvents);
  }

  return newAllEvents;
};

export function* eventsSenderSaga(ch: Channel<ISessionEventsWithConfig>) {
  // Events that we should resend as we could not do it previously
  let toResend: ISessionEventsWithConfig[] = [];

  // Key is config id, value session descriptor
  const sessions: Record<string, SessionDescriptor> = {};

  try {
    while (true) {
      const events: ISessionEventsWithConfig[] = yield flush(ch);

      const allEvents = [...events, ...toResend];

      const grouped = groupBy(allEvents, (ev) => ev.config.id);

      const eventsToSend: ISessionEventsWithConfig[] = [];
      for (const event of allEvents) {
        let eventSession = sessions[event.config.id];
        if (!eventSession) {
          sessions[event.config.id] = {
            shouldRecreateSession: true,
            isFinished: false,
            sessionId: undefined,
          };

          eventSession = sessions[event.config.id];
        }

        if (
          !eventSession.sessionId ||
          eventSession.shouldRecreateSession ||
          eventSession.isFinished
        ) {
          // Don't need to recreate session when finished and only "playing" event sending after that
          if (
            eventSession.isFinished &&
            grouped[event.config.id].length === 1 &&
            grouped[event.config.id][0].ev.name === eAnalyticsEventType.playing
          ) {
            continue;
          }

          // if undefined returns it still will retry to fetch session id(in next call)
          eventSession.sessionId = yield call(
            fetchSessionId,
            event.config.ids,
            getUserInfo(`player_event_${event.config.id}`)
          );

          if (eventSession.sessionId) {
            eventSession.shouldRecreateSession = false;
            eventSession.isFinished = false;
          }
        }

        eventsToSend.push(event);
      }

      toResend = yield call(
        sendSessionEvents,
        sessions,
        sanitizeEvents(eventsToSend),
        false
      );

      yield delay(5000);
    }
  } finally {
    // This method can be triggered on page leave, and here we can't make usual
    // ajax query, only beacons(they will be sent browser in background)
    // So that's why we can't take new session id, but we still can send
    // remaining events
    const events: ISessionEventsWithConfig[] = yield take(ch);

    yield call(
      sendSessionEvents,
      sessions,
      sanitizeEvents([...events, ...toResend]),
      true
    );
  }
}
