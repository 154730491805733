import { Channel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';
import {
  eAnalyticsEventType,
  IAnalyticsSessionEvent,
} from '../../types/analytics';
import { getIsVideoPlaying } from '../videoState/selectors';
import { ISessionEventsWithConfig } from './eventsSender/eventsSenderSaga';
import { eInternalAnalyticEventType, IInternalAnalyticEvent } from './types';
import { IS_DEBUG_ANALYTICS } from './utils';
import { IConfigIds } from '../sourceConfiguration/selectors';

type SeekingState = {
  startedAt: number;
};

const eventsMap = {
  [eInternalAnalyticEventType.play]: eAnalyticsEventType.play,
  [eInternalAnalyticEventType.pause]: eAnalyticsEventType.pause,
  [eInternalAnalyticEventType.finished]: eAnalyticsEventType.finish,
  [eInternalAnalyticEventType.playing]: eAnalyticsEventType.playing,
};

// It converts internal raw events to api events
export function* eventsConverterSaga(
  internalEventsCh: Channel<IInternalAnalyticEvent>,
  apiAnalyticsEventsCh: Channel<ISessionEventsWithConfig>
) {
  let currentConfigId: string | undefined;
  let currentSeekingState: SeekingState | undefined;

  const eventIdxMap: { [configId: string]: number } = {};
  let latestEv: IAnalyticsSessionEvent | undefined = undefined;
  let latestConfig: IConfigIds | undefined = undefined;

  while (true) {
    const newEvent: IInternalAnalyticEvent = yield take(internalEventsCh);
    if (newEvent.config.id !== currentConfigId) {
      currentConfigId = newEvent.config.id;
      currentSeekingState = undefined;
      eventIdxMap[currentConfigId] = eventIdxMap[currentConfigId] ?? 1;
    }

    if (newEvent.name === eInternalAnalyticEventType.seekStarted) {
      currentSeekingState = {
        startedAt: newEvent.second,
      };

      if (IS_DEBUG_ANALYTICS) {
        console.debug(
          '[ANALYTICS] Start seek. Config ID:',
          newEvent.config.id,
          newEvent
        );
      }
    } else if (
      newEvent.name === eInternalAnalyticEventType.seekFinished &&
      currentSeekingState
    ) {
      // We don't want to track too short seeks
      const isTiny =
        Math.abs(currentSeekingState?.startedAt - newEvent.second) < 1;
      if (!isTiny) {
        const ev: IAnalyticsSessionEvent = {
          name: eAnalyticsEventType.seek,
          fromSecond: Math.floor(currentSeekingState.startedAt),
          toSecond: Math.floor(newEvent.second),
          sequenceNumber: eventIdxMap[currentConfigId]++,
          executedAt: new Date().toISOString(),
        };

        if (IS_DEBUG_ANALYTICS) {
          console.debug(
            '[ANALYTICS] Seeked. Config ID:',
            newEvent.config.id,
            ev
          );
        }

        yield put(apiAnalyticsEventsCh, {
          ev,
          config: newEvent.config,
          prevEv: latestEv,
          prevConfig: latestConfig,
        });

        latestEv = ev;
        latestConfig = newEvent.config;
      }

      const ev: IAnalyticsSessionEvent = {
        name: getIsVideoPlaying(yield select())
          ? eAnalyticsEventType.play
          : eAnalyticsEventType.pause,
        sequenceNumber: eventIdxMap[currentConfigId]++,
        second: Math.floor(
          isTiny ? currentSeekingState.startedAt : newEvent.second
        ),
        executedAt: new Date().toISOString(),
      };

      if (IS_DEBUG_ANALYTICS) {
        console.debug(
          `[ANALYTICS] ${
            ev.name === eAnalyticsEventType.play ? 'Play' : 'Pause'
          } after seek. Config ID:`,
          newEvent.config.id,
          ev
        );
      }

      // Let's also emit if player play/pause currently, because it may happen during seek
      yield put(apiAnalyticsEventsCh, {
        ev,
        config: newEvent.config,
        prevEv: latestEv,
        prevConfig: latestConfig,
      });

      latestEv = ev;
      latestConfig = newEvent.config;
      currentSeekingState = undefined;
    } else {
      // Skipping any event on seek, cause
      // html video api spam play/pause events during seek
      if (currentSeekingState) continue;

      if (!eventsMap[newEvent.name]) {
        console.error(
          `Don't know how to map event ${JSON.stringify(newEvent)}`
        );

        continue;
      }

      const ev: IAnalyticsSessionEvent = {
        name: eventsMap[newEvent.name],
        second: Math.floor(newEvent.second),
        sequenceNumber: eventIdxMap[currentConfigId]++,
        executedAt: new Date().toISOString(),
      };

      if (IS_DEBUG_ANALYTICS) {
        console.debug(
          `[ANALYTICS] Original ${ev.name}. Config ID:`,
          newEvent.config.id,
          ev
        );
      }

      yield put(apiAnalyticsEventsCh, {
        ev,
        config: newEvent.config,
        prevEv: latestEv,
        prevConfig: latestConfig,
      });

      latestEv = ev;
      latestConfig = newEvent.config;
    }
  }
}
