import { makeUrl } from '@voomly/utils';

export interface IBody {
  email: string;
  firstName?: string;
  lastName?: string;
}

export const subscribe = (
  itemId: string,
  body: IBody,
  ref: { videoId: string; type: 'voomly' } | { funnelId: string }
) => {
  return fetch(makeUrl('/subscribe'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...body,
      itemId,
      ...ref,
    }),
  });
};
