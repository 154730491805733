// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser/9851769

const getBrowsers = () => {
  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    return {
      isOpera: false,
      isIE: false,
      isFirefox: false,
      isEdge: false,
      isChrome: false,
      isSafari: false,
      isSSR: true,
    };
  }

  // Opera 8.0+
  const isOpera =
    // @ts-ignore
    (!!window.opr && !!window.opr.addons) ||
    // @ts-ignore
    !!window.opera ||
    navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  // @ts-ignore
  const isFirefox = typeof InstallTrigger !== 'undefined';

  // Safari 3.0+ "[object HTMLElementConstructor]"
  // @ts-ignore
  const isSafari =
    // @ts-ignore
    /constructor/i.test(window.HTMLElement) ||
    ((p) => p.toString() === '[object SafariRemoteNotification]')(
      // @ts-ignore
      !window.safari ||
        // @ts-ignore
        (typeof window.safari !== 'undefined' &&
          // @ts-ignore
          window.safari.pushNotification)
    );

  // Internet Explorer 6-11
  // @ts-ignore
  const isIE = /*@cc_on!@*/ !!document.documentMode;

  // Edge 20+
  // @ts-ignore
  const isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 71
  const isChrome =
    // @ts-ignore
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  return { isOpera, isFirefox, isSafari, isIE, isEdge, isChrome, isSSR: false };
};

export const { isChrome, isEdge, isFirefox, isIE, isOpera, isSafari, isSSR } =
  getBrowsers();
