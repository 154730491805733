import {
  FromPlayerEventTypes,
  IFromPlayerApiEventName,
} from './emitters/FromPlayerEmitter';
import { IToPlayerApiEventName } from './emitters/ToPlayerDispatcher';

export type VoomlyPlayerInfo = {
  // Video id / Funnel id
  id?: string;

  // If we have multiple player on the page, but content is the same
  // we will be able to distinguish them this way
  instanceId?: string;
};

export type VoomlyPayloadFromPlayer<T extends IFromPlayerApiEventName> =
  FromPlayerEventTypes[T] & {
    playerInfo: VoomlyPlayerInfo;
  };

export const VOOMLY_EVENT_PREFIX = 'voomly:' as const;

export type VoomlyPlayerEventTypes<
  T extends IFromPlayerApiEventName | IToPlayerApiEventName
> = `${typeof VOOMLY_EVENT_PREFIX}${T}`;

export const toExternalEventName = <
  T extends IFromPlayerApiEventName | IToPlayerApiEventName
>(
  eventName: T
): VoomlyPlayerEventTypes<T> => `${VOOMLY_EVENT_PREFIX}${eventName}`;

/**
 * Event wrapper for player to listen (window, document, player root node)
 */
export class VoomlyPlayerEvent<
  T extends IFromPlayerApiEventName
> extends window.Event {
  constructor(eventName: T, public payload: VoomlyPayloadFromPlayer<T>) {
    super(toExternalEventName(eventName));
  }
}
