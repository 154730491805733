import React from 'react';
import { DetachedStoreContext } from '../../contexts/DetachedStoreContext';
import { ITimelineItem } from '../../types/types';

interface IOuterProps {
  id: string;
}

export interface IDetachableInnerProps<T extends ITimelineItem> {
  // To be removed
  detachItemFromStore: () => void;
  attachItemToStore: () => void;

  // only for timeline dragging (as it can allows changing z-index fluently)
  detachAllItemsFromStore: () => void;
  attachAllItemsToStore: () => void;

  detachSelectedItemsFromStore: () => void;
  attachSelectedItemsToStore: () => void;

  updateItem: (newData: Partial<T>) => void;
  moveItem: (
    itemId: string,
    buttonsScale: number,
    newX: number,
    newY: number
  ) => void;
  itemWrapperHtmlElsRef: React.RefObject<Record<string, HTMLElement>>;
  updateItemZIndexById: (itemId: string, draggingItemId: string) => void;
  updatePositionInTime: (
    itemId: string,
    startTime: number,
    endTime: number,
    dragingType: 'left' | 'right' | 'whole'
  ) => void;
  item: T;
  isDetached: boolean;

  isSelected: boolean;
  selectedItemIds: string[];
  selectTimelineItem: () => void;
  deselectTimelineItem: () => void;

  isMultiSelect: boolean;
  isThumbnailEditorActive: boolean;
}

/**
 * Remove from T the keys that are in common with K
 */
type Optionalize<T extends K, K> = Omit<T, keyof K>;
export const detachableStoreHoc = <
  K extends ITimelineItem,
  T extends IDetachableInnerProps<K>
>(
  Klass: React.ComponentType<T>
) => {
  const WrappedClass = (
    props: IOuterProps &
      Optionalize<T, IDetachableInnerProps<K>> & {
        forwardedRef: React.Ref<any>;
      }
  ) => {
    const { id, forwardedRef, ...rest } = props;

    const consumerProps = React.useContext(DetachedStoreContext);

    if (!consumerProps) {
      throw new Error(
        `Component wrapped in detachableStoreHOC cannot be used outside of DetachableStoreProvider`
      );
    }

    const {
      detachedItemsById,
      attachItemToStore,
      attachAllItemsToStore,
      detachItemFromStore,
      detachAllItemsFromStore,
      detachSelectedItemsFromStore,
      attachSelectedItemsToStore,
      timelineItems,
      thumbnailItems,
      updateItem,
      swapZIndexes,
      selectedItemIds,
      updatePositionInTime,
      moveItem,
      itemWrapperHtmlElsRef,
      handleMultiDeselect,
      isThumbnailEditorActive,
    } = consumerProps;

    const currentItemFromStore =
      timelineItems.find((x) => x.id === id)! ||
      thumbnailItems.find((x) => x.id === id)!;
    let isDetached = true;
    let detachedItem = detachedItemsById[id];
    // item is not detached - fetch from store
    if (!detachedItem) {
      isDetached = false;
      detachedItem = currentItemFromStore;
    }

    const detachStoreRequestWrap = React.useCallback(
      () => detachItemFromStore(id),
      [id, detachItemFromStore]
    );

    const attachStoreRequestWrap = React.useCallback(
      () => attachItemToStore(id),
      [id, attachItemToStore]
    );

    const updateItemRequestWrap = React.useCallback(
      (newData: Partial<ITimelineItem>) => updateItem(id, newData),
      [id, updateItem]
    );

    const isSelected = selectedItemIds.indexOf(id) > -1;

    // TODO: TS 4.1 fix typing
    // <Klass ... {...((rest as unknown) as T)}

    return (
      <Klass
        {...({
          detachItemFromStore:
            detachStoreRequestWrap as T['detachAllItemsFromStore'],
          detachAllItemsFromStore: detachAllItemsFromStore,
          detachSelectedItemsFromStore: detachSelectedItemsFromStore,
          attachSelectedItemsToStore: attachSelectedItemsToStore,
          attachItemToStore: attachStoreRequestWrap,
          attachAllItemsToStore: attachAllItemsToStore,
          updateItem: updateItemRequestWrap,
          updateItemZIndexById: swapZIndexes,
          item: detachedItem,
          isDetached: isDetached,
          isSelected: isSelected,
          updatePositionInTime: updatePositionInTime,
          moveItem: moveItem,
          itemWrapperHtmlElsRef: itemWrapperHtmlElsRef,
          selectedItemIds: selectedItemIds,
          handleMultiDeselect: handleMultiDeselect,
          isMultiSelect: selectedItemIds.length > 1,
          isThumbnailEditorActive,
          ref: forwardedRef,
          ...(rest as unknown as Omit<
            T,
            | 'detachItemFromStore'
            | 'detachAllItemsFromStore'
            | 'detachSelectedItemsFromStore'
            | 'attachSelectedItemsToStore'
            | 'attachItemToStore'
            | 'attachAllItemsToStore'
            | 'updateItem'
            | 'updateItemZIndexById'
            | 'item'
            | 'isDetached'
            | 'isSelected'
            | 'updatePositionInTime'
            | 'moveItem'
            | 'itemWrapperHtmlElsRef'
            | 'selectedItemIds'
            | 'isMultiSelect'
            | 'isThumbnailEditorActive'
          >),
        } as unknown as T)}
      />
    );
  };

  return React.memo(
    React.forwardRef<
      any,
      IOuterProps & Optionalize<T, IDetachableInnerProps<K>>
    >((props, ref) => <WrappedClass {...props} forwardedRef={ref} />)
  );
};
