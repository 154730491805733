import React, { memo } from 'react';
import { isArray, get } from 'lodash-es';
import cx from 'classnames';
import { secondsToHms } from '@voomly/utils';
import { IPlayerTemplate } from '../../../types/player';
import { IThumbnailGrid, IThumbnailGrid_V2 } from '../../../types/video';
import PopoverTip from '../PopoverTip/PopoverTip';
import { getStyles } from '../skins/getStyles';
import { usePlayerSkin } from '../skins/PlayerSkinContext';
import styles from './progressBar.module.sass';

const isOdd = (n: number) => Math.abs(n % 2) === 1;

const TipWithPreview = ({
  thumbnailGrids,
  isVisible,
  position,
  duration,
  player,
}: {
  isVisible: boolean;
  position: number;
  duration: number;
  player: IPlayerTemplate;
  thumbnailGrids?: IThumbnailGrid[] | IThumbnailGrid_V2;
}) => {
  const skin = usePlayerSkin();
  const currentTimestamp = Number((duration * position).toFixed());
  const showTime = get<IPlayerTemplate, 'controls', 'showTime'>(player, [
    'controls',
    'showTime',
  ]);

  let gridSrc;
  let imageCoordinates = [0, 0, 0, 0];

  if (thumbnailGrids && !isArray(thumbnailGrids)) {
    const width = thumbnailGrids.width;
    const height = thumbnailGrids.height;
    const imagesOnGrid = thumbnailGrids.tile.width * thumbnailGrids.tile.height;
    const coordinates: Array<[number, number, number, number]> = [];
    const imageNumber = Math.floor(currentTimestamp / thumbnailGrids.interval);
    const imageNumberOnGrid = Math.floor(
      (currentTimestamp / thumbnailGrids.interval) % imagesOnGrid
    );
    const gridNumber = Math.floor(imageNumber / imagesOnGrid);

    for (let i = 0; i < thumbnailGrids.tile.width; i++) {
      for (let j = 0; j < thumbnailGrids.tile.height; j++) {
        coordinates.push([
          width * j,
          height * i,
          width * (j + 1),
          height * (i + 1),
        ]);
      }
    }

    const imageX = coordinates[imageNumberOnGrid]
      ? coordinates[imageNumberOnGrid][0]
      : 0;
    const imageY = coordinates[imageNumberOnGrid]
      ? coordinates[imageNumberOnGrid][1]
      : 0;

    return (
      <div
        className={cx(styles.tipTime, skin.classes.progressBar__previews, {
          [styles.visible]: isVisible,
        })}
        style={{
          left: `${position * 100}%`,
          ...getStyles('progressBar__previews', skin, player),
        }}
      >
        {isVisible && (
          <div
            className={styles.tipImage}
            style={{
              width,
              height,
              backgroundImage: `url(${thumbnailGrids.urlPattern.replace(
                '{index}',
                gridNumber.toString()
              )})`,
              backgroundPosition: `${-imageX}px ${-imageY}px`,
              backgroundRepeat: 'no-repeat',
            }}
          />
        )}
        {showTime && secondsToHms(currentTimestamp)}
        <PopoverTip skin={skin} config={player} />
      </div>
    );
  }

  if (thumbnailGrids && isArray(thumbnailGrids)) {
    const grid = thumbnailGrids.find(
      ({ fromTimestamp, toTimestamp }) =>
        currentTimestamp >= fromTimestamp && currentTimestamp <= toTimestamp + 1
    );
    gridSrc = grid?.url;
    const image = grid?.images.find(({ timestamp }) => {
      return (
        currentTimestamp === timestamp ||
        (isOdd(currentTimestamp) && currentTimestamp - 1 === timestamp)
      );
    });
    if (image) imageCoordinates = image.coordinates;
  }

  const [x, y, xx, yy] = imageCoordinates;
  const imgWidth = xx - x;
  const imgHeight = yy - y;

  return (
    <div
      className={cx(styles.tipTime, { [styles.visible]: isVisible })}
      style={{ left: `${position * 100}%` }}
    >
      {isVisible && gridSrc && (
        <div
          className={styles.tipImage}
          style={{
            width: imgWidth,
            height: imgHeight,
            backgroundImage: `url(${gridSrc})`,
            backgroundPosition: `${-x}px ${-y}px`,
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}
      {showTime && secondsToHms(currentTimestamp)}
    </div>
  );
};

export default memo(TipWithPreview);
