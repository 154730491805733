import { IPlayerTemplate, IPlayerExtended } from '../../../types/player';

export const shouldShowReWatchButton = (
  player: IPlayerTemplate,
  isVideoEnded: boolean
) => {
  const {
    controls: { showRewatchButton },
    general: {
      onEnd: { action },
    },
  } = player;
  const extendedPlayer = player as IPlayerExtended;

  return (
    !extendedPlayer.playNodeIdOnEnd &&
    showRewatchButton &&
    isVideoEnded &&
    action !== 'LOOP'
  );
};
