import { IPlayerSkin, IPlayerSkinKey } from './types';

export const importAllPlayerSkins = async () => {
  return await import('./playerSkins');
};

export const findAndImportPlayerSkin = async (
  skinKey: IPlayerSkinKey
): Promise<IPlayerSkin> => {
  switch (skinKey) {
    case 'pult':
      const pult = await import('./pult/pult');
      return pult.pultSkin;
    case 'rounded':
      const rounded = await import('./rounded/rounded');
      return rounded.roundedSkin;
    case 'line':
      const line = await import('./line/line');
      return line.lineSkin;
    case 'panel':
      const panel = await import('./panel/panel');
      return panel.panelSkin;
    case 'buttons':
      const buttons = await import('./buttons/buttons');
      return buttons.buttonsSkins;
    case 'circles':
      const circles = await import('./circles/circles');
      return circles.circlesSkin;
    case 'gradient':
      const gradient = await import('./gradient/gradient');
      return gradient.gradientSkin;
    case 'wellRounded':
      const wellRounded = await import('./wellRounded/wellRounded');
      return wellRounded.wellRoundedSkin;
    case 'bottomProgressBar':
      const bottomProgressBar = await import(
        './bottomProgressBar/bottomProgressBar'
      );
      return bottomProgressBar.bottomProgressBarSkin;
    case 'default':
    default:
      const skin = await import('./default/default');
      return skin.defaultSkin;
  }
};
