import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

export interface ICustomIntegrations {
  turnstile: boolean;
}

const initialState: ICustomIntegrations = {
  turnstile: false,
};

type IAction = ActionType<typeof actions>;

export const customIntegrationsReducer = (
  state: ICustomIntegrations = initialState,
  action: IAction
): ICustomIntegrations => {
  switch (action.type) {
    case getType(actions.enableCustomTurnstileIntegration):
      return { ...state, turnstile: true };
  }

  return state;
};
