import * as React from 'react';
import styled from 'styled-components';
import { Flex } from '@voomly/ui/player-deps';
import { ReactComponent as ArrowRightIcon } from '../../../svg/arrow-right.svg';
import { IVideoItemSharedProps } from '../sharedTypes';
import {
  VideoItemCommonButton,
  VideoItemCommonText,
} from '../shared/commonComponents';
import { useCallback } from 'react';
import { ITimelineItem, ITimelineTurnstile } from '../../../types/types';
import { IDetachableInnerProps } from '../detachableStoreHoc';
import { useTinyForm } from '@voomly/use-tiny-form';

interface IWithButtonScaleProp {
  buttonsScale: number;
}

type IStyledBackgroundDiv = Pick<
  ITimelineTurnstile,
  'backgroundColor' | 'backgroundOpacity'
> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
const StyledBackgroundDiv = styled(
  ({ backgroundColor, backgroundOpacity, ...rest }: IStyledBackgroundDiv) => (
    <div {...rest} />
  )
)<IStyledBackgroundDiv>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ backgroundOpacity }) => backgroundOpacity / 100};
`;

const StyledTurnstileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  /* $zTurnstile: 11 */
  z-index: 11;
  width: 100%;
  height: 100%;
`;

const StyledPreviewBadge = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  font-size: 9px;
  color: #fff;
  opacity: 0.7;
  text-transform: uppercase;
`;

const StyledFormWrapper = styled.div<IWithButtonScaleProp>`
  position: relative;
  max-width: 100%;
  width: ${({ buttonsScale }) => `${400 * buttonsScale}px`};
  color: #fff;
  text-align: left;
  form {
    width: 100%;
    height: 100%;
  }
  label {
    display: block;
    margin: 0;
    margin-bottom: ${({ buttonsScale }) => `${5 * buttonsScale}px`};
    font-size: ${({ buttonsScale }) => `${14 * buttonsScale}px`};
  }
  input {
    width: 100%;
  }
`;

const StyledInputsRow = styled(({ buttonsScale, ...rest }) => (
  <div {...rest} />
)).attrs<IWithButtonScaleProp>(
  ({
    buttonsScale,
  }): {
    style: React.CSSProperties;
  } => ({
    style: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      marginBottom: 15 * buttonsScale,
    },
  })
)<IWithButtonScaleProp>`
  > div {
    width: ${({ buttonsScale }) => `calc(50% - ${10 * buttonsScale}px)`};
  }
`;

const StyledInput = styled.input.attrs<IWithButtonScaleProp>(
  ({
    buttonsScale,
  }): {
    style: React.CSSProperties;
  } => ({
    style: {
      background: 'rgba(255, 255, 255, 0.2)',
      border: '1px solid #ffffff',
      height: 30 * buttonsScale,
      fontSize: 14 * buttonsScale,
      padding: 10 * buttonsScale,
      color: '#fff',
    },
  })
)<IWithButtonScaleProp>``;

const StyledError = styled.div.attrs<IWithButtonScaleProp>(
  ({
    buttonsScale,
  }): {
    style: React.CSSProperties;
  } => ({
    style: {
      marginTop: 6 * buttonsScale,
      fontSize: 12 * buttonsScale,
      color: '#ff7171',
    },
  })
)<IWithButtonScaleProp>``;

type IStyledSkipButton = IWithButtonScaleProp & {
  skipText: string;
} & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;
const StyledSkipButton = styled(
  ({ buttonsScale, skipText, ...rest }: IStyledSkipButton) => {
    return (
      <button type="button" {...rest}>
        {skipText} <ArrowRightIcon />
      </button>
    );
  }
).attrs<IWithButtonScaleProp>(
  ({
    buttonsScale,
  }): {
    style: React.CSSProperties;
  } => ({
    style: {
      marginTop: 6 * buttonsScale,
      fontSize: 11 * buttonsScale,
      bottom: 8 * buttonsScale,
      right: 8 * buttonsScale,
      padding: `${10 * buttonsScale}px ${20 * buttonsScale}px`,
    },
  })
)`
  && {
    position: absolute;
    z-index: 2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1;
    color: #fff;
    border: 0;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      color: #fff;
      background: rgba(255, 255, 255, 0.2);
    }
    svg {
      margin-left: 8px;
    }
  }
`;

export interface IBaseTurnstileFormValues {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ITurnstileItemProps
  extends IDetachableInnerProps<ITimelineTurnstile>,
    IVideoItemSharedProps {
  onDismiss: (item: ITimelineItem, remember?: boolean) => void;
}

interface IPropsWithConfig extends ITurnstileItemProps {
  titleText: React.ReactNode;
  subTitleText: React.ReactNode;
  formFirstNameText: React.ReactNode;
  formLastNameText: React.ReactNode;
  formEmailText: React.ReactNode;
  formEmailTextRaw: string;
}

interface ICallbacks {
  onSkip: () => void;
  onSubmit: (values: IBaseTurnstileFormValues) => Promise<boolean>;
}

export const BaseTurnstileItem = ({
  item,
  buttonsScale,
  isPreviewMode,
  titleText,
  subTitleText,
  formFirstNameText,
  formLastNameText,
  formEmailText,
  onWrapperMouseDown,
  onSkip,
  onSubmit,
}: IPropsWithConfig & ICallbacks & { isPreviewMode: boolean }) => {
  const { isNameRequired, skippable } = item;

  const handleSkipTurnstile = useCallback(() => {
    onSkip();
  }, [onSkip]);

  const { handleSubmit, isSubmitting, values, setValue, errors } = useTinyForm(
    { email: '', firstName: '', lastName: '' },
    async ({ values }) => {
      await onSubmit(values);
    }
  );

  return (
    <StyledTurnstileWrapper onMouseDown={onWrapperMouseDown}>
      <StyledBackgroundDiv
        backgroundColor={item.backgroundColor}
        backgroundOpacity={item.backgroundOpacity}
      />
      {isPreviewMode && <StyledPreviewBadge>Preview mode</StyledPreviewBadge>}
      {skippable && (
        <StyledSkipButton
          skipText={item.skipText}
          onClick={handleSkipTurnstile}
          buttonsScale={buttonsScale}
        />
      )}
      <StyledFormWrapper buttonsScale={buttonsScale}>
        <form onSubmit={handleSubmit}>
          <VideoItemCommonText
            buttonsScale={buttonsScale}
            fontSize={item.titleFontSize}
            fontStyle={['align-center', ...item.titleFontStyle]}
            color={item.titleFontColor}
            margin={`0 0 ${10 * buttonsScale}px`}
            padding="0"
          >
            {titleText}
          </VideoItemCommonText>
          <VideoItemCommonText
            buttonsScale={buttonsScale}
            fontSize={item.subTitleFontSize}
            fontStyle={['align-center', ...item.subTitleFontStyle]}
            color={item.subTitleFontColor}
            margin={`0 0 ${20 * buttonsScale}px`}
            padding="0"
          >
            {subTitleText}
          </VideoItemCommonText>
          {isNameRequired && (
            <StyledInputsRow buttonsScale={buttonsScale}>
              <div>
                <label>{formFirstNameText}</label>
                <StyledInput
                  type="text"
                  required
                  name="firstName"
                  buttonsScale={buttonsScale}
                  value={values.firstName}
                  onChange={(e) => setValue('firstName', e.target.value)}
                />
                {errors.firstName && (
                  <StyledError buttonsScale={buttonsScale}>
                    {errors.firstName}
                  </StyledError>
                )}
              </div>
              <div>
                <label>{formLastNameText}</label>
                <StyledInput
                  type="text"
                  required
                  name="lastName"
                  value={values.lastName}
                  buttonsScale={buttonsScale}
                  onChange={(e) => setValue('lastName', e.target.value)}
                />
                {errors.lastName && (
                  <StyledError buttonsScale={buttonsScale}>
                    {errors.lastName}
                  </StyledError>
                )}
              </div>
            </StyledInputsRow>
          )}
          <div>
            <label>{formEmailText}</label>
            <StyledInput
              type="email"
              required
              name="email"
              value={values.email}
              buttonsScale={buttonsScale}
              onChange={(e) => setValue('email', e.target.value)}
            />
            {errors.email && (
              <StyledError buttonsScale={buttonsScale}>
                {errors.email}
              </StyledError>
            )}
          </div>
          <Flex container={true} justifyContent="center">
            <VideoItemCommonButton
              type="submit"
              disabled={isSubmitting}
              fontSize={item.buttonFontSize}
              backgroundColor={item.buttonBackgroundColor}
              color={item.buttonTextColor}
              borderRadius={item.buttonBorderRadius}
              buttonsScale={buttonsScale}
              padding={`${10 * buttonsScale}px ${24 * buttonsScale}px`}
              margin={`${20 * buttonsScale}px 0 0`}
            >
              {item.buttonText}
            </VideoItemCommonButton>
          </Flex>
        </form>
      </StyledFormWrapper>
    </StyledTurnstileWrapper>
  );
};
