import styled from 'styled-components';

export const Dim = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const LightDim = styled.div`
  transition-property: opacity;
  transition-duration: 0.2s;
  opacity: 0.4;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
`;
