import { IPlayerTemplate } from '../../../types/player';
import { IControlsClasses, IPlayerSkin } from './types';

export const getStyles = (
  className: IControlsClasses,
  skin: IPlayerSkin,
  config: IPlayerTemplate
) => {
  const bgColorGetter = skin.bgColors ? skin.bgColors[className] : undefined;
  const colorGetter = skin.colors ? skin.colors[className] : undefined;
  const strokeGetter = skin.stroke ? skin.stroke[className] : undefined;
  const gradientGetter = skin.bgGradient
    ? skin.bgGradient[className]
    : undefined;

  return {
    backgroundColor: bgColorGetter ? bgColorGetter(config, true) : undefined,
    backgroundImage: gradientGetter ? gradientGetter(config) : undefined,
    color: colorGetter ? colorGetter(config) : undefined,
    stroke: strokeGetter ? strokeGetter(config) : undefined,
  };
};
