import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { IMiscScreenObjects } from './types';

const initialState: IMiscScreenObjects = {
  alternativeDurationLine: undefined,
  swallowPlayPauseOverlay: false,
  backButtonReferences: [],
};

type IAction = ActionType<typeof actions>;
const reducer = (
  state: IMiscScreenObjects = initialState,
  action: IAction
): IMiscScreenObjects => {
  switch (action.type) {
    case getType(actions.toggleSwallowPlayPauseOverlay):
      return {
        ...state,
        swallowPlayPauseOverlay: action.payload,
      };

    case getType(actions.toggleAlternativeDurationLine): {
      return {
        ...state,
        alternativeDurationLine: action.payload,
      };
    }

    case getType(actions.updateAlternativeDurationLineProgress): {
      if (!state.alternativeDurationLine) return state;

      return {
        ...state,
        alternativeDurationLine: {
          ...state.alternativeDurationLine,
          percents: action.payload,
        },
      };
    }

    case getType(actions.appendBackButtonReference): {
      return {
        ...state,
        backButtonReferences: [...state.backButtonReferences, action.payload],
      };
    }

    case getType(actions.popBackButtonReference): {
      return {
        ...state,
        backButtonReferences: state.backButtonReferences.slice(0, -1),
      };
    }
  }

  return state;
};

export default reducer;
