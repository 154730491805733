import * as React from 'react';
import removeOperaPiP from '../removeOperaPiP';
import {
  IGeneralVideoControlInterface,
  IGeneralVideoControlProps,
  IInternalVideoProps,
} from '../videoControlInterface';
import { endsWith } from 'lodash-es';
import { VideoStateForLoaderListener } from '../VideoStateForLoaderListener';
import { mergeRefs } from '@voomly/utils';

interface IGeneralVideoControlState {
  hlsUrl?: string;
}

class NativeVideo
  extends React.Component<
    IGeneralVideoControlProps & IInternalVideoProps,
    IGeneralVideoControlState
  >
  implements IGeneralVideoControlInterface
{
  private video: HTMLVideoElement | undefined | null;
  private videoStateListener = new VideoStateForLoaderListener();
  private videoRef = React.createRef<HTMLVideoElement>();
  private sourceRef = React.createRef<HTMLSourceElement>();
  private isComponentMounted: boolean;
  private isVideoBufferedFirstTime = false;

  private isReportingVolumeChanges = true;
  private isVolumeSetFirstTime = false;
  private isPlayRequestedFirstTime = false;

  private lastSeek?: number;

  state = {
    hlsUrl: undefined,
  };

  componentDidMount() {
    this.isComponentMounted = true;

    this.video = this.videoRef.current;
    this.videoStateListener.listen(this.props.onVideoBufferingToggle);

    if (this.video) {
      this.props.onMount(this);

      const video = this.video;

      video.addEventListener('ended', this.handleEnded);
      video.addEventListener('playing', this.handleVideoStartPlaying);
      video.addEventListener('play', this.handleVideoPlayRequested);
      video.addEventListener('pause', this.handleVideoStoppedPlaying);
      video.addEventListener('volumechange', this.handleVideoVolumeChange);
      video.addEventListener('timeupdate', this.handleTimeUpdate);
      video.addEventListener('ratechange', this.handleVideoRateChange);
      video.addEventListener('error', this.onError);
      video.addEventListener('seeking', this.handleSeeking);
      video.addEventListener('seeked', this.handleSeeked);
      video.addEventListener('canplaythrough', this.handleCanPlayThrough);

      setTimeout(() => {
        if (this.isComponentMounted) {
          this.props.onReady();
        }
      });
    }

    if (this.sourceRef.current) {
      this.sourceRef.current.addEventListener('error', this.onError);
    }

    removeOperaPiP();
  }

  componentDidUpdate(
    prevProps: Readonly<IGeneralVideoControlProps>,
    prevState: Readonly<IGeneralVideoControlState>
  ) {
    if (
      (prevState.hlsUrl !== this.state.hlsUrl ||
        prevProps.url !== this.props.url) &&
      this.video
    ) {
      const currentTime = this.video.currentTime;
      const wasPlaying = this.getIsPlaying();

      if (wasPlaying) {
        this.video.addEventListener(
          'loadeddata',
          () => {
            this.play();
          },
          { once: true }
        );
      }

      this.video.load();
      this.video.currentTime =
        (prevProps.url !== this.props.url
          ? this.props.nextCurrentTime
          : undefined) ?? currentTime;

      this.videoStateListener.notify(() => this.video?.readyState || 0);
    }

    if (prevProps.url !== this.props.url) {
      this.setState({ hlsUrl: undefined });

      this.isPlayRequestedFirstTime = false;
      this.isVideoBufferedFirstTime = false;
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;

    if (this.video) {
      const video = this.video;
      video.removeEventListener('ended', this.handleEnded);
      video.removeEventListener('playing', this.handleVideoStartPlaying);
      video.removeEventListener('play', this.handleVideoPlayRequested);
      video.removeEventListener('pause', this.handleVideoStoppedPlaying);
      video.removeEventListener('volumechange', this.handleVideoVolumeChange);
      video.removeEventListener('timeupdate', this.handleTimeUpdate);
      video.removeEventListener('ratechange', this.handleVideoRateChange);
      video.removeEventListener('error', this.onError);
      video.removeEventListener('seeking', this.handleSeeking);
      video.removeEventListener('seeked', this.handleSeeked);
      video.removeEventListener('canplaythrough', this.handleCanPlayThrough);
    }

    if (this.sourceRef.current) {
      this.sourceRef.current.removeEventListener('error', this.onError);
    }
  }

  public getIsMuted = () => {
    return this.video?.muted ?? false;
  };

  public getIsPlaying = () => {
    return this.video ? !this.video.paused : false;
  };

  public getCurrentTime = () => {
    return this.video?.currentTime ?? 0;
  };

  public seekAndBuffer = (time: number, cb: () => void) => {
    if (!this.video) {
      return;
    }

    this.video.addEventListener(
      'seeked',
      () => {
        // When video is not started video
        // don't send canplaythrough event - so exec callback right away
        if (!this.isPlayRequestedFirstTime) {
          cb();
        } else {
          this.video?.addEventListener(
            'canplaythrough',
            () => {
              cb();
            },
            { once: true }
          );
        }
      },
      { once: true }
    );

    this.seek(time);
  };

  play = async () => {
    if (this.video) {
      if (!this.isPlayRequestedFirstTime) {
        this.isPlayRequestedFirstTime = true;
      }

      await this.video.play();
    }
  };

  getVolume = () => {
    return this.video?.volume ?? 1;
  };

  getDuration() {
    return this.video?.duration ?? 0;
  }

  pause = () => {
    this.video?.pause();
  };

  seek = (time: number) => {
    this.lastSeek = time;
    if (this.video) {
      this.video.currentTime = time;
    }
  };

  setVolume = (value: number) => {
    if (this.video) {
      if (!this.isVolumeSetFirstTime) {
        this.isVolumeSetFirstTime = true;
      }
      this.video.volume = value;
    }
  };

  setMuted = (value: boolean) => {
    if (this.video) {
      this.video.muted = value;
    }
  };

  getMuted = () => {
    return this.video?.muted ?? false;
  };

  setSpeed = (value: number) => {
    if (this.video) {
      this.video.playbackRate = value;
    }
  };

  setQuality(quality: number, hlsUrl: string): void {
    if (!this.video || !this.sourceRef.current) {
      return;
    }

    this.setState({
      hlsUrl,
    });

    this.props.onQualityChange(quality);
  }

  onError = () => {
    const { onVideoSourceError } = this.props;
    onVideoSourceError('');
  };

  private handleEnded = () => {
    if (this.video) {
      this.props.onEnded();
    }
  };

  private handleVideoPlayRequested = () => {
    if (this.video) {
      this.props.onPlayRequested();
    }
  };

  private handleVideoStartPlaying = () => {
    if (this.video) {
      this.props.onPlay();
    }
  };

  private handleVideoStoppedPlaying = () => {
    if (this.video) {
      this.props.onPause();
    }
  };

  private handleVideoVolumeChange = () => {
    // Volume is toggling from muted to unmuted for the first time for no reason
    // (don't allow to do that if we didn't set it manually)
    if (
      this.video &&
      this.isVideoBufferedFirstTime &&
      this.isReportingVolumeChanges &&
      this.isVolumeSetFirstTime
    ) {
      this.props.onVolumeChange(this.video.volume, this.video.muted);
    }
  };

  private handleTimeUpdate = () => {
    this.videoStateListener.notify(() => this.video?.readyState || 0);

    // Time update has race with seek,
    // so we need to be sure that latest seek where executed
    // + update current time when video is ready to play
    if (
      this.video &&
      (!this.lastSeek ||
        Math.abs(this.lastSeek - this.video.currentTime) < 1) &&
      this.video.readyState === 4
    ) {
      this.props.onTimeUpdate(this.video.currentTime);
      this.lastSeek = undefined;
    }
  };

  private handleCanPlayThrough = () => {
    if (!this.isVideoBufferedFirstTime) {
      this.props.onReady();
      this.isVideoBufferedFirstTime = true;
    }
  };

  private handleVideoRateChange = () => {
    if (this.video) {
      this.props.onRateChange(this.video.playbackRate);
    }
  };

  private handleSeeked = () => {
    if (this.video) {
      this.props.onSeeking(this.video.currentTime, 'seeked');
    }
  };

  private handleSeeking = () => {
    if (this.video) {
      this.props.onSeeking(this.video.currentTime, 'seeking');
    }
  };

  render() {
    const { url, loop, muted } = this.props;
    const { hlsUrl } = this.state;
    const sourceUrl = hlsUrl || url;

    // mp4 is used only for local files to make possible add local video to our player
    const isPlayingLocalFile = endsWith(sourceUrl, '.mp4');

    return (
      <video
        // safari requires `playsInline` attr for autoplay and manual calls of `play` method.
        // https://webkit.org/blog/6784/new-video-policies-for-ios/
        playsInline
        controls={false}
        crossOrigin="anonymous"
        ref={mergeRefs(this.videoRef, this.props.videoRef)}
        muted={muted}
        //  If the developer does not set the poster attribute,
        //  Android will set its own poster image, which does not have the proper origin and will cause a CORS exception.
        poster="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        // autoPlay={autoplay} – do NOT use it here! We do .play() using PlaybackManager
        // having it here triggers double auto-play which spawns a error
        loop={loop}
      >
        {/* Source order is matter, overwise safari will not get video file */}
        {isPlayingLocalFile ? (
          <source src={sourceUrl} type="video/mp4" ref={this.sourceRef} />
        ) : (
          <source
            src={sourceUrl}
            type="application/x-mpegURL"
            ref={this.sourceRef}
          />
        )}
      </video>
    );
  }

  public pauseReportingVolumeChanges = () => {
    this.isReportingVolumeChanges = false;
  };

  public resumeReportingVolumeChanges = () => {
    this.isReportingVolumeChanges = true;
  };
}

export default NativeVideo;
