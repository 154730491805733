// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".totalTime_root__3jXGI{display:inline-block;position:relative;padding:0 12px 0 8px;pointer-events:none;color:currentColor}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/ProgressBar/TotalTime/totalTime.module.sass"],"names":[],"mappings":"AAAA,uBACE,oBAAA,CACA,iBAAA,CACA,oBAAA,CACA,mBAAA,CACA,kBAAA","sourcesContent":[".root\n  display: inline-block\n  position: relative\n  padding: 0 12px 0 8px\n  pointer-events: none\n  color: currentColor"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "totalTime_root__3jXGI"
};
export default ___CSS_LOADER_EXPORT___;
