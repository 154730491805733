/*
 * This detection method identifies Internet Explorers up until version 11.
 *
 * Reference: https://msdn.microsoft.com/en-us/library/ms537503(v=vs.85).aspx
 */
export function isInternetExplorerBefore(version: number) {
  const iematch = /MSIE ([0-9]+)/g.exec(window.navigator.userAgent);

  const val = iematch ? (iematch[1] || '', 10) : NaN;

  return iematch ? val < version : false;
}
