import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { IPlayPauseOverlayShape } from './types';

const initialState: IPlayPauseOverlayShape = {
  blockedBy: [],
};

type IAction = ActionType<typeof actions>;
const reducer = (
  state: IPlayPauseOverlayShape = initialState,
  action: IAction
): IPlayPauseOverlayShape => {
  switch (action.type) {
    case getType(actions.blockPausePauseOverlay):
      return {
        ...state,
        blockedBy: state.blockedBy.concat(action.payload),
      };

    case getType(actions.unblockPausePauseOverlay):
      return {
        ...state,
        blockedBy: state.blockedBy.filter((b) => b !== action.payload),
      };
  }

  return state;
};

export default reducer;
