export const normalizeRatio = (ratio: string) => {
  if (!ratio) {
    return;
  }

  if (!ratio.includes(':')) {
    return parseFloat(ratio);
  }

  const split = ratio.split(':');
  return parseInt(split[0], 10) / parseInt(split[1], 10);
};
