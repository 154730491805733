const FRAME_COUNT = 60;
const DURATION = 1000;
const FRAME_DURATION = DURATION / FRAME_COUNT;
const DASH_OFFSET = 221;
const DASH_OFFSET_STEP = DASH_OFFSET / FRAME_COUNT;
const ANIMATING_CLASS = 'animating';

export const animateDashOffset = (element: SVGPathElement) => {
  if (element.classList.contains(ANIMATING_CLASS)) {
    return;
  }

  element.classList.add(ANIMATING_CLASS);

  const intervalId = window.setInterval(() => {
    const dashOffset = parseFloat(element.style.strokeDashoffset) || 0;
    element.style.strokeDashoffset = (dashOffset + DASH_OFFSET_STEP).toString();
    if (dashOffset >= 221) {
      element.style.strokeDashoffset = '0';
    }
  }, FRAME_DURATION);

  const autoClearId = window.setInterval(() => {
    if (!document.body.contains(element)) {
      clearInterval(intervalId);
      clearInterval(autoClearId);
    }
  }, 3000);

  return {
    clear: () => {
      clearInterval(intervalId);
      clearInterval(autoClearId);
    },
  };
};
