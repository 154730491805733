import isMobileJs from 'ismobilejs';
import { isSafari } from './browserInfo';

const isSSR = typeof window === 'undefined';
const mobileDetect = !isSSR && isMobileJs(window.navigator);

// iPad OS 13
const iPad =
  !isSSR && navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;

// md.is('iPad') is pre OS 13 check
export const isIOS = (mobileDetect && mobileDetect.apple.device) || iPad;

export const isMobile = Boolean(mobileDetect && mobileDetect.phone);

let canPlayCached: undefined | boolean;
const canPlay = () => {
  if (canPlayCached !== undefined) return canPlayCached;

  const video = document.createElement('video');

  canPlayCached = Boolean(
    video.canPlayType('application/vnd.apple.mpegURL') ||
      video.canPlayType('audio/mpegurl')
  );

  video.remove();

  return canPlayCached;
};

export const isHlsNativelySupported = () => {
  return (
    isSafari ||
    isIOS ||
    // hls.js doesn't support UCBrowser
    navigator.userAgent.indexOf(' UCBrowser/') >= 0 ||
    canPlay()
  );
};
