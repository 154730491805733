import * as React from 'react';
import { memo } from 'react';
import cx from 'classnames';
import styles from './sliderHandle.module.sass';

const SliderHandle = ({
  className,
  position,
  handleStyle,
  vertical,
  classNamePoiner,
  classNameValue,
  value,
  showPoiner,
}: {
  className?: string;
  position: string | number;
  handleStyle?: React.CSSProperties;
  vertical?: boolean;
  classNamePoiner?: string;
  classNameValue?: string;
  value?: string | false;
  showPoiner?: boolean;
}) => {
  const positionStyle = vertical ? { bottom: position } : { left: position };

  return (
    <div
      className={cx(styles.root, className, {
        [styles.vertical]: vertical,
        [styles.withPointer]: showPoiner,
      })}
      style={positionStyle}
    >
      {showPoiner && (
        <div
          className={cx(styles.handle, classNamePoiner)}
          style={handleStyle}
        />
      )}
      {value && (
        <div
          className={cx(styles.value, classNameValue, {
            [styles.withPointer]: showPoiner,
          })}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default memo(SliderHandle);
