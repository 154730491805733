import React, { memo } from 'react';
import cx from 'classnames';
import { getStyles } from '../skins/getStyles';
import styles from './popoverTip.module.sass';
import { IPlayerSkin } from '../skins/types';
import { IPlayerTemplate } from '../../../types/player';

const PopoverTip = ({
  skin,
  config,
}: {
  skin: IPlayerSkin;
  config: IPlayerTemplate;
}) => {
  return (
    <div
      className={cx(
        'popoverTip',
        styles.root,
        skin.classes.controls__popoverTip
      )}
      style={getStyles('controls__popoverTip', skin, config)}
    />
  );
};

export default memo(PopoverTip);
