import { EventEmitter } from 'eventemitter3';
import { IFromPlayerApiEventName } from '../FromPlayerEmitter';
import { VoomlyPayloadFromPlayer } from '../../VoomlyPlayerEvent';
import { VoomlyFromPlayerMessage } from '../../VoomlyPlayerMessage';

export class FromIFrameEventEmitter extends EventEmitter<IFromPlayerApiEventName> {
  public emit = <T extends IFromPlayerApiEventName>(
    event: T,
    args: VoomlyPayloadFromPlayer<T>
  ): boolean => {
    if (args.playerInfo) {
      window?.parent.postMessage(
        new VoomlyFromPlayerMessage<T>(event, args),
        '*'
      );
    }

    return super.emit(event, args);
  };
}
