import { all, fork, spawn, take, cancel } from 'redux-saga/effects';
import { initialVideoReadySaga } from './videoState/sagas/initialVideoReadySaga';
import { nextVideoSaga } from './videoState/sagas/nextVideoSaga';
import { playbackSettingsChangeSaga } from './videoState/sagas/playbackSettingsChangeSaga';
import { playEndedSaga } from './videoState/sagas/playEndedSaga';
import { controlVisibilitySaga } from './videoControls/controlVisibilitySaga';
import { passActionsToVideoApiSaga } from './videoState/sagas/passActionsToVideoApiSaga';
import { analyticsRootSaga } from './analytics/analyticsRootSaga';
import { sourceConfigurationSaga } from './sourceConfiguration/sourceConfigurationSaga';
import { playBlockerSaga } from './videoState/sagas/playBlockerSaga';
import { clickItemsSaga } from './videoItems/itemsClickSaga';
import { alternativeDurationLineSaga } from './miscScreenObjects/alternativeDurationLineSaga';
import { itemLifecycleSaga } from './videoItems/itemLifecycleSaga';
import { playerEventsSaga } from './videoState/sagas/playerEventsSaga';
import { cancelSpawnTasks } from './rootActions';

export function* rootPlayerSaga() {
  yield fork(function* () {
    yield all([
      alternativeDurationLineSaga(),
      sourceConfigurationSaga(),
      passActionsToVideoApiSaga(),
      initialVideoReadySaga(),
      playEndedSaga(),
      playbackSettingsChangeSaga(),
      nextVideoSaga(),
      controlVisibilitySaga(),
      clickItemsSaga(),
      playBlockerSaga(),
      itemLifecycleSaga(),
    ]);
  });

  // So if error will happen all other sagas will functional
  const analyticsTask = yield spawn(analyticsRootSaga);
  const playerEventsTask = yield spawn(playerEventsSaga);

  // Cancel other spawned tasks when player were destroyed
  yield take(cancelSpawnTasks);
  yield cancel([analyticsTask, playerEventsTask]);
}
