import React, { memo, useMemo } from 'react';

interface FlexProps {
  children?: any;
  className?: string;
  container?: boolean;
  /****** Container Props ********/
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit';
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /****** Child Props ********/
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: number;
  flex?: string;
  /****** Common Layout Props ********/
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  minWidth?: string;
  maxHeight?: string;
  minHeight?: string;
  style?: React.CSSProperties;
}

const FlexComp = (props: FlexProps) => {
  const styleProps = useMemo(
    () =>
      Object.fromEntries(
        Object.entries({
          display: props.container ? 'flex' : 'block',
          justifyContent: props.justifyContent,
          flexDirection: props.flexDirection,
          flexGrow: props.flexGrow,
          flexBasis: props.flexBasis,
          flexShrink: props.flexShrink,
          flexWrap: props.flexWrap,
          flex: props.flex,
          alignItems: props.alignItems,
          margin: props.margin,
          padding: props.padding,
          width: props.width,
          height: props.height,
          maxWidth: props.maxWidth,
          minWidth: props.minWidth,
          maxHeight: props.maxHeight,
          minHeight: props.minHeight,
          ...props.style,
        }).filter((_k, v) => v !== undefined)
      ),
    [
      props.alignItems,
      props.container,
      props.flex,
      props.flexBasis,
      props.flexDirection,
      props.flexGrow,
      props.flexShrink,
      props.flexWrap,
      props.height,
      props.justifyContent,
      props.margin,
      props.maxHeight,
      props.maxWidth,
      props.minHeight,
      props.minWidth,
      props.padding,
      props.style,
      props.width,
    ]
  );

  return (
    <div className={props.className} style={styleProps} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export const Flex = memo(FlexComp);
