import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../sourceConfiguration/actions';
import * as funnelStateActions from './actions';

export interface IFunnelState {
  muted?: boolean;
  volume?: number;

  stopAnalyticsNodeId?: string;
}

const initialState: IFunnelState = {
  muted: undefined,
  volume: undefined,

  stopAnalyticsNodeId: undefined,
};

type IAction = ActionType<typeof actions | typeof funnelStateActions>;

// Share state across the funnel and store it in one place
export const funnelStateReducer = (
  state: IFunnelState = initialState,
  action: IAction
): IFunnelState => {
  switch (action.type) {
    // Saving state right after request to navigation to overwrite values
    // when next video will autoplay
    case getType(actions.requestNavigateToOtherNode):
      return { ...state, ...action.payload };

    // Stop analytics to avoid spamming events for the seek/play/pause events for
    // previous video in funnel
    case getType(funnelStateActions.stopAnalyticsForNode):
      return { ...state, stopAnalyticsNodeId: action.payload.nodeId };
  }

  return state;
};
