export type IVerticalAlign =
  | 'vertical-align-top'
  | 'vertical-align-center'
  | 'vertical-align-bottom';

export const pickVerticalAlign = (
  verticalAlign: IVerticalAlign | undefined
) => {
  if (verticalAlign === 'vertical-align-top') return 'flex-start';
  else if (verticalAlign === 'vertical-align-center') return 'center';
  else if (verticalAlign === 'vertical-align-bottom') return 'flex-end';
  else return undefined;
};
