import { omit } from '@voomly/utils';
import { call, select, take } from 'redux-saga/effects';
import { createAnalyticsEvents } from '../../../api/analytics';
import {
  eAnalyticsEventType,
  IAnalyticsEventPageview,
} from '../../../types/analytics';
import { IConfigIds, getConfigIds } from '../../sourceConfiguration/selectors';
import { getUserInfo } from './getUserInfo';

// PageView will be triggered just when play shown,
// then session will be created once video element emit first event

function* sendPageView(config: IConfigIds) {
  const pageViewEv: IAnalyticsEventPageview = {
    name: eAnalyticsEventType.pageView,
    ...omit(config.ids, ['nodeId']),
  };

  yield call(createAnalyticsEvents, {
    user: getUserInfo(`page_view_${config.id}`),
    events: [pageViewEv],
    byBeacon: false,
  });
}

// This is needed to track conversion — view -> play
export function* pageViewSenderSaga() {
  let currentConfig: IConfigIds | undefined = getConfigIds(yield select());

  if (currentConfig) yield call(sendPageView, currentConfig);

  while (true) {
    yield take();

    const newConfig = getConfigIds(yield select());

    if ((currentConfig || { id: undefined }).id === newConfig?.id) continue;
    if (!newConfig) continue;

    yield sendPageView(newConfig);

    currentConfig = newConfig;
  }
}
