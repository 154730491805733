import { IDetachableInnerProps } from '../detachableStoreHoc';
import { ThemedStyledFunction } from 'styled-components';
import { IVideoItemSharedProps } from '../sharedTypes';
import { dynamicVideoItemsProps } from '../shared/commonComponents';
import { ITimelineText } from '../../../types/types';

export interface IPlainTextItemProps
  extends IDetachableInnerProps<ITimelineText>,
    IVideoItemSharedProps {}

export interface ITextWrapperProps {
  item: ITimelineText;
  buttonsScale: number;
}

export const createPlainTextItemStyled = (
  item: ThemedStyledFunction<any, any, any, any>
) => {
  return item.attrs<ITextWrapperProps>(({ item, buttonsScale }) =>
    dynamicVideoItemsProps({
      buttonsScale,
      padding: `${5 * buttonsScale}px`,
      ...item,
    })
  )<ITextWrapperProps>`
    display: flex;
    overflow: hidden;
    border-style: solid;
    word-break: break-word;
  `;
};
