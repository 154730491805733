import * as React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CrossIcon } from '../../svg/cross.svg';
import styles from './chapters.module.sass';
import ChapterLine from './ChapterLine';
import { IDefaultPropTypes, PlayerMode } from '../types/defaultPropTypes';
import { IPlayerRootReducerShape } from '../../store/rootPlayerStore';
import { useCallback } from 'react';
import { updateChaptersVisibility } from '../../store/chapters/actions';
import { requestSeek } from '../../store/videoState/actions';
import { getIsPlaybackBlocked } from '../../store/selectors';
import { useMouseHandlers } from '../../contexts/MouseMoveContext';
import { usePlayerSkin } from '../Controls/skins/PlayerSkinContext';

export const Chapters = ({ player, playerMode }: IDefaultPropTypes) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(updateChaptersVisibility(false));
  }, [dispatch]);

  const handleSeek = useCallback(
    (time: number) => {
      dispatch(requestSeek({ time, type: 'toTime' }));
    },
    [dispatch]
  );

  const isVisible =
    useSelector(
      (state: IPlayerRootReducerShape) =>
        state.chapters.isVisible && !getIsPlaybackBlocked(state)
    ) && playerMode !== PlayerMode.THUMBNAIL_EDITOR;

  const chapters = player.chapters;

  const mouseHandlers = useMouseHandlers();
  const skin = usePlayerSkin();

  // Hide chapters PlayerForm when toggling enabled without player rebuild
  if (!chapters || !chapters.enabled) return null;

  const { items } = chapters;

  return (
    <div
      {...mouseHandlers}
      className={cx(styles.wrapper, {
        [styles.visible]: isVisible,
      })}
      style={{
        height: `calc(100% - ${skin.approximateBarHeight}px - 26px)`,
      }}
    >
      <div className={styles.header}>
        <h3>{chapters.label}: </h3>
        <button className={styles.closeButton} onClick={handleClose}>
          <CrossIcon />
        </button>
      </div>

      <div className={styles.content}>
        {items.map((chapter, i) => (
          <ChapterLine
            key={i}
            title={chapter.title}
            timestampSecond={chapter.timestamp}
            seek={handleSeek}
          />
        ))}
      </div>
    </div>
  );
};
