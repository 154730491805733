import React, { Suspense } from 'react';
import to from 'await-to-js';
import {
  getEmbedVideoPublicRequest,
  isEmbedVideoPasswordProtected,
} from './api/embedVideo';
import {
  ICallbacks as IOriginalCallbacks,
  IPlaybackSettings,
  IPlayerContainerProps,
  ISourceConfiguration as IOriginalSourceConfiguration,
  PlayerContainer,
} from './components/Player/PlayerContainer';
import { Ratio, Loader } from '@voomly/ui/player-deps';
import { IError } from './components/ErrorStub/types';
import { AskToResumeOnVisitWatcher } from './watchers/AskToResumeOnVisitWatcher/AskToResumeOnVisitWatcher';
import { ITimelineItem } from './types/types';
import { VideoItemsContainerProvider } from './components/VideoItems/VideoItemsContainerContext';
import { IPlayerWithVideo, IRatio } from './types/player';
import { INodeRawPlayerConfigEssentials } from './types/funnels';
import { IPasswordProtectedPlayerVideo, IPlayerVideo } from './types/video';
import {
  IEmbedPlayerData,
  IPasswordProtectedEmbedPlayerData,
  IPasswordProtection,
} from './types/embedVideo';
import { autofixPlayerWithVideoConfig } from './autofixers/autofixPlayerWithVideoConfig';
import { DetachableStoreProviderForPlayer } from './contexts/DetachableStoreProviderForPlayer';
import { PlayerComponentsProvider } from './components/VideoItems/contextProviders/PlayerComponentsProvider';
import { PlayerMode } from './components/types/defaultPropTypes';
import { PlayerExternalAPI } from './api/external/PlayerExternalAPI';
import { PlayerExternalAPIContext } from './components/VideoItems/PlayerExternalAPIContext';

// Lazy components
const LazyUnlock = React.lazy(() => import('./components/Unlock/Unlock'));
const LazyErrorStub = React.lazy(() => import('./components/ErrorStub'));

// We do something with this ISourceConfiguration to get IOriginalSourceConfiguration,
// that's the whole point of this Class.
interface ISourceConfiguration {
  ratio: IRatio;
  embedVideoId: string | undefined;
  funnelNode?: INodeRawPlayerConfigEssentials;

  playerMode: PlayerMode;
  playbackSettings: IPlaybackSettings;
}

interface ICallbacks extends IOriginalCallbacks {
  onLoaded?: (playerConfig: IPlayerWithVideo, file: IPlayerVideo) => void;
  onLoadedPasswordForm?: (
    playerConfig: IPasswordProtection,
    file: IPasswordProtectedPlayerVideo
  ) => void;
}

interface IPasswordProtectedState {
  passwordProtectedEmbedPlayerData?: IPasswordProtectedEmbedPlayerData;
}

interface IAPIConfiguration {
  playerExternalAPI?: PlayerExternalAPI;
}

type IState = Partial<IOriginalSourceConfiguration> &
  IPasswordProtectedState & { error?: IError };

export class PlayerContainerFromEmbedVideoId extends React.PureComponent<
  ISourceConfiguration &
    ICallbacks &
    IAPIConfiguration &
    Omit<IPlayerContainerProps, keyof IOriginalSourceConfiguration>,
  IState
> {
  public state: IState = {};
  private askToResumeOnVisitWatcher?: AskToResumeOnVisitWatcher;

  private emptyTimelineItems: ITimelineItem[] = [];

  public componentDidMount() {
    this.askToResumeOnVisitWatcher = new AskToResumeOnVisitWatcher();
    this.askToResumeOnVisitWatcher.setEmbedVideoId(this.props.embedVideoId);
    this.fetchEmbedVideo();
  }

  public componentWillUnmount() {
    if (this.askToResumeOnVisitWatcher) {
      this.askToResumeOnVisitWatcher.destruct();
      this.askToResumeOnVisitWatcher = undefined;
    }
  }

  private async fetchEmbedVideo() {
    const { embedVideoId, onLoaded, onLoadedPasswordForm } = this.props;
    if (!embedVideoId) {
      this.setState({
        error: {
          message: 'Video is missing',
        },
      });
      return;
    }
    const [err, response] = await to(getEmbedVideoPublicRequest(embedVideoId));

    if (response) {
      const { data } = response;

      if (isEmbedVideoPasswordProtected(data)) {
        this.setState({
          passwordProtectedEmbedPlayerData: data,
        });
        onLoadedPasswordForm?.(data.player, data.video);
      } else {
        // TODO: boundToVideo refactoring, check if it works
        (data as IEmbedPlayerData).player.videoId = (
          data as IEmbedPlayerData
        ).video?.id;

        const embedVideo = {
          // TODO: WTF, why `data` type is unknown?
          ...(data as IEmbedPlayerData),
          player: autofixPlayerWithVideoConfig(
            (data as IEmbedPlayerData).player
          ) as IPlayerWithVideo,
        } as IEmbedPlayerData;

        this.setState({
          playerConfig: embedVideo.player,
          file: embedVideo.video,
        });

        if (this.askToResumeOnVisitWatcher) {
          this.askToResumeOnVisitWatcher.assignPlayer(
            embedVideo.player,
            embedVideo.video,
            this.props.playerMode
          );
        }
        onLoaded?.(embedVideo.player, embedVideo.video);
      }
    } else {
      this.setState({
        error: { message: err!.message },
      });
    }
  }

  private handleUnlockVideo = (
    player: IPlayerWithVideo,
    file: IPlayerVideo,
    password: string
  ) => {
    const { onLoaded } = this.props;

    this.setState({
      passwordProtectedEmbedPlayerData: undefined,
      playerConfig: player,
      file,
    });

    if (this.askToResumeOnVisitWatcher) {
      this.askToResumeOnVisitWatcher.assignPlayer(
        player,
        file,
        this.props.playerMode
      );
    }

    onLoaded?.(player, file);

    if (this.props.playerExternalAPI) {
      this.props.playerExternalAPI.fromEmitter.timelineUnlock.emit({
        password,
      });
    }
  };

  private renderPasswordForm() {
    const { passwordProtectedEmbedPlayerData } = this.state;
    const { embedVideoId, ratio } = this.props;
    if (!passwordProtectedEmbedPlayerData || !embedVideoId) return null;

    return (
      <Ratio ratio={ratio}>
        <Suspense fallback={this.renderLoader()}>
          <LazyUnlock
            embedVideoId={embedVideoId}
            lockedPlayer={passwordProtectedEmbedPlayerData.player}
            lockedFile={passwordProtectedEmbedPlayerData.video}
            onUnlock={this.handleUnlockVideo}
          />
        </Suspense>
      </Ratio>
    );
  }

  private handleOnTimeChange = (currentTime: number) => {
    this.askToResumeOnVisitWatcher?.updateLastTime(currentTime);
    this.props.onTimeChange?.(currentTime);
  };

  private renderLoader = () => {
    const { playerConfig } = this.state;

    return (
      <Loader
        color={
          playerConfig && playerConfig.skin.bgColorEnabled
            ? playerConfig.skin.bgColor
            : undefined
        }
      />
    );
  };

  render() {
    const { file, playerConfig, passwordProtectedEmbedPlayerData, error } =
      this.state;
    const { funnelNode, ratio, playerExternalAPI } = this.props;

    if (error) {
      return (
        <Ratio ratio={ratio}>
          <Suspense fallback={this.renderLoader}>
            <LazyErrorStub error={error} />
          </Suspense>
        </Ratio>
      );
    }

    if (file && playerConfig) {
      const passThroughProps: IPlayerContainerProps = {
        onTimeChange: this.handleOnTimeChange,
        onVideoFinished: this.props.onVideoFinished,
        onNavigateToOtherNodeRequest: this.props.onNavigateToOtherNodeRequest,
        playbackSettings: this.props.playbackSettings,
        playerMode: this.props.playerMode,
        isRememberDismissEnabled: this.props.isRememberDismissEnabled,
        playerConfig,
        file,
        playerExternalAPI,
        ratio,
      };

      let timelineItems = this.emptyTimelineItems;
      if (funnelNode) {
        timelineItems = funnelNode.timelineItems || this.emptyTimelineItems;
      } else if (playerConfig) {
        timelineItems = playerConfig.timelineItems || this.emptyTimelineItems;
      }

      return (
        <PlayerExternalAPIContext.Provider value={playerExternalAPI}>
          <VideoItemsContainerProvider>
            <PlayerComponentsProvider>
              <DetachableStoreProviderForPlayer
                timelineItems={timelineItems}
                thumbnailItems={playerConfig.thumbnailItems}
              >
                <PlayerContainer {...passThroughProps} />
              </DetachableStoreProviderForPlayer>
            </PlayerComponentsProvider>
          </VideoItemsContainerProvider>
        </PlayerExternalAPIContext.Provider>
      );
    }

    if (passwordProtectedEmbedPlayerData) {
      return this.renderPasswordForm();
    }

    return <Ratio ratio={ratio}>{this.renderLoader()}</Ratio>;
  }
}
