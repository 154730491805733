const THUMBNAIL_SIZES = {
  40: 40,
  80: 80,
  320: 320,
  640: 640,
  720: 720,
  1280: 1280,
  1920: 1920,
  3840: 3840,
} as const;

export type IThumbnailSize = keyof typeof THUMBNAIL_SIZES;

const extname = (url: string) => {
  const ext = url.split('.').pop();

  if (!ext) {
    return undefined;
  }

  return `.${ext}`;
};

function pickSize(sizes: number[], width: number) {
  let finalSize: number;

  sizes.every((currentSize, idx) => {
    const nextSize = sizes[idx + 1];

    if (!nextSize) {
      finalSize = currentSize;
      return false;
    }

    if (width === currentSize) {
      finalSize = currentSize;
      return false;
    }

    if (width > currentSize && width <= nextSize) {
      finalSize = nextSize;
      return false;
    }

    return true;
  });

  return finalSize!;
}

const pickThumbnailSize = (width: number, maxWidth: number) => {
  const sizes = Object.values(THUMBNAIL_SIZES).filter((v) => v <= maxWidth);
  return pickSize(sizes, width);
};

const getThumb = (
  url: string,
  width: IThumbnailSize | number,
  maxWidth: number
) => {
  const ext = extname(url);
  const thumbWidth = pickThumbnailSize(width, maxWidth);
  const isAvailableX2 = thumbWidth * 2 <= maxWidth;

  if (!ext) {
    console.error('Invalid url, no extension', url);
    return undefined;
  }

  if (url.indexOf('original') === -1) {
    console.error('Invalid url, no original', url);

    return undefined;
  }

  const url_1x = url.replace('original', `${thumbWidth}/thumb%401x`);
  const url_1x_webp = url_1x.replace(ext, '.webp');
  const url_2x = url.replace('original', `${thumbWidth}/thumb%402x`);
  const url_2x_webp = url_2x.replace(ext, '.webp');

  return {
    original: url,
    x1: url_1x,
    x2: isAvailableX2 ? url_2x : undefined,
    x1_webp: url_1x_webp,
    x2_webp: isAvailableX2 ? url_2x_webp : undefined,
  };
};

export const getThumbnail = (url: string, width: number, maxWidth: number) =>
  width && url ? getThumb(url, width, maxWidth) : undefined;

export const getTinyThumbWithOriginalExt = (url: string) => {
  return getThumb(url, 40, 40)?.x1 || url;
};

export const getThumb320WithOriginalExt = (url: string) => {
  return getThumb(url, 320, 320)?.x1 || url;
};
