import { IConfigIds } from '../sourceConfiguration/selectors';

export enum eInternalAnalyticEventType {
  play = 'play',
  pause = 'pause',
  playing = 'playing',
  // Difference with eAnalyticsEventType is that instead of `seek` - seekStarted and seekFinished present. That is needed for better tracking. Some new differ types maybe added in future
  seekStarted = 'seekStarted',
  seekFinished = 'seekFinished',
  finished = 'finished',
}

export type IInternalAnalyticEvent = {
  config: IConfigIds;
} & {
  name:
    | eInternalAnalyticEventType.play
    | eInternalAnalyticEventType.pause
    | eInternalAnalyticEventType.playing
    | eInternalAnalyticEventType.seekStarted
    | eInternalAnalyticEventType.seekFinished
    | eInternalAnalyticEventType.finished;
  second: number;
};
