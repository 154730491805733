export enum eAnalyticsEventType {
  play = 'play',
  pause = 'pause',
  playing = 'playing',
  seek = 'seek',
  pageView = 'pageview',
  finish = 'finish',
}

export interface IBaseAnalyticsEvent {
  executedAt: string;
  sequenceNumber: number;
}

export interface IAnalyticsEventPlayPauseFinish extends IBaseAnalyticsEvent {
  name:
    | eAnalyticsEventType.pause
    | eAnalyticsEventType.play
    | eAnalyticsEventType.playing
    | eAnalyticsEventType.finish;
  second: number;
}
export interface IAnalyticsEventSeek extends IBaseAnalyticsEvent {
  name: eAnalyticsEventType.seek;
  fromSecond: number;
  toSecond: number;
}

export interface IAnalyticsEventPageview {
  name: eAnalyticsEventType.pageView;
  videoId: string;
  playerId?: string | undefined;
  funnelId?: string | undefined;
  nodeId?: string | undefined;
  spotlightId?: string | undefined;
}

export type IAnalyticsSessionEvent =
  | IAnalyticsEventPlayPauseFinish
  | IAnalyticsEventSeek;

export type IAnalyticsEvent = IAnalyticsSessionEvent | IAnalyticsEventPageview;

export const isAnalyticsSessionEvent = (
  event: IAnalyticsEvent
): event is IAnalyticsSessionEvent => {
  return typeof (event as IAnalyticsSessionEvent).sequenceNumber === 'number';
};

export enum eOS {
  windows = 'Windows',
  linux = 'Linux',
  macos = 'macOS',
  ios = 'iOS',
  ipados = 'iPadOS',
  android = 'Android',
  other = 'Other',
}

export enum eDevice {
  desktop = 'Desktop',
  mobile = 'Mobile',
  tablet = 'Tablet',
  other = 'Other',
}

export enum eBrowser {
  googleChrome = 'Google Chrome',
  mozillaFirefox = 'Mozilla Firefox',
  opera = 'Opera',
  safari = 'Safari',
  internetExplorer = 'Internet Explorer',
  microsoftEdge = 'Microsoft Edge',
  ssr = 'ssr',
  other = 'Other',
}

export interface IAnalyticsUser {
  visitorId: string;
  firstVisit: boolean;
  os: eOS;
  device: eDevice;
  browser: eBrowser;
}
