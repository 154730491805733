import { useEffect, useMemo, useRef } from 'react';
import store from 'store';
import { useMount } from 'react-use';
import {
  changeCurrentSubtitlesName,
  updateSubtitlesVisibility,
} from '../../../store/subtitles/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  subtitlesIsVisibleSelector,
  currentSubtitlesNameSelector,
} from '../../../store/subtitles/selectors';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import {
  getFile,
  getFunnelId,
} from '../../../store/sourceConfiguration/selectors';

export const useSubtitlesLocalStorageKeys = () => {
  const funnelId = useSelector(getFunnelId);
  const file = useSelector(getFile);
  const storageId = funnelId ? funnelId : file?.id;
  const storageVisibilityKeyRef = useRef(
    `voomlySubtitlesIsVisible|${storageId}`
  );
  const storageSubtitlesSelectedKeyRef = useRef(
    `voomlySubtitlesSelectedId|${storageId}`
  );

  return {
    storageVisibilityKeyRef,
    storageSubtitlesSelectedKeyRef,
  };
};

export const useSubtitlesLocalStorage = ({
  player: {
    subtitles,
    skin: { subtitles: subtitlesSkin },
  },
}: Pick<IDefaultPropTypes, 'player' | 'file'>) => {
  const dispatch = useDispatch();
  const { storageSubtitlesSelectedKeyRef, storageVisibilityKeyRef } =
    useSubtitlesLocalStorageKeys();
  const subtitlesIsVisible = useSelector(subtitlesIsVisibleSelector);
  const currentSubtitlesName = useSelector(currentSubtitlesNameSelector);
  const enabledSubtitles = useMemo(
    () => subtitles?.items.filter((s) => s.isEnabled) || [],
    [subtitles?.items]
  );

  useMount(() => {
    const shouldUseDefaultSubtitles = subtitlesSkin.showByDefault;
    const defaultSubtitlesId = subtitles?.defaultSubtitlesId;
    const defaultSubtitlesName = enabledSubtitles.find(
      (s) =>
        s.subtitlesId === defaultSubtitlesId || s.name === defaultSubtitlesId
    )?.name;

    const storedSubtitlesName = store.get(
      storageSubtitlesSelectedKeyRef.current
    );

    if (!enabledSubtitles.length) {
      store.remove(storageSubtitlesSelectedKeyRef.current);
      return;
    }

    const firstSubtitlesName = enabledSubtitles[0].name;
    const hasStoredSubtitlesNameInEnabledSubtitles = !!enabledSubtitles.find(
      (s) => s.name === storedSubtitlesName
    );
    const hasDefaultSubtitlesNameInEnabledSubtitles = !!enabledSubtitles.find(
      (s) => s.name === defaultSubtitlesName
    );

    if (
      shouldUseDefaultSubtitles &&
      defaultSubtitlesName &&
      !hasStoredSubtitlesNameInEnabledSubtitles &&
      hasDefaultSubtitlesNameInEnabledSubtitles
    ) {
      store.set(storageSubtitlesSelectedKeyRef.current, defaultSubtitlesName);
      dispatch(changeCurrentSubtitlesName(defaultSubtitlesName));

      return;
    }

    if (storedSubtitlesName && hasStoredSubtitlesNameInEnabledSubtitles) {
      dispatch(changeCurrentSubtitlesName(storedSubtitlesName));

      return;
    }

    if (
      !hasDefaultSubtitlesNameInEnabledSubtitles ||
      !shouldUseDefaultSubtitles
    ) {
      store.set(storageSubtitlesSelectedKeyRef.current, firstSubtitlesName);
      dispatch(changeCurrentSubtitlesName(firstSubtitlesName));

      return;
    }
  });

  useMount(() => {
    if (
      subtitlesSkin.showByDefault &&
      !store.get(storageVisibilityKeyRef.current)
    ) {
      store.set(storageVisibilityKeyRef.current, 'true');
      dispatch(updateSubtitlesVisibility(true));
    } else if (store.get(storageVisibilityKeyRef.current) !== null) {
      const isSubtitlesVisible =
        store.get(storageVisibilityKeyRef.current) === 'true';
      dispatch(updateSubtitlesVisibility(isSubtitlesVisible));
    }
  });

  // NOTE: This useEffects should be called last
  useEffect(() => {
    store.set(storageVisibilityKeyRef.current, subtitlesIsVisible.toString());
  }, [storageVisibilityKeyRef, subtitlesIsVisible]);
  useEffect(() => {
    if (currentSubtitlesName) {
      store.set(storageSubtitlesSelectedKeyRef.current, currentSubtitlesName);
    }
  }, [currentSubtitlesName, storageSubtitlesSelectedKeyRef]);
};
