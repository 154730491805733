import { IPlayerRootReducerShape } from '../rootPlayerStore';
import { createSelector } from 'reselect';
import { shallowEqual } from 'react-redux';
import {
  getTimelineItems,
  getPlayerMode,
  getThumbnailItems,
  getNextSourceConfigurationTime,
} from '../sourceConfiguration/selectors';
import { getHasStarted, getCurrentTime } from '../videoState/selectors';
import { PlayerMode } from '../../components/types/defaultPropTypes';
import { hasActionOnEnter } from '../../types/guards';

const getDismissedNotRememberedItemIds = (store: IPlayerRootReducerShape) =>
  store.videoItemsOnScreen.dismissedItemsIds;

const getDismissedRememberedItemIds = (store: IPlayerRootReducerShape) =>
  store.videoItemsOnScreen.rememberedDismissedItemIds;

export const getDismissedItemIds = createSelector(
  getDismissedNotRememberedItemIds,
  getDismissedRememberedItemIds,
  (rmIds, notRmIds) => rmIds.concat(notRmIds)
);

export const getItemsOnScreen = createSelector(
  getDismissedItemIds,
  getTimelineItems,
  getThumbnailItems,
  getPlayerMode,
  getHasStarted,
  getCurrentTime,
  getNextSourceConfigurationTime,
  (
    dismissedItemsIds,
    timelineItems,
    thumbnailItems,
    playerMode,
    hasStarted,
    currentTime,
    nextCurrentTime
  ) => {
    if (playerMode === PlayerMode.THUMBNAIL_EDITOR || !hasStarted) {
      return thumbnailItems;
    }

    return timelineItems.filter((x) => {
      const startSecond = Math.round(x.startSecond * 10) / 10;
      const endSecond = Math.round(x.endSecond * 10) / 10;
      const roundTime = Math.round((nextCurrentTime ?? currentTime) * 10) / 10;

      const inFrame = startSecond <= roundTime && roundTime <= endSecond;
      if (!inFrame) {
        return false;
      }

      return !dismissedItemsIds.includes(x.id);
    });
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
      maxSize: 2,
    },
  }
);

const dimBackgroundItemsOnScreen = createSelector(
  getItemsOnScreen,
  getDismissedItemIds,
  (items, dismissedItemsIds) => {
    return items.filter(
      (item) =>
        !dismissedItemsIds.includes(item.id) &&
        hasActionOnEnter(item) &&
        item.actionsOnEnter.some((act) => act.type === 'dimBackground')
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
      maxSize: 2,
    },
  }
);

export const getIsBackgroundDimmed = createSelector(
  [dimBackgroundItemsOnScreen, getCurrentTime],
  (items, currentTime) => {
    return items.some(
      (item) => item.startSecond <= currentTime && currentTime <= item.endSecond
    );
  }
);
