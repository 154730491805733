import * as React from 'react';
import { IDetachableInnerProps } from '../detachableStoreHoc';
import { IVideoItemSharedProps } from '../sharedTypes';
import styled, { css } from 'styled-components';
import { chunk } from 'lodash-es';
import {
  IFontStyle,
  Flex,
  IVerticalAlign,
  pickVerticalAlign,
} from '@voomly/ui/player-deps';
import {
  ITimelineGrid,
  ITimelineItemClickDescriptor,
} from '../../../types/types';
import tinycolor from '@ctrl/tinycolor';

export const GridWrapper = styled.div<{
  backgroundColor: string;
  opacity: number;
  verticalAlign?: IVerticalAlign;
}>(
  ({ backgroundColor, opacity, verticalAlign }) => css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    background: ${tinycolor(backgroundColor)
      .setAlpha(opacity / 100)
      .toRgbString()};
    display: flex;
    flex-direction: column;
    justify-content: ${pickVerticalAlign(
      verticalAlign || 'vertical-align-center'
    )};
  `
);

interface IButtonProps {
  buttonsScale: number;
  buttonColor: string;
  buttonOpacity: number;
  buttonRounded: number;
  fontColor: string;
  fontSize: number;
  hasActionOnButton: boolean;
}
export const GridButton = styled.div.attrs<IButtonProps>(
  ({
    fontSize,
    buttonsScale,
    buttonOpacity,
    fontColor,
    buttonRounded,
    hasActionOnButton,
  }): {
    style: React.CSSProperties;
  } => {
    return {
      style: {
        whiteSpace: 'normal',
        width: '100%',
        height: '100%',
        fontSize: `${fontSize * buttonsScale}px`,
        color: fontColor,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        opacity: buttonOpacity / 100,
        borderRadius: buttonRounded * 0.15 * buttonsScale,
        padding: `${15 * buttonsScale}px`,
        cursor: hasActionOnButton ? 'pointer' : 'auto',
      },
    };
  }
)<IButtonProps>`
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ buttonColor }) => buttonColor};
  &:hover {
    background: ${({ buttonColor }) =>
      tinycolor(buttonColor).saturate(30).toHexString()};
  }
`;

export const GridButtonWrapper = styled.div<{ margin: number }>`
  flex: 1;
  margin: ${({ margin }) => `${margin}px`};
`;

interface IGridTitleProps {
  fontSize: number;
  fontStyle: IFontStyle[];
  color: string;
  buttonsScale: number;
}

export const GridTitle = styled.div.attrs<IGridTitleProps>(
  ({
    fontSize,
    fontStyle,
    color,
    buttonsScale,
  }): {
    style: React.CSSProperties;
  } => ({
    style: {
      fontWeight: fontStyle.includes('bold') ? 'bold' : undefined,
      fontStyle: fontStyle.includes('italic') ? 'italic' : undefined,
      fontSize: `${fontSize * buttonsScale}px`,
      textDecoration: `${fontStyle.includes('underline') ? 'underline' : ''} ${
        fontStyle.includes('line-through') ? 'line-through' : ''
      }`,
      lineHeight: `${fontSize * 1.1 * buttonsScale}px`,
      color,
    },
  })
)<IGridTitleProps>``;

export const GridAnimationDiv = styled.div<{
  animationState: string;
  buttonsScale: number;
  appearanceAnimation: ITimelineGrid['appearanceAnimation'];
}>(({ buttonsScale, animationState, appearanceAnimation }) => {
  const shouldBeVisible =
    animationState === 'entered' || animationState === 'exiting';

  return css`
    display: flex;
    flex-direction: column;

    padding: ${20 * buttonsScale}px 0;
    padding-bottom: 40px;

    align-items: center;
    justify-content: center;

    ${appearanceAnimation === 'slide-top-bottom' &&
    css`
      transition-property: transform;
      transition-duration: 500ms;
      transform: ${shouldBeVisible ? 'translate(0, 0)' : 'translate(0, -100%)'};
    `}
  `;
});

export interface IBaseGridItemProps
  extends IDetachableInnerProps<ITimelineGrid>,
    IVideoItemSharedProps {
  onButtonClick: (item: ITimelineItemClickDescriptor) => void;
}

interface IPropsWithConfig extends IBaseGridItemProps {
  titleText: React.ReactNode;
  getLabelForButton: (index: number) => React.ReactNode;
}

export class BaseGridItem extends React.PureComponent<IPropsWithConfig> {
  private onButtonClick = (index: number) => {
    const {
      item,
      item: { buttons },
      onButtonClick,
    } = this.props;

    const clickedButton = buttons[index];
    onButtonClick({
      actionOnClick: clickedButton.actionOnClick,
      id: item.id + '|' + clickedButton.id,
      type: 'grid',
    });
  };

  public render() {
    const {
      item,
      buttonsScale,
      getLabelForButton,
      titleText,
      animationState,
      onWrapperMouseDown,
    } = this.props;

    return (
      <GridWrapper
        onMouseDown={onWrapperMouseDown}
        opacity={item.backgroundOpacity}
        backgroundColor={item.backgroundColor}
      >
        <GridAnimationDiv
          animationState={animationState}
          buttonsScale={buttonsScale}
          appearanceAnimation={item.appearanceAnimation}
        >
          <GridTitle
            color={item.fontColor}
            fontSize={item.fontSize}
            fontStyle={item.fontStyle}
            buttonsScale={buttonsScale}
          >
            {titleText}
          </GridTitle>

          <Flex
            width="100%"
            height="100%"
            margin={`${20 * buttonsScale}px 0 0`}
            padding={`${(item.paddingButtonContainer / 4) * buttonsScale}px`}
            flexShrink={0}
          >
            {chunk(
              item.buttons.slice(0, item.buttonsCount),
              item.buttonsInRowCount
            ).map((chunk, chunkIndex) => (
              <Flex key={chunkIndex} container={true} justifyContent="center">
                {chunk.map((button, index) => {
                  const buttonIndex =
                    index + chunkIndex * item.buttonsInRowCount;
                  return (
                    <GridButtonWrapper
                      key={index}
                      margin={(item.paddingBetweenButtons / 10) * buttonsScale}
                    >
                      <GridButton
                        buttonsScale={buttonsScale}
                        buttonColor={item.buttonColor}
                        fontColor={item.buttonTextColor}
                        buttonOpacity={item.buttonOpacity}
                        buttonRounded={item.buttonRounded}
                        fontSize={item.buttonFontSize}
                        hasActionOnButton={
                          button.actionOnClick.type !== 'doNothing'
                        }
                        onClick={() => this.onButtonClick(buttonIndex)}
                      >
                        {getLabelForButton(buttonIndex)}
                      </GridButton>
                    </GridButtonWrapper>
                  );
                })}
              </Flex>
            ))}
          </Flex>
        </GridAnimationDiv>
      </GridWrapper>
    );
  }
}
