import styles from './progressBar.module.sass';
import * as React from 'react';
import cx from 'classnames';
import { secondsToHms } from '@voomly/utils';
import Slider from '../../Slider/Slider';
import SliderHandle from '../../Slider/SliderHandle/SliderHandle';
import { getStyles } from '../skins/getStyles';
import { ReactComponent as FunnelBlockedIcon } from '../../../svg/funnel-blocked.svg';
import TotalTime from './TotalTime/TotalTime';
import CurrentTime from './CurrentTime/CurrentTime';
import { IDefaultPropTypes, PlayerMode } from '../../types/defaultPropTypes';
import { IPlayerRootReducerShape } from '../../../store/rootPlayerStore';
import { connect, ConnectedProps } from 'react-redux';
import TipWithPreview from './TipWithPreview';
import { get } from 'lodash-es';
import { IPlayerTemplate } from '../../../types/player';
import {
  requestSeek,
  requestSeekAndBuffer,
  requestPlay,
  requestPause,
} from '../../../store/videoState/actions';
import { getIsVideoChanging } from '../../../store/sourceConfiguration/selectors';
import { getHasStarted } from '../../../store/videoState/selectors';
import { getFirstBlockingSecond } from '../../../store/selectors';
import { IPlayerSkin } from '../skins/types';
import { usePlayerSkin } from '../skins/PlayerSkinContext';

interface IState {
  isDragging: boolean;
  tipTime: string;
  tipPosition: number;
  showTipTime: boolean;
  wasPaused: boolean;
}
class ProgressBar extends React.PureComponent<
  IDefaultPropTypes & { skin: IPlayerSkin } & ConnectedProps<typeof hoc>,
  IState
> {
  lastDragPosition: number | undefined;

  state: IState = {
    isDragging: false,
    tipTime: '',
    tipPosition: 0,
    showTipTime: false,
    wasPaused: false,
  };

  private handleMouseUp = (position: number) => {
    this.handleDraggingChange(false);
    if (this.props.isChangingVideo || !this.props.hasStarted) {
      return;
    }

    const newCurrentTime = this.getNewTimeMinus1(
      this.lastDragPosition ?? position
    );
    !this.state.wasPaused &&
      this.props.requestPlay({
        time: newCurrentTime,
        isSilent: true,
      });
    this.setState({ showTipTime: false });

    this.lastDragPosition = undefined;
  };

  private handleMouseDown = () => {
    this.handleDraggingChange(true);
    if (!this.props.hasStarted) {
      return;
    }

    !this.props.paused && this.props.requestPause({ isSilent: true });
    this.setState({
      wasPaused: this.props.paused,
    });
  };

  private handleMouseMove = (position: number) => {
    this.props.requestSeek({
      time: this.getNewTimeMinus1(position),
      initiatedBy: 'click',
      type: 'toTime',
    });
    this.lastDragPosition = position;
    this.setState({ showTipTime: true, tipPosition: position });
  };

  private handleMouseMoveOnTrack = (position: number) => {
    this.setState({ showTipTime: true, tipPosition: position });
  };

  private handleMouseLeaveOnTrack = () => {
    this.setState({ showTipTime: false });
  };

  private handleDraggingChange = (isDragging: boolean) => {
    this.setState({ isDragging });
  };

  private getProgress = () => {
    const {
      currentTime,
      file: {
        metadata: { duration },
      },
    } = this.props;

    const { isDragging, tipPosition } = this.state;

    let time = currentTime;
    if (isDragging) {
      time = this.getNewTimeMinus1(tipPosition);
    }

    const progress = duration && time / duration;
    return progress >= 1 ? 1 : progress;
  };

  private getLoadedProgress = () => {
    const {
      percentsLoaded,
      file: {
        metadata: { duration },
      },
    } = this.props;

    return Math.min(duration !== 0 ? percentsLoaded : 0, 1);
  };

  private getNewTimeMinus1 = (position: number) => {
    const {
      file: {
        metadata: { duration },
      },
      firstTimestampBlock,
    } = this.props;
    // If you try to fast-forward till the very end, player goes crazy
    // +1 due to roundings (real length could be 1:59.7 rounded to 2:00),
    const result = position * duration;

    if (typeof firstTimestampBlock !== 'undefined') {
      return Math.min(result, Math.max(firstTimestampBlock - 0.5, 0));
    }

    return result;
  };

  private renderBar = () => {
    const {
      player,
      file: {
        metadata: { duration },
        thumbnailGrids,
      },
      firstTimestampBlock: realFirstTimestampBlock,
      skin,
    } = this.props;
    const { showTipTime, tipPosition } = this.state;
    const progress = this.getProgress();

    const patchedFirstTimeBlock = realFirstTimestampBlock
      ? this.getNewTimeMinus1(realFirstTimestampBlock)
      : undefined;
    const firstBlockPercents =
      typeof patchedFirstTimeBlock !== 'undefined'
        ? (patchedFirstTimeBlock / duration) * 100
        : undefined;

    const cssWidthPercents = `${Math.min(
      progress * 100,
      firstBlockPercents || 100
    ).toFixed(2)}%`;

    const loadedProgress = this.getLoadedProgress();
    const cssLoadedWidthPercents = `${(loadedProgress * 100).toFixed(2)}%`;
    const { classes, handleValue } = skin;
    const showTime = get<IPlayerTemplate, 'controls', 'showTime'>(player, [
      'controls',
      'showTime',
    ]);

    return (
      <div className={cx(styles.barWrap, classes.progressBar__barWrap)}>
        <TipWithPreview
          thumbnailGrids={thumbnailGrids}
          duration={duration}
          position={tipPosition}
          isVisible={showTipTime}
          player={player}
        />
        {typeof patchedFirstTimeBlock !== 'undefined' && (
          <div
            className={cx(styles.blockedAreaIconWrapper)}
            style={{
              left: `${firstBlockPercents}%`,
              width: `${
                ((duration - patchedFirstTimeBlock) / duration) * 100
              }%`,
            }}
          >
            <div className={styles.funnelAreaIcon}>
              {/* <FunnelNonBlockedIcon /> */}
              <FunnelBlockedIcon />
            </div>
          </div>
        )}

        <div className={cx(styles.track, classes.progressBar__track)}>
          {typeof patchedFirstTimeBlock !== 'undefined' && (
            <div
              className={cx(styles.blockedArea)}
              style={{
                left: `${firstBlockPercents}%`,
                width: `${
                  ((duration - patchedFirstTimeBlock) / duration) * 100
                }%`,
              }}
            />
          )}

          <div
            className={cx(
              styles.bar,
              classes.progressBar__bar,
              classes.progressBar__secondsLoaded,
              styles.secondsLoaded
            )}
            style={{
              ...getStyles('progressBar__bar', skin, player),
              width: cssLoadedWidthPercents,
            }}
          />

          <div
            className={cx(styles.bar, classes.progressBar__bar)}
            style={{
              ...getStyles('progressBar__bar', skin, player),
              width: cssWidthPercents,
            }}
          />
        </div>

        <SliderHandle
          className={cx(styles.handle, classes.progressBar__handle_root)}
          classNamePoiner={classes.progressBar__handle_pointer}
          showPoiner={skin.handles}
          classNameValue={classes.progressBar__handle_value}
          handleStyle={getStyles('progressBar__handle', skin, player)}
          position={cssWidthPercents}
          value={
            showTime &&
            handleValue &&
            secondsToHms(progress * duration, duration)
          }
        />
      </div>
    );
  };

  render() {
    const {
      file: {
        metadata: { duration },
      },
      player,
      currentTime,
      isVideoReady,
      playerMode,
      skin,
    } = this.props;
    const { isDragging } = this.state;
    const { classes, timeFormat } = skin;
    const isTimeTogether = timeFormat === 'together';

    return (
      <div
        className={cx(styles.root, classes.progressBar__root, {
          [styles.dragging]: isDragging,
          [styles.disabled]: !isVideoReady,
        })}
        style={getStyles('progressBar__root', skin, player)}
      >
        {playerMode !== PlayerMode.MINI_PLAYER && (
          <CurrentTime
            classes={classes}
            skin={skin}
            config={player}
            currentTime={currentTime}
          />
        )}
        {isTimeTogether && (
          <TotalTime
            duration={duration}
            classes={classes}
            skin={skin}
            config={player}
          />
        )}

        <Slider
          className={cx(styles.slider, classes.progressBar__slider)}
          style={getStyles('progressBar__slider', skin, player)}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
          onMouseDown={this.handleMouseDown}
          onLocalMouseMove={this.handleMouseMoveOnTrack}
          onLocalMouseLeave={this.handleMouseLeaveOnTrack}
        >
          {this.renderBar()}
        </Slider>
        {!isTimeTogether && (
          <TotalTime
            duration={duration}
            classes={classes}
            skin={skin}
            config={player}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IPlayerRootReducerShape) => {
  return {
    isVideoReady: state.videoState.isVideoReady,
    currentTime: state.videoState.currentTime,
    paused: state.videoState.paused,
    percentsLoaded: state.videoState.percentsLoaded,
    firstTimestampBlock: getFirstBlockingSecond(state),
    isChangingVideo: getIsVideoChanging(state),
    hasStarted: getHasStarted(state),
  };
};

const mapDispatchToProps = {
  requestSeek,
  requestSeekAndBuffer,
  requestPlay,
  requestPause,
};

const hoc = connect(mapStateToProps, mapDispatchToProps);
const ProgressBarWithHoc = hoc(ProgressBar);

// TODO: Rewrite ProgressBarWithHoc into functional component and remove this wrapper
const ProgressBarWithSkin = (props: IDefaultPropTypes) => {
  const skin = usePlayerSkin();

  return <ProgressBarWithHoc {...props} skin={skin} />;
};

export default ProgressBarWithSkin;
