import * as React from 'react';
import { IDetachableInnerProps } from '../detachableStoreHoc';
import { IVideoItemSharedProps } from '../sharedTypes';
import {
  IFontStyle,
  IVerticalAlign,
  pickAlign,
  pickAlignFlex,
} from '@voomly/ui/player-deps';
import {
  ITimelineGrid,
  ITimelineItemClickDescriptor,
  ITimelineSurvey,
} from '../../../types/types';
import { memo, useCallback } from 'react';
import { GridWrapper } from '../Grid/Base';
import styled, { css } from 'styled-components';
import tinycolor from '@ctrl/tinycolor';
import { IFontHorizontalAlign } from '@voomly/ui/player-deps';

export const SurveyButtonsWrapper = styled.div.attrs<{
  padding: number;
  buttonsScale: number;
  buttonsTitleIndent: number;
}>(
  ({
    padding,
    buttonsScale,
    buttonsTitleIndent,
  }): {
    style: React.CSSProperties;
  } => {
    return {
      style: {
        padding: `${buttonsTitleIndent * buttonsScale}px ${padding / 2}% 0`,
      },
    };
  }
)<{ padding: number; buttonsScale: number; buttonsTitleIndent: number }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
`;

export const SurveyButtonWrapper = styled.div.attrs<{
  margin: number;
  buttonsScale: number;
}>(
  ({
    margin,
    buttonsScale,
  }): {
    style: React.CSSProperties;
  } => {
    return {
      style: {
        margin: `${margin * buttonsScale}px 0`,
      },
    };
  }
)<{ margin: number; buttonsScale: number }>`
  flex: 1;
`;

type ISurveyOptionMarkerProps = {
  fontSize: number;
  fontColor: string;
  color: string;
  buttonsScale: number;
  horizontalPosition: 'left' | 'right';
  indent: number;
};

export const SurveyOptionMarker = styled.div.attrs<ISurveyOptionMarkerProps>(
  ({
    fontSize,
    fontColor,
    color,
    buttonsScale,
    horizontalPosition,
    indent,
  }): {
    style: React.CSSProperties;
  } => {
    return {
      style: {
        fontSize: `${fontSize * buttonsScale}px`,
        color: fontColor,
        background: color,
        width: `${(5 + fontSize) * buttonsScale}px`,
        height: `${(5 + fontSize) * buttonsScale}px`,
        pointerEvents: 'none',
        [horizontalPosition]: `${indent * buttonsScale}px`,
      },
    };
  }
)<ISurveyOptionMarkerProps>`
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  border-radius: 50%;
`;

interface ISurveyTitleProps {
  fontSize: number;
  fontStyle: IFontStyle[];
  color: string;
  buttonsScale: number;
  textIndent: number;
}

export const SurveyTitle = styled.div.attrs<ISurveyTitleProps>(
  ({
    fontSize,
    fontStyle,
    color,
    buttonsScale,
    textIndent,
  }): {
    style: React.CSSProperties;
  } => {
    const textAlign = pickAlign(fontStyle);
    return {
      style: {
        fontWeight: fontStyle.includes('bold') ? 'bold' : undefined,
        fontStyle: fontStyle.includes('italic') ? 'italic' : undefined,
        fontSize: `${fontSize * buttonsScale}px`,
        textDecoration: `${
          fontStyle.includes('underline') ? 'underline' : ''
        } ${fontStyle.includes('line-through') ? 'line-through' : ''}`,
        justifyContent: pickAlignFlex(fontStyle),
        lineHeight: `${fontSize * 1.1 * buttonsScale}px`,
        color,
        ...(textAlign === 'left'
          ? { paddingLeft: `${(textIndent * buttonsScale) / 2}%` }
          : {}),
        ...(textAlign === 'right'
          ? { paddingRight: `${(textIndent * buttonsScale) / 2}%` }
          : {}),
      },
    };
  }
)<ISurveyTitleProps>`
  width: 100%;
  display: flex;
`;

interface IButtonProps {
  buttonsScale: number;
  buttonVerticalPadding: number;
  buttonColor: string;
  buttonOpacity: number;
  buttonRounded: number;
  fontColor: string;
  fontSize: number;
  horizontalAlign: IFontHorizontalAlign[];
  hasActionOnButton: boolean;
  textIndent: number;
  borderColor: string;
  borderSize: number;
}
export const SurveyButton = styled.div.attrs<IButtonProps>(
  ({
    fontSize,
    buttonsScale,
    buttonOpacity,
    fontColor,
    buttonRounded,
    hasActionOnButton,
    horizontalAlign,
    textIndent,
    borderColor,
    borderSize,
    buttonVerticalPadding,
  }): {
    style: React.CSSProperties;
  } => {
    const textAlign = pickAlign(horizontalAlign);

    return {
      style: {
        fontSize: `${fontSize * buttonsScale}px`,
        color: fontColor,
        justifyContent: pickAlignFlex(horizontalAlign),
        alignItems: 'center',
        opacity: buttonOpacity / 100,
        borderRadius: `${
          ((buttonVerticalPadding + fontSize) * buttonsScale * buttonRounded) /
          100
        }px`,
        paddingTop: `${buttonVerticalPadding * buttonsScale}px`,
        paddingBottom: `${buttonVerticalPadding * buttonsScale}px`,
        cursor: hasActionOnButton ? 'pointer' : 'auto',
        ...(textAlign === 'left' ? { paddingLeft: `${textIndent / 2}%` } : {}),
        ...(textAlign === 'right'
          ? { paddingRight: `${textIndent / 2}%` }
          : {}),
        borderColor,
        borderWidth: `${borderSize * buttonsScale}px`,
      },
    };
  }
)<IButtonProps>`
  overflow: hidden;
  display: flex;
  width: 100%;
  white-space: normal;
  align-items: center;
  position: relative;
  background: ${({ buttonColor }) => buttonColor};
  border-style: solid;
  &:hover {
    background: ${({ buttonColor }) =>
      tinycolor(buttonColor).saturate(30).toHexString()};
  }
`;

export const SurveyAnimationDiv = styled.div<{
  animationState: string;
  buttonsScale: number;
  appearanceAnimation: ITimelineGrid['appearanceAnimation'];
  verticalAlign: IVerticalAlign;
  verticalIndent: number;
}>(
  ({
    buttonsScale,
    animationState,
    appearanceAnimation,
    verticalAlign,
    verticalIndent,
  }) => {
    const shouldBeVisible =
      animationState === 'entered' || animationState === 'exiting';

    return css`
      display: flex;
      flex-direction: column;

      ${verticalAlign === 'vertical-align-top' &&
      css`
        padding-top: ${verticalIndent}%;
        padding-bottom: 40px;
      `}

      ${verticalAlign === 'vertical-align-bottom' &&
      css`
        padding-top: ${20 * buttonsScale}px;
        padding-bottom: calc(${verticalIndent}% + 40px);
      `}

    align-items: center;
      justify-content: center;

      ${appearanceAnimation === 'slide-top-bottom' &&
      css`
        transition-property: transform;
        transition-duration: 500ms;
        transform: ${shouldBeVisible
          ? 'translate(0, 0)'
          : 'translate(0, -100%)'};
      `}
    `;
  }
);

export interface IBaseSurveyItemProps
  extends IDetachableInnerProps<ITimelineSurvey>,
    IVideoItemSharedProps {
  onButtonClick: (item: ITimelineItemClickDescriptor) => void;
}

interface IPropsWithConfig extends IBaseSurveyItemProps {
  titleText: React.ReactNode;
  getLabelForButton: (index: number) => React.ReactNode;
}

export const SurveyItemComponent = ({
  item,
  item: { buttons },
  buttonsScale,
  getLabelForButton,
  titleText,
  animationState,
  onWrapperMouseDown,
  onButtonClick,
}: IPropsWithConfig) => {
  const handleButtonClick = useCallback(
    (index: number) => {
      const clickedButton = buttons[index];
      onButtonClick({
        actionOnClick: clickedButton.actionOnClick,
        id: item.id + '|' + clickedButton.id,
        type: 'survey',
      });
    },
    [onButtonClick, item.id, buttons]
  );

  return (
    <GridWrapper
      onMouseDown={onWrapperMouseDown}
      opacity={item.backgroundOpacity}
      backgroundColor={item.backgroundColor}
      verticalAlign={item.verticalAlign}
    >
      <SurveyAnimationDiv
        animationState={animationState}
        buttonsScale={buttonsScale}
        appearanceAnimation={item.appearanceAnimation}
        verticalIndent={item.verticalIndent}
        verticalAlign={item.verticalAlign}
      >
        {item.isTitleEnabled && (
          <SurveyTitle
            color={item.fontColor}
            fontSize={item.fontSize}
            fontStyle={item.fontStyle}
            buttonsScale={buttonsScale}
            textIndent={item.titleIndent}
          >
            {titleText}
          </SurveyTitle>
        )}

        <SurveyButtonsWrapper
          padding={item.paddingButtonContainer}
          buttonsScale={buttonsScale}
          buttonsTitleIndent={item.buttonsTitleIndent}
        >
          {item.buttons.map((button, idx) => {
            return (
              <SurveyButtonWrapper
                key={idx}
                margin={item.paddingBetweenButtons}
                buttonsScale={buttonsScale}
              >
                <SurveyButton
                  buttonsScale={buttonsScale}
                  buttonVerticalPadding={item.buttonVerticalPadding}
                  buttonColor={item.buttonColor}
                  fontColor={item.buttonTextColor}
                  buttonOpacity={item.buttonOpacity}
                  buttonRounded={item.buttonRounded}
                  fontSize={item.buttonFontSize}
                  horizontalAlign={item.buttonFontHorizontalAlign}
                  textIndent={item.buttonTextIndent}
                  borderColor={item.buttonBorderColor}
                  borderSize={item.buttonBorderSize}
                  hasActionOnButton={button.actionOnClick.type !== 'doNothing'}
                  onClick={() => handleButtonClick(idx)}
                >
                  {item.isMarkersEnabled && (
                    <SurveyOptionMarker
                      fontSize={item.markerFontSize}
                      fontColor={item.markerFontColor}
                      color={item.markerColor}
                      buttonsScale={buttonsScale}
                      horizontalPosition={item.markerHorizontalPosition}
                      indent={item.markerIndent}
                    >
                      {item.markerType === 'number'
                        ? idx + 1
                        : String.fromCharCode(65 + idx)}
                    </SurveyOptionMarker>
                  )}
                  {getLabelForButton(idx)}
                </SurveyButton>
              </SurveyButtonWrapper>
            );
          })}
        </SurveyButtonsWrapper>
      </SurveyAnimationDiv>
    </GridWrapper>
  );
};

export const BaseSurveyItem = memo(SurveyItemComponent);
