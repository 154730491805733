export class PlayerExternalAPIConnectedChecker {
  constructor(
    private element: HTMLDivElement,
    private onDisconnected?: () => void
  ) {}

  public checkConnected = () => {
    if (this.element.isConnected) {
      return true;
    }

    console.debug(
      'Checked that Voomly player API node was not connected:',
      this.element
    );

    this.onDisconnected?.();
    return false;
  };
}
