import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAlternativeDurationLine,
  getAlternativeDurationLinePercents,
} from '../../../store/miscScreenObjects/selectors';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import styles from './styles.module.sass';

interface ILineProps {
  buttonsScale: number;
  percentsFill: number;
  color: string;
}

const Line = ({ color, percentsFill, buttonsScale }: ILineProps) => (
  <div
    className={styles.line}
    style={{
      width: `${percentsFill}%`,
      backgroundColor: color,
      height: `${5 * buttonsScale}px`,
    }}
  />
);

export const AlternativeDurationLine = (props: IDefaultPropTypes) => {
  const progress = useSelector(getAlternativeDurationLinePercents);
  const alternativeDurationLine = useSelector(getAlternativeDurationLine);

  if (!alternativeDurationLine) return null;

  return (
    <>
      <Line
        buttonsScale={props.buttonsScale}
        color="#728898"
        percentsFill={100}
      />
      <Line
        buttonsScale={props.buttonsScale}
        color="#fef4dd"
        percentsFill={progress ?? 0}
      />
    </>
  );
};
