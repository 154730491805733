import { IFontStyle } from '../LegacyFontStylePicker/utils';

export type IFontHorizontalAlign =
  | 'align-left'
  | 'align-center'
  | 'align-right';

type IAlign = 'right' | 'center' | 'left';
type IFlexAlign = 'flex-end' | 'center' | 'flex-start';

export const alignMap: Record<IFontHorizontalAlign, IAlign> = {
  'align-right': 'right',
  'align-center': 'center',
  'align-left': 'left',
};

export const alignFlexMap: Record<IFontHorizontalAlign, IFlexAlign> = {
  'align-right': 'flex-end',
  'align-center': 'center',
  'align-left': 'flex-start',
};

export const pickAlign = (fontStyles: IFontStyle[]) => {
  const fontStyle = fontStyles.find(
    (style) => !!alignMap[style as IFontHorizontalAlign]
  );
  return fontStyle ? alignMap[fontStyle as IFontHorizontalAlign] : 'left';
};

export const pickAlignFlex = (fontStyles: IFontStyle[]) => {
  const fontStyle = fontStyles.find(
    (style) => !!alignFlexMap[style as IFontHorizontalAlign]
  );
  return fontStyle
    ? alignFlexMap[fontStyle as IFontHorizontalAlign]
    : 'flex-start';
};
