// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_selectQualityWrap__1m8dO{position:relative}.styles_selectQualityWrap__1m8dO:last-child .styles_selectQualityDropdown__5uJuq>div{left:0}.styles_selectQualityWrap__1m8dO:last-child .styles_selectQualityDropdown__5uJuq .popoverTip{left:calc(100% - 10px)}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/SelectQuality/styles.module.sass"],"names":[],"mappings":"AAAA,iCACE,iBAAA,CAGI,qFACE,MAAA,CACD,6FACC,sBAAA","sourcesContent":[".selectQualityWrap\n  position: relative\n  &:last-child\n    .selectQualityDropdown\n      > div\n        left: 0\n      \\:global(.popoverTip)\n        left: calc(100% - 10px)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectQualityWrap": "styles_selectQualityWrap__1m8dO",
	"selectQualityDropdown": "styles_selectQualityDropdown__5uJuq"
};
export default ___CSS_LOADER_EXPORT___;
