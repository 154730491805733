import * as React from 'react';
import { BaseItemButton, IBaseButtonItemProps } from './Base';
import { appearAnimationHoc } from '../playerHocs/appearAnimationHoc';
import positionHoc from '../playerHocs/positionHoc';
import highlightItemHoc from '../playerHocs/highlightItemHoc';

const Component = ({ onClick, ...props }: IBaseButtonItemProps) => (
  <BaseItemButton {...props} onClick={() => onClick(props.item)} />
);

export const PlayerButtonItem = appearAnimationHoc()(
  positionHoc({ isInteractable: true })(highlightItemHoc()(Component))
);
