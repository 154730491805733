import * as React from 'react';
import cx from 'classnames';
import { secondsToHms } from '@voomly/utils';
import { IPlayerTemplate } from '../../../../types/player';
import { getStyles } from '../../skins/getStyles';
import styles from './totalTime.module.sass';

interface IClasses {
  progressBar__totalTime?: string;
}

interface ITotalTime {
  duration: number;
  config: IPlayerTemplate;
  classes: IClasses;
  skin: any;
}

class TotalTime extends React.Component<ITotalTime> {
  static defaultProps = {
    classes: {},
  };

  render() {
    const { duration, skin, config, classes } = this.props;

    if (!config.controls.showTime) {
      return null;
    }

    return (
      <div
        className={cx(styles.root, classes.progressBar__totalTime)}
        style={getStyles('progressBar__totalTime', skin, config)}
      >
        {secondsToHms(duration)}
      </div>
    );
  }
}

export default TotalTime;
