import { Task } from 'redux-saga';
import {
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { videoPlayStarted } from '../videoState/actions';
import { getIsVideoPaused } from '../videoState/selectors';
import { hideControls, registerMouseChannel, showControls } from './actions';
import { getAreControlsVisiblePure } from './selectors';
import { PlayerMode } from '../../components/types/defaultPropTypes';

// Redux-saga channel used here to reduce amount of event dispatches
// to the redux store (otherwise we would need to dispatch all mouse move/leave/enter events,
// that may impact performance significantly)

export type IMouseEvent = (
  | {
      type: 'move';
    }
  | { type: 'leave' }
  | { type: 'enter' }
) & {
  // Is needed to track hover(like don't hide controls when mouse is on playbar)
  initiatedBy?: 'controlBar';
};

function* hideSaga(delayMs: number) {
  yield delay(delayMs);

  if (!getIsVideoPaused(yield select())) {
    yield put(hideControls());
  }
}

function* handleRegisterMouseChannel(
  action: ReturnType<typeof registerMouseChannel>
) {
  if (!action.payload.ch) {
    return;
  }

  let currentTask: Task | undefined = undefined;
  let isControlHover = false;

  // Don't show controls on hover right away for mini player
  const isMiniPlayer = action.payload.playerMode === PlayerMode.MINI_PLAYER;
  let shouldShowControls = !isMiniPlayer;

  while (true) {
    const [event]: [
      IMouseEvent | undefined,
      ReturnType<typeof videoPlayStarted> | undefined
    ] = yield race([take(action.payload.ch), take(videoPlayStarted)]);

    if (shouldShowControls && !getAreControlsVisiblePure(yield select())) {
      yield put(showControls());
    }

    // Show controls only on mouse next event for mini player
    !shouldShowControls &&
      // eslint-disable-next-line no-loop-func
      setTimeout(() => {
        shouldShowControls = true;
      }, 200);

    if (event?.initiatedBy === 'controlBar') {
      if (event.type === 'enter') {
        isControlHover = true;
      }

      if (event.type === 'leave') {
        isControlHover = false;
      }
    }

    if (currentTask) {
      currentTask.cancel();
    }
    if (isControlHover) {
      continue;
    }

    currentTask = yield fork(
      hideSaga,
      event && (event.type === 'enter' || event.type === 'move')
        ? !isMiniPlayer
          ? 2500
          : 500
        : 150
    );
  }
}

export function* controlVisibilitySaga() {
  yield takeLatest(registerMouseChannel, handleRegisterMouseChannel);
}
