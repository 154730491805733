import * as React from 'react';
import styled from 'styled-components';
import { IDefaultPropTypes } from '../types/defaultPropTypes';
import { connect, ConnectedProps } from 'react-redux';
import { IPlayerRootReducerShape } from '../../store/rootPlayerStore';
import { getIsBackgroundDimmed } from '../../store/videoItems/selectors';

const DimmedBackgroundDiv = styled.div<{ isDimmed: boolean }>`
  transition-property: opacity;
  transition-duration: 0.2s;
  opacity: ${(props) => (props.isDimmed ? 0.5 : 0)};
  pointer-events: none;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
`;

class DimmedBackground extends React.PureComponent<
  IDefaultPropTypes & ConnectedProps<typeof hoc>
> {
  public render() {
    const { isBackgroundDimmed } = this.props;

    return <DimmedBackgroundDiv isDimmed={isBackgroundDimmed} />;
  }
}

const mapStateToProps = (state: IPlayerRootReducerShape) => ({
  isBackgroundDimmed: getIsBackgroundDimmed(state),
});

const hoc = connect(mapStateToProps);

export default hoc(DimmedBackground);
