// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__3Ha97{flex-shrink:0}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/TogglePlayButton/styles.module.sass"],"names":[],"mappings":"AAAA,oBACE,aAAA","sourcesContent":[".root\n  flex-shrink: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__3Ha97"
};
export default ___CSS_LOADER_EXPORT___;
