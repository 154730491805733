import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  ITimelineDraggableItem,
  ITimelineItemBase,
  ITimelineResizableItem,
} from '../../../types/types';
import { IDetachableInnerProps as IDetachableStoreInnerProps } from '../detachableStoreHoc';

// Props you want the resulting component to take (besides the props of the wrapped component)
interface ExternalProps {
  buttonsScale: number;
}

// Props the HOC adds to the wrapped component
export interface InjectedProps {}

// Props that are required for the wrapped component to accept
interface IOriginalProps extends Omit<IDetachableStoreInnerProps<any>, 'item'> {
  item: ITimelineDraggableItem & ITimelineResizableItem & ITimelineItemBase;
}
// Options for the HOC factory that are not dependent on props values
interface IHOCOptions {
  isInteractable: boolean;
}

type IDraggableDivProps = ITimelineDraggableItem & {
  zIndex: number;
  buttonsScale: number;
  isInteractable: boolean;
};

const DraggableDiv = styled.div.attrs<IDraggableDivProps>((props) => ({
  style: {
    left: `${props.x * props.buttonsScale}px`,
    top: `${props.y * props.buttonsScale}px`,
  },
}))<IDraggableDivProps>`
  position: absolute;
  z-index: ${(props) => props.zIndex};
  ${({ isInteractable }) =>
    !isInteractable &&
    css`
      pointer-events: none;
    `}
`;

const positionHoc =
  (options: IHOCOptions) =>
  <OriginalProps extends IOriginalProps>(
    Component: React.ComponentType<OriginalProps & InjectedProps>
  ): React.ComponentType<OriginalProps & ExternalProps> => {
    class HOC extends React.PureComponent<OriginalProps & ExternalProps> {
      protected ref = React.createRef<HTMLDivElement>();

      public render() {
        const { item, buttonsScale } = this.props;

        return (
          <DraggableDiv
            x={item.x}
            y={item.y}
            buttonsScale={buttonsScale}
            zIndex={item.zIndex}
            ref={this.ref}
            isInteractable={options.isInteractable}
          >
            <Component {...this.props} />
          </DraggableDiv>
        );
      }
    }

    return HOC;
  };

export default positionHoc;
