import React, { useEffect, useState } from 'react';
import { animateDashOffset } from './animateDashOffset';
import cx from 'classnames';
import styles from './loader.module.sass';

export const LoadingIndicator = ({
  color = '#008EFF',
  className,
}: {
  color?: string;
  className?: string;
}) => {
  const [svgPathElement, setSvgPathElement] = useState<SVGPathElement | null>(
    null
  );

  useEffect(() => {
    if (!svgPathElement) return;
    const animation = animateDashOffset(svgPathElement);

    return () => {
      animation?.clear();
    };
  }, [svgPathElement]);

  return (
    <div
      className={cx(className, styles.loader)}
      // @ts-ignore
      style={{ '--path': color, display: 'none' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 86 80"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
      >
        <path
          ref={setSvgPathElement}
          className="voomly-loader-path"
          d="M100,140v68l57-34-57-34Z"
          transform="translate(-94-134)"
          fill="none"
          stroke={color}
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="145,76,145,76"
          strokeDashoffset="0"
        />
      </svg>
    </div>
  );
};
