import React from 'react';
import styled from 'styled-components';
import { appearAnimationHoc } from '../playerHocs/appearAnimationHoc';
import positionHoc from '../playerHocs/positionHoc';
import { ImageItem, IImageItemProps } from './Base';
import highlightItemHoc from '../playerHocs/highlightItemHoc';

const ImageItemStyled = styled(ImageItem)<{ isClickable: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

const Component = (props: IImageItemProps) => (
  <ImageItemStyled
    isClickable={props.item.actionOnClick.type !== 'doNothing'}
    {...props}
  />
);
export const PlayerImageItem = appearAnimationHoc()(
  positionHoc({ isInteractable: true })(highlightItemHoc()(Component))
);
