import axios from 'axios';
import { makeUrl } from '@voomly/utils';

export enum eSubtitlesStatus {
  processing = 'processing',
  failed = 'failed',
  completed = 'completed',
}

export interface IIFsFileItemCustomSubtitlesItem {
  id: string;
  startTime: number;
  endTime: number;
  content: string;
}

export type FsFileItemCustomSubtitles = IIFsFileItemCustomSubtitlesItem[];

export type FsFileItemCustomJsonSubtitles = {
  items: FsFileItemCustomSubtitles;
};

export type ConvertSubtitlesInputFormat = 'srt' | 'ass';

export interface IFsFileItemSubtitles {
  id: string;
  status: eSubtitlesStatus;
  language: string;
  isAutogenerated: boolean;
  error?: string;
  size?: number;
  uploadId?: string;
  content?: FsFileItemCustomJsonSubtitles;
  inputFormat?: ConvertSubtitlesInputFormat;
}

type ISubtitlesPublicResponse = IFsFileItemSubtitles;
export const getSubtitlesPublicRequest = (
  fileId: string,
  subtitlesId: string
) =>
  axios.get<ISubtitlesPublicResponse>(
    makeUrl(`/files/${fileId}/subtitles/${subtitlesId}`)
  );
