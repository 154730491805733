import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import store from 'store';
import styled from 'styled-components';
import { usePrevious } from 'react-use';
import { generate } from 'shortid';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import {
  changeCurrentSubtitlesName,
  updateSubtitlesVisibility,
} from '../../../store/subtitles/actions';
import { usePlayerSkin } from '../skins/PlayerSkinContext';
import {
  currentSubtitlesNameSelector,
  subtitlesIsVisibleSelector,
} from '../../../store/subtitles/selectors';
import styles from '../SelectQuality/styles.module.sass';
import { Select } from '../Select/Select';
import {
  addControlsStayVisibleBy,
  removeControlsStayVisibleBy,
} from '../../../store/videoControls/actions';
import {
  useSubtitlesLocalStorage,
  useSubtitlesLocalStorageKeys,
} from './useSubtitlesLocalStorage';

const StyledIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIndicator = styled.div`
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 7px;
  height: 7px;
  background-color: var(--red-500);
  border-radius: 50%;
`;

const SUBTITLES_TOGGLE_ID = 'SUBTITLES_TOGGLE';

const ToggleSubtitlesComponent = ({
  file,
  player,
  player: {
    skin: { captions },
  },
  player: { subtitles },
  allControlsAreAlwaysVisible,
}: IDefaultPropTypes) => {
  useSubtitlesLocalStorage({ player, file });
  const skin = usePlayerSkin();
  const dispatch = useDispatch();
  const subtitlesIsVisible = useSelector(subtitlesIsVisibleSelector);
  const currentSubtitlesName = useSelector(currentSubtitlesNameSelector);
  const enabledSubtitlesList = useMemo(() => {
    return subtitles?.items.filter((i) => i.isEnabled) || [];
  }, [subtitles?.items]);

  const toggleSubtitlesVisibility = useCallback(() => {
    dispatch(updateSubtitlesVisibility(undefined));
  }, [dispatch]);

  const handleChangeOpen = useCallback(
    (opened: boolean) => {
      if (opened) {
        dispatch(addControlsStayVisibleBy('SELECT_SUBTITLES'));
      } else {
        dispatch(removeControlsStayVisibleBy('SELECT_SUBTITLES'));
      }
    },
    [dispatch]
  );

  const handleSelect = useCallback(
    (subtitlesId: string) => {
      if (subtitlesId === SUBTITLES_TOGGLE_ID) {
        toggleSubtitlesVisibility();
        return;
      }
      dispatch(changeCurrentSubtitlesName(subtitlesId));
      dispatch(updateSubtitlesVisibility(true));
    },
    [dispatch, toggleSubtitlesVisibility]
  );

  const subtitlesOptions = useMemo(() => {
    return [
      subtitlesIsVisible
        ? {
            label: 'Off',
            value: SUBTITLES_TOGGLE_ID,
          }
        : undefined,
      ...enabledSubtitlesList.map((item) => ({
        label: item.name,
        value: item.name,
      })),
    ].filter(Boolean) as { label: string; value: string }[];
  }, [enabledSubtitlesList, subtitlesIsVisible]);

  const buttonProps = useMemo(() => {
    const SubtitlesIcon = skin.icons.subtitles;

    return {
      config: player,
      icon: (
        <StyledIconWrapper>
          {subtitlesIsVisible && <StyledIndicator />}
          <SubtitlesIcon />
        </StyledIconWrapper>
      ),
      title: captions.subtitlesText,
    };
  }, [captions.subtitlesText, player, skin, subtitlesIsVisible]);

  const selectedSubtitlesValue = useMemo(() => {
    if (!subtitlesIsVisible) return undefined;

    return subtitlesOptions.find((i) => {
      if (!currentSubtitlesName) return false;

      return i.value === currentSubtitlesName;
    })?.value;
  }, [currentSubtitlesName, subtitlesIsVisible, subtitlesOptions]);

  const content = (
    <Select
      className={cx(
        skin.classes.toggleChapters__root,
        styles.selectSubtitlesDropdown
      )}
      buttonProps={buttonProps}
      config={player}
      options={subtitlesOptions}
      selectedValue={selectedSubtitlesValue}
      onSelect={handleSelect}
      onChangeOpen={handleChangeOpen}
    />
  );

  if (
    (player.controls.showSubtitlesButton && enabledSubtitlesList.length) ||
    (allControlsAreAlwaysVisible && player.controls.showSubtitlesButton)
  ) {
    return content;
  }

  return null;
};

export const ToggleSubtitles = (props: IDefaultPropTypes) => {
  const { storageSubtitlesSelectedKeyRef, storageVisibilityKeyRef } =
    useSubtitlesLocalStorageKeys();
  const [subtitlesKey, setSubtitlesKey] = useState(generate());
  const prevSubtitlesConfig = usePrevious(props.player.subtitles);
  const prevShowByDefault = usePrevious(
    props.player.skin.subtitles.showByDefault
  );

  // When we change subtitles config on Timeline editor
  // we need to reset subtitles selected and visibility
  // to keep it in sync with player subtitles config
  // Invalidate player subtitles config
  useEffect(() => {
    if (
      prevSubtitlesConfig !== props.player.subtitles ||
      prevShowByDefault !== props.player.skin.subtitles.showByDefault
    ) {
      store.remove(storageSubtitlesSelectedKeyRef.current);
      store.remove(storageVisibilityKeyRef.current);
      setSubtitlesKey(generate());
    }
  }, [
    props.player.skin.subtitles.showByDefault,
    props.player.subtitles,
    prevSubtitlesConfig,
    prevShowByDefault,
    storageSubtitlesSelectedKeyRef,
    storageVisibilityKeyRef,
  ]);

  return <ToggleSubtitlesComponent key={subtitlesKey} {...props} />;
};
