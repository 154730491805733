import { call, cancelled } from 'redux-saga/effects';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import to from 'await-to-js';
import { eventChannel, END } from 'redux-saga';

const { CancelToken } = axios;

// Taken from https://github.com/redux-saga/redux-saga/issues/651

export function request(config: AxiosRequestConfig = {}) {
  return call(function* () {
    const source = CancelToken.source();
    try {
      // const maybeRetryCall = (fn: any, ...args: any) => withRetry ? retry(3, 2_000, fn, ...args) : call(fn, ...args);

      return yield call((args) => to(axios.request(args)), {
        cancelToken: source.token,
        ...config,
      });
    } finally {
      if (yield cancelled()) {
        source.cancel();
      }
    }
  });
}

export type RequestEvent =
  | {
      type: 'progressUpdated';
      loaded: number;
      total: number;
    }
  | {
      type: 'finished';
      response: AxiosResponse;
    }
  | {
      type: 'errorHappened';
      error: AxiosError;
    };

export function requestChannelWithProgress(config: AxiosRequestConfig = {}) {
  return eventChannel<RequestEvent>((emitter) => {
    const source = CancelToken.source();

    axios
      .request({
        ...config,
        cancelToken: source.token,
        onUploadProgress: (e) =>
          emitter({
            type: 'progressUpdated',
            loaded: e.loaded,
            total: e.total,
          }),
      })
      .then((response) => {
        emitter({ type: 'finished', response });
      })
      .catch((error) => {
        emitter({ type: 'errorHappened', error });
      })
      .finally(() => {
        emitter(END);
      });

    return () => {
      source.cancel();
    };
  });
}

// and then defining verbs

export function getRequest(url: string, config: AxiosRequestConfig = {}) {
  return request({ method: 'get', url, ...config });
}

export function postRequest(
  url: string,
  data?: object,
  config: AxiosRequestConfig = {}
) {
  return request({ method: 'post', url, data, ...config });
}

export function putRequest(
  url: string,
  data?: object,
  config: AxiosRequestConfig = {}
) {
  return request({ method: 'put', url, data, ...config });
}

export function patchRequest(
  url: string,
  data?: object,
  config: AxiosRequestConfig = {}
) {
  return request({ method: 'patch', url, data, ...config });
}

export function deleteRequest(url: string, config: AxiosRequestConfig = {}) {
  return request({ method: 'delete', url, ...config });
}
