import React, { useCallback } from 'react';
import cx from 'classnames';
import { getStyles } from '../skins/getStyles';
import styles from './button.module.sass';
import { IPlayerTemplate } from '../../../types/player';
import { usePlayerSkin } from '../skins/PlayerSkinContext';

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  config: IPlayerTemplate;
  icon?: React.ReactNode | JSX.Element | null;
  isHovered?: boolean;
}

const Button = ({
  config,
  className,
  style,
  icon,
  isHovered,
  title,
  onClick,
  ...rest
}: IButtonProps) => {
  const skin = usePlayerSkin();
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick?.(e);
    },
    [onClick]
  );

  return (
    <button
      type="button"
      {...rest}
      onClick={handleClick}
      className={cx(styles.root, skin.classes.button__root, className, {
        [styles.isHovered]: isHovered,
      })}
      style={{ ...getStyles('button__root', skin, config), ...style }}
      title={title}
    >
      {icon}
    </button>
  );
};

export default Button;
