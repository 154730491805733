import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { DetachedStoreContext } from '../../contexts/DetachedStoreContext';

const Container = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
`;

const lineAnimation = keyframes`
 0% { opacity: 1; }
 100% { opacity: 0 }
`;

const HorizontalLine = styled.div<{ y: number }>`
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  top: ${({ y }) => y + 'px'};
  background-color: #008eff;

  animation-name: ${lineAnimation};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const VerticalLine = styled.div<{ x: number }>`
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  left: ${({ x }) => x + 'px'};
  background-color: #008eff;

  animation-name: ${lineAnimation};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

export class GuideLinesContainer extends React.Component<any, any> {
  static contextType = DetachedStoreContext;
  public context!: React.ContextType<typeof DetachedStoreContext>;

  public shouldComponentUpdate(
    nextProps: any,
    nextState: any,
    nextContext: React.ContextType<typeof DetachedStoreContext>
  ): boolean {
    return (
      this.context!.snappedToX !== nextContext!.snappedToX ||
      this.context!.snappedToY !== nextContext!.snappedToY
    );
  }

  public render() {
    // snappedToX / Y are already multiplied by buttonScale
    const { snappedToX, snappedToY } = this.context!;
    return (
      <Container>
        {typeof snappedToY !== 'undefined' && (
          <HorizontalLine key={snappedToY} y={snappedToY} />
        )}
        {typeof snappedToX !== 'undefined' && (
          <VerticalLine key={snappedToX} x={snappedToX} />
        )}
      </Container>
    );
  }
}
