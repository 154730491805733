import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useClickAway } from 'react-use';
import PopoverTip from '../PopoverTip/PopoverTip';
import Button, { IButtonProps } from '../Button/Button';
import { getStyles } from '../skins/getStyles';
import styles from './select.module.sass';
import { IPlayerTemplate } from '../../../types/player';
import { usePlayerSkin } from '../skins/PlayerSkinContext';

export const Select = ({
  className,
  buttonProps,
  config,
  options,
  selectedValue,
  onSelect,
  onChangeOpen,
}: {
  className: string;
  buttonProps: IButtonProps;
  config: IPlayerTemplate;
  options: { value: number | string; label: string }[];
  selectedValue: number | string | undefined;
  onSelect: (value: number | string) => void;
  onChangeOpen?: (open: boolean) => void;
}) => {
  const selectRef = useRef(null);
  const skin = usePlayerSkin();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    onChangeOpen?.(opened);
  }, [opened, onChangeOpen]);

  useClickAway(selectRef, () => {
    setOpened(false);
  });

  const handleSelect = useCallback(
    (
      value: number | string,
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
      event.stopPropagation();
      onSelect(value);
      setOpened(false);
    },
    [onSelect]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setOpened(!opened);
    },
    [opened]
  );

  return (
    <div ref={selectRef} className={cx(styles.root, className)}>
      {options.length > 0 ? (
        <div
          className={cx(styles.popover, skin.classes.controls__popover, {
            [styles.withTip]: Boolean(skin.tips),
            [styles.opened]: opened,
            [skin.classes.controls__popoverOpened]:
              skin.classes.controls__popoverOpened && opened,
          })}
          style={getStyles('controls__popover', skin, config)}
        >
          <div className={cx(styles.select, skin.classes.select)}>
            {options.map(({ value, label }) => {
              const selected = value === selectedValue;
              const className = cx(
                styles.option,
                skin.classes.controls__selectOption,
                {
                  [styles.selected]: selected,
                  [skin.classes.controls__selectedOption]: selected,
                }
              );
              const style = {
                ...getStyles('controls__selectOption', skin, config),
                ...(selected
                  ? getStyles('controls__selectedOption', skin, config)
                  : {}),
              };

              return (
                <span
                  key={value}
                  className={className}
                  style={style}
                  onClick={(event) => handleSelect(value, event)}
                >
                  <span className={styles.icon} />
                  <span className={styles.label}>{label}</span>
                </span>
              );
            })}
            {skin.tips && <PopoverTip skin={skin} config={config} />}
          </div>
        </div>
      ) : null}
      <Button {...buttonProps} onClick={handleClick} />
    </div>
  );
};
