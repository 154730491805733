import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway, usePrevious } from 'react-use';
import { IPlayerRootReducerShape } from '../store/rootPlayerStore';
import { toggleFullScreen } from '../store/containerDimensions/actions';
import { getAreKeybindingsGlobal } from '../store/selectors';
import {
  requestSeek,
  requestToggleMute,
  requestTogglePlayPause,
  requestVolumeChange,
} from '../store/videoState/actions';
import { getIsPlaybackBlocked } from '../store/selectors';
import { getHasStarted } from '../store/videoState/selectors';

// https://stackoverflow.com/a/40877492/3872807
const isInputFocused = () => {
  try {
    const el = document.activeElement as HTMLInputElement;

    if (
      (el && el.selectionStart !== undefined) ||
      (el && el.isContentEditable)
    ) {
      return true; // active element has caret, do not proceed
    }
    // eslint-disable-next-line no-empty
  } catch (ex) {}

  return false;
};

export const usePlayerKeybindings = (
  rootDivRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  const dispatch = useDispatch();

  const hasStarted = useSelector(getHasStarted);
  const areBindsGlobal = useSelector(getAreKeybindingsGlobal);
  const isPlaybackBlocked = useSelector(getIsPlaybackBlocked);

  const currentlyFocusedRef = useRef<boolean>(false);

  useClickAway(rootDivRef, () => {
    currentlyFocusedRef.current = false;
  });

  const handleKey = useCallback(
    (code: string) => {
      if (isInputFocused()) return false;
      if (isPlaybackBlocked) return;

      const numberKey = /Digit([0-9])/gi.exec(code)?.[1];

      // Bindings are taken from https://support.google.com/youtube/answer/7631406
      if (numberKey !== undefined) {
        dispatch(
          requestSeek({
            type: 'percent',
            percent: parseInt(numberKey, 10) * 10,
          })
        );

        return true;
      } else if (code === 'ArrowRight') {
        dispatch(requestSeek({ type: 'relative', bySeconds: 5 }));

        return true;
      } else if (code === 'ArrowLeft') {
        dispatch(requestSeek({ type: 'relative', bySeconds: -5 }));

        return true;
      } else if (code === 'KeyK') {
        dispatch(requestTogglePlayPause());

        return true;
      } else if (code === 'KeyM') {
        dispatch(requestToggleMute());

        return true;
      } else if (code === 'KeyL') {
        dispatch(requestSeek({ type: 'relative', bySeconds: 10 }));

        return true;
      } else if (code === 'KeyJ') {
        dispatch(requestSeek({ type: 'relative', bySeconds: -10 }));

        return true;
      } else if (code === 'KeyF') {
        dispatch(toggleFullScreen());

        return true;
      } else if (code === 'ArrowUp') {
        dispatch(requestVolumeChange({ type: 'relativeVolume', byPercent: 5 }));

        return true;
      } else if (code === 'ArrowDown') {
        dispatch(
          requestVolumeChange({ type: 'relativeVolume', byPercent: -5 })
        );

        return true;
      } else if (code === 'Space') {
        dispatch(requestTogglePlayPause());
        if (!hasStarted) {
          dispatch(requestVolumeChange({ type: 'mute', mute: false }));
        }

        return true;
      }

      return false;
    },
    [dispatch, isPlaybackBlocked, hasStarted]
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!areBindsGlobal && currentlyFocusedRef.current === false) return;

      if (handleKey(e.code)) {
        e.preventDefault();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [areBindsGlobal, handleKey]);

  // Let's focus on player after full screen exit
  const isFullScreen = useSelector(
    (state: IPlayerRootReducerShape) => state.dimensions.isFullScreen
  );
  const prevIsFullScreen = usePrevious(isFullScreen);

  useEffect(() => {
    if (
      prevIsFullScreen === true &&
      isFullScreen === false &&
      rootDivRef.current
    ) {
      currentlyFocusedRef.current = true;
    }
  }, [isFullScreen, prevIsFullScreen, rootDivRef]);

  const handleMouse = useCallback(() => {
    currentlyFocusedRef.current = true;
  }, []);

  return useMemo(
    () => ({
      onMouseDown: handleMouse,
      onTouchStart: handleMouse,
    }),
    [handleMouse]
  );
};
