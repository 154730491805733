import { ActionType, getType } from 'typesafe-actions';
import { IThumbnail } from '../../types/player';
import { IPlayerVideo } from '../../types/video';
import * as actions from './actions';
import * as videoActions from '../videoState/actions';

export interface IThumbnailShape {
  thumbnail: IThumbnail | undefined;
  customVideo?: IPlayerVideo;
  isVisible: boolean;
}

const initialState: IThumbnailShape = {
  thumbnail: undefined,
  customVideo: undefined,
  isVisible: true,
};

type IAction = ActionType<typeof actions | typeof videoActions>;
const reducer = (
  state: IThumbnailShape = initialState,
  action: IAction
): IThumbnailShape => {
  switch (action.type) {
    case getType(actions.updateThumbnail):
      return {
        ...state,
        thumbnail: action.payload,
      };

    case getType(actions.updateVisibility):
      return {
        ...state,
        isVisible: action.payload,
      };

    case getType(videoActions.videoPlayStarted):
      return {
        ...state,
        isVisible: false,
      };

    case getType(videoActions.requestSeek):
    case getType(videoActions.videoSeekStarted):
    case getType(videoActions.videoSeeked):
      return {
        ...state,
        isVisible: false,
      };

    case getType(actions.setThumbnailCustomVideo):
      return {
        ...state,
        customVideo: action.payload,
      };
  }

  return state;
};

export default reducer;
