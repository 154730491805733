import React, { memo, useCallback } from 'react';
import Button from '../Button/Button';
import { IDefaultPropTypes } from '../../types/defaultPropTypes';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFullScreen } from '../../../store/containerDimensions/actions';
import { usePlayerSkin } from '../skins/PlayerSkinContext';
import { selectIsFullScreen } from '../../../store/containerDimensions/selectors';

const ToggleFullscreen = ({
  player,
  player: {
    skin: { captions },
  },
}: IDefaultPropTypes) => {
  const dispatch = useDispatch();
  const isFullScreen = useSelector(selectIsFullScreen);
  const skin = usePlayerSkin();

  const handleOnClick = useCallback(() => {
    dispatch(setIsFullScreen(!isFullScreen));
  }, [dispatch, isFullScreen]);

  // Since iOS doesn't support Fullscreen API, then don't show Full screen button
  // because all our functionality like annotations/cta/funnels are not available on native fullscreen
  if (!player.controls.showFullscreenButton) return null;

  const { classes } = skin;
  const EnterIcon = skin.icons.fullscreenEnter;
  const ExitIcon = skin.icons.fullscreenExit;

  return (
    <Button
      className={classes.toggleFullscreen__root}
      config={player}
      onClick={handleOnClick}
      icon={isFullScreen ? <ExitIcon /> : <EnterIcon />}
      title={captions.fullscreenText}
    />
  );
};

export default memo(ToggleFullscreen);
