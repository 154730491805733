import type { IPlayerTemplate, IPlayerValues } from '../types/player';
import { IPlayerForVideoSubtitlesAppearance } from '../types/player';

export const subtitlesAppearanceDefault: IPlayerForVideoSubtitlesAppearance = {
  fontFamily: 'Roboto',
  fontSize: 16,
  fontStyle: [],
  textAlign: 'align-center',
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  showByDefault: false,
};

export const DEFAULT_PLAYER_DATA: IPlayerValues = {
  name: 'Voomly Player',
  general: {
    autoplay: false,
    resumeOnFutureVisitsEnabled: false,
    muted: false,
    onEnd: {
      action: 'RESET_TO_THUMBNAIL',
      redirectUrl: undefined,
    },
  },
  skin: {
    bgColor: '#008EFF',
    bgColorEnabled: true,
    logoEnabled: true,
    useCustomLogo: false,
    customLogoUrl: undefined,
    customUrl: undefined,
    controlBarSkin: 'default',
    borderSkinEnabled: false,
    borderSkin: 'iphone',
    subtitles: subtitlesAppearanceDefault,
    captions: {
      playText: 'Play',
      pauseText: 'Pause',
      playbackSpeedText: 'Playback speed',
      volumeText: 'Volume',
      qualityText: 'Quality',
      subtitlesText: 'Subtitles',
      fullscreenText: 'Full screen',
      normalSpeedText: 'Normal',
      rewatchText: 'Rewatch',
      enableSoundText: 'Enable sound',
      logoText: 'Logo',
    },
  },
  controls: {
    playBar: true,
    showPlayBar: true,
    visibleControlsOnLoad: true,
    showVolumeButton: true,
    showSelectSpeedButton: true,
    showFullscreenButton: true,
    showPlayButton: true,
    showSelectQualityButton: true,
    showSubtitlesButton: true,
    showTime: true,
    playButton: 'large',
    customPlayButtonUrl: undefined,
    showRewatchButton: true,
    enableSoundButtonLocation: 'top-right',
  },
  security: {
    enabled: false,
    password: undefined,
    title: 'Video is Password Protected',
    enterPasswordText: 'Please enter password to view video:',
    submitButtonText: 'Unlock',
    submitButtonLoadingText: 'Loading...',
    emptyPasswordErrorText: 'Password is a required field',
    wrongPasswordErrorText: 'Wrong password',
  },

  floatingModal: {
    enabled: true,
    position: 'bottom-right',
    size: 'M',
    hasCloseButton: true,
    clickToExpand: {
      enabled: true,
      scale: 50,
    },
    overlayBackground: {
      enabled: false,
      color: 'rgba(255, 255, 255, 0.5)',
      blur: 1,
    },
  },

  openInModal: {
    enabled: false,
  },
};

export const emptyPlayerConfig: IPlayerTemplate = {
  name: '@@SPECIAL_EMPTY_PLAYER@@',

  general: {
    ...DEFAULT_PLAYER_DATA.general,
    resumeOnFutureVisitsEnabled: true,
  },

  security: DEFAULT_PLAYER_DATA.security,
  skin: DEFAULT_PLAYER_DATA.skin,
  controls: DEFAULT_PLAYER_DATA.controls,
  floatingModal: DEFAULT_PLAYER_DATA.floatingModal,
  openInModal: DEFAULT_PLAYER_DATA.openInModal,

  createdAt: '2020-02-28T16:33:28.760Z',
  updatedAt: '2020-04-14T23:18:28.612Z',
  deletedAt: '2020-04-14T23:18:28.612Z',
};
