import { AnyAction, Dispatch } from 'redux';
import {
  requestPause,
  requestPlay,
  requestQualityChange,
  requestSeek,
  requestSpeedChange,
  requestVolumeChange,
  getSpeedOptions,
  getQualityOptions,
} from '../../../store/videoState/actions';
import { enableCustomTurnstileIntegration } from '../../../store/customIntegrations/actions';
import { PlayerExternalAPIConnectedChecker } from '../PlayerExternalAPIConnectedChecker';

export type ToPlayerEventTypes = {
  'video:play': { time: number } | void;
  'video:pause': void;
  'video:seek': { time: number };
  'video:changeVolume': { volume: number };
  'video:mute': void;
  'video:unmute': void;
  'video:changeQuality': { quality: number; hlsUrl: string };
  'video:changeSpeed': { speed: number };
  'video:speedOptions': void;
  'video:qualityOptions': void;

  'timeline:turnstile:enable': void;
};

export type IToPlayerApiEventName = keyof ToPlayerEventTypes;

export class PlayerActionDispatcher {
  private dispatch?: Dispatch;

  public isInitialized = () => !!this.dispatch;

  public doDispatch = (action: AnyAction) => {
    if (!this.dispatch) {
      console.error('Player dispatcher is not initialized');
      return false;
    }

    return this.dispatch(action);
  };

  public register = (dispatch: Dispatch) => {
    this.dispatch = dispatch;
  };
}

export class ToPlayerDispatcher {
  private destroyed = false;
  private nodeConnectedChecker?: PlayerExternalAPIConnectedChecker;

  constructor(private dispatcher = new PlayerActionDispatcher()) {}

  public setNodeConnectedChecker = (
    nodeConnectedChecker: PlayerExternalAPIConnectedChecker
  ) => {
    this.nodeConnectedChecker = nodeConnectedChecker;
  };

  public isInitialized = this.dispatcher.isInitialized;
  public register = this.dispatcher.register;

  private isDestroyed = () =>
    (this.nodeConnectedChecker
      ? !this.nodeConnectedChecker?.checkConnected()
      : false) || this.destroyed;

  public destroy = () => {
    this.destroyed = true;
  };

  /**
   * Create a sort of event emitter for particular event name
   */
  private createEventEmitter = <T extends IToPlayerApiEventName>(
    eventName: T,
    callback: (payload: ToPlayerEventTypes[T]) => AnyAction
  ) => ({
    eventName,
    emit: (payload: ToPlayerEventTypes[T]) => {
      if (this.isDestroyed()) {
        console.warn(
          'Voomly player API is already destroyed. You cannot make new calls'
        );

        return;
      }

      this.dispatcher.doDispatch(callback(payload));
    },
  });

  /*
   * API start here
   */

  public play = this.createEventEmitter('video:play', (payload) => {
    return requestPlay({ time: payload ? payload.time : undefined });
  });

  public pause = this.createEventEmitter('video:pause', () => requestPause({}));

  public seek = this.createEventEmitter('video:seek', ({ time }) =>
    requestSeek({ time, type: 'toTime' })
  );

  public changeVolume = this.createEventEmitter(
    'video:changeVolume',
    ({ volume }) =>
      requestVolumeChange({
        volume,
        type: 'absoluteVolume',
      })
  );

  public mute = this.createEventEmitter('video:mute', () =>
    requestVolumeChange({
      mute: true,
      type: 'mute',
    })
  );

  public unmute = this.createEventEmitter('video:unmute', () =>
    requestVolumeChange({
      mute: false,
      type: 'mute',
    })
  );

  public changeQuality = this.createEventEmitter(
    'video:changeQuality',
    (option) => requestQualityChange(option)
  );

  public changeSpeed = this.createEventEmitter(
    'video:changeSpeed',
    ({ speed }) =>
      requestSpeedChange({
        value: speed,
      })
  );

  public enableCustomTurnstileIntegration = this.createEventEmitter(
    'timeline:turnstile:enable',
    () => enableCustomTurnstileIntegration({})
  );

  public getSpeedOptions = this.createEventEmitter('video:speedOptions', () =>
    getSpeedOptions()
  );

  public getQualityOptions = this.createEventEmitter(
    'video:qualityOptions',
    () => getQualityOptions()
  );
}
