import { IPlayerWithVideo } from '../../types/player';
import { IPlayerVideo } from '../../types/video';

export enum PlayerMode {
  NORMAL = 1,
  PLAYER_PREVIEW = 2,
  TIMELINE_EDITOR = 3,
  THUMBNAIL_EDITOR = 4,
  MINI_PLAYER = 5,
}

export interface IDefaultPropTypes {
  player: IPlayerWithVideo;
  file: IPlayerVideo;
  playerMode: PlayerMode;
  buttonsScale: number;
  allControlsAreAlwaysVisible: boolean;
}
