import * as React from 'react';
import { get } from 'lodash-es';
import cx from 'classnames';
import { secondsToHms } from '@voomly/utils';
import { IPlayerTemplate } from '../../../../types/player';
import { getStyles } from '../../skins/getStyles';
import styles from './currentTime.module.sass';

interface IClasses {
  progressBar__currentTime?: string;
}

interface ICurrentTime {
  currentTime: number;
  config: IPlayerTemplate;
  classes: IClasses;
  skin: any;
}

class CurrentTime extends React.Component<ICurrentTime> {
  static defaultProps = {
    classes: {},
  };

  render() {
    const { currentTime, skin, config, classes } = this.props;
    const showTime = get<IPlayerTemplate, 'controls', 'showTime'>(config, [
      'controls',
      'showTime',
    ]);

    if (!showTime) return null;

    return (
      <div
        className={cx(styles.root, classes.progressBar__currentTime)}
        style={getStyles('progressBar__currentTime', skin, config)}
      >
        {secondsToHms(currentTime)}
      </div>
    );
  }
}

export default CurrentTime;
