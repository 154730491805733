import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

export interface ISubtitlesShape {
  isVisible: boolean;
  currentSubtitlesName: string | undefined;
}

const initialState: ISubtitlesShape = {
  isVisible: false,
  currentSubtitlesName: undefined,
};

type IAction = ActionType<typeof actions>;
const reducer = (
  state: ISubtitlesShape = initialState,
  action: IAction
): ISubtitlesShape => {
  switch (action.type) {
    case getType(actions.updateSubtitlesVisibility):
      return {
        ...state,
        isVisible:
          typeof action.payload === 'undefined'
            ? !state.isVisible
            : action.payload,
      };
    case getType(actions.changeCurrentSubtitlesName):
      return {
        ...state,
        currentSubtitlesName: action.payload,
      };
  }

  return state;
};

export default reducer;
