// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes playPauseOverlay_scale-and-disappear__iFomY{0%{opacity:0;transform:translate(-50%, -50%) scale(0.5)}20%{opacity:1}70%{opacity:1}100%{opacity:0;transform:translate(-50%, -50%) scale(2)}}@keyframes playPauseOverlay_hide-animation__26L-U{100%{opacity:0}}.playPauseOverlay_wrapper__11ziD{pointer-events:none;position:absolute;top:50%;left:50%;z-index:5;transform:translate(-50%, -50%);animation:playPauseOverlay_scale-and-disappear__iFomY .6s forwards}.playPauseOverlay_wrapper__11ziD>div{width:200px;height:200px;display:flex;justify-content:center;align-items:center}.playPauseOverlay_wrapper__11ziD>div.playPauseOverlay_hideAnimation__3pI2X{animation:playPauseOverlay_hide-animation__26L-U .2s forwards}.playPauseOverlay_wrapper__11ziD>div svg{fill:#fff}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/PlayPauseOverlay/playPauseOverlay.module.sass"],"names":[],"mappings":"AAAA,uDACE,GACE,SAAA,CACA,0CAAA,CACF,IACE,SAAA,CACF,IACE,SAAA,CACF,KACE,SAAA,CACA,wCAAA,CAAA,CAEJ,kDACE,KACE,SAAA,CAAA,CACJ,iCACE,mBAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,SAAA,CACA,+BAAA,CACA,kEAAA,CAEA,qCACE,WAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,2EACE,6DAAA,CAEF,yCACE,SAAA","sourcesContent":["@keyframes scale-and-disappear\n  0%\n    opacity: 0\n    transform: translate(-50%, -50%) scale(0.5)\n  20%\n    opacity: 1\n  70%\n    opacity: 1\n  100%\n    opacity: 0\n    transform: translate(-50%, -50%) scale(2)\n\n@keyframes hide-animation\n  100%\n    opacity: 0\n.wrapper\n  pointer-events: none\n  position: absolute\n  top: 50%\n  left: 50%\n  z-index: 5\n  transform: translate(-50%, -50%)\n  animation: scale-and-disappear 0.6s forwards\n\n  > div\n    width: 200px\n    height: 200px\n    display: flex\n    justify-content: center\n    align-items: center\n\n    &.hideAnimation\n      animation: hide-animation 0.2s forwards\n\n    svg\n      fill: #FFF\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "playPauseOverlay_wrapper__11ziD",
	"scale-and-disappear": "playPauseOverlay_scale-and-disappear__iFomY",
	"hideAnimation": "playPauseOverlay_hideAnimation__3pI2X",
	"hide-animation": "playPauseOverlay_hide-animation__26L-U"
};
export default ___CSS_LOADER_EXPORT___;
