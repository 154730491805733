// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_simulatedFullScreenFix__3WhCb {\n  z-index: 9999999999 !important;\n  position: static !important;\n  display: block !important;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/WithFullScreen/styles.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,2BAA2B;EAC3B,yBAAyB;AAC3B","sourcesContent":[".simulatedFullScreenFix {\n  z-index: 9999999999 !important;\n  position: static !important;\n  display: block !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simulatedFullScreenFix": "styles_simulatedFullScreenFix__3WhCb"
};
export default ___CSS_LOADER_EXPORT___;
