// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_line__x6LU_{position:absolute;transition:width 300ms linear;z-index:40;bottom:0;left:0}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/AlternativeDurationLine/styles.module.sass"],"names":[],"mappings":"AAAA,oBACE,iBAAA,CACA,6BAAA,CACA,UAAA,CACA,QAAA,CACA,MAAA","sourcesContent":[".line\n  position: absolute\n  transition: width 300ms linear\n  z-index: 40\n  bottom: 0\n  left: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": "styles_line__x6LU_"
};
export default ___CSS_LOADER_EXPORT___;
