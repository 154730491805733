import axios, { AxiosError } from 'axios';
import to from 'await-to-js';
import {
  IAnalyticsEvent,
  IAnalyticsSessionEvent,
  IAnalyticsUser,
} from '../types/analytics';
import { makeUrl } from '@voomly/utils';
import 'navigator.sendbeacon';

interface ICreateAnalyticsEvents {
  user: IAnalyticsUser;
  events: IAnalyticsEvent[];
  byBeacon?: boolean;
}

// We could always use beacon method
// But it will not return if request was success or not
// So it will be used only on page unload
const sendEvents = async (url: string, data: object, byBeacon: boolean) => {
  if (byBeacon) {
    const isAddedToQueue = navigator.sendBeacon(url, JSON.stringify(data));

    if (isAddedToQueue) {
      return { success: true };
    } else {
      return { success: false, error: 'Failed to add beacon to queue' };
    }
  } else {
    const [err, response] = await to<{}, AxiosError>(axios.post(url, data));

    if (response) {
      return { success: true };
    }

    if (err) {
      return { success: false, error: err.response?.data };
    }

    throw new Error('no response or error');
  }
};

export const createAnalyticsEvents = async ({
  user,
  events,
  byBeacon,
}: ICreateAnalyticsEvents) => {
  return sendEvents(
    makeUrl('/analytics-v2/events'),
    { user, events },
    !!byBeacon
  );
};

interface ICreateSessionAnalyticsEvents {
  events: IAnalyticsSessionEvent[];
  sessionId: string;
  byBeacon?: boolean;
}

export const createSessionAnalyticsEvents = async ({
  events,
  sessionId,
  byBeacon,
}: ICreateSessionAnalyticsEvents) => {
  return sendEvents(
    makeUrl(`/analytics-v3/sessions/${sessionId}/events`),
    events,
    !!byBeacon
  );
};

interface IGetSessionIdResponse {
  id: string;
}

export const fetchSessionId = async (
  config: {
    videoId: string;
    funnelId: string | undefined;
    nodeId: string | undefined;
    spotlightId: string | undefined;
  },
  user: IAnalyticsUser
) => {
  const [err, response] = await to(
    axios.post<IGetSessionIdResponse>(makeUrl('/analytics-v3/sessions'), {
      ...config,
      user,
    })
  );

  if (response) {
    return response.data.id;
  }

  if (err) {
    return undefined;
  }

  throw new Error('unreachable code');
};
