import { createStandardAction } from 'typesafe-actions';
import { IThumbnail } from '../../types/player';
import { IPlayerVideo } from '../../types/video';

export const updateThumbnail = createStandardAction(
  'thumbnail/UPDATE_THUMBNAIL'
)<IThumbnail | undefined>();

export const updateVisibility = createStandardAction(
  'thumbnail/UPDATE_VISIBILITY'
)<boolean>();

export const setThumbnailCustomVideo = createStandardAction(
  'thumbnail/SET_CUSTOM_VIDEO'
)<IPlayerVideo>();
