import React from 'react';
import cx from 'classnames';
import { IBorderSkin } from '../../../store/video/borderSkins/borderSkins';
import styles from './border.module.sass';

const fullScreenStyles = {
  width: '100%',
  height: '100%',
};

export interface IBorderProps extends IBorderSkin {
  borderContentStyle?: React.CSSProperties;
  shouldRender: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Border = ({
  gutter,
  borderRadius,
  image,
  className,
  borderContentStyle,
  children,
  shouldRender,
  width,
  height,
}: IBorderProps) => {
  if (!shouldRender)
    return (
      // NOTE: this way we will not trigger remount of player when Player Skin is changed(in player editor)
      <div key="1" style={fullScreenStyles}>
        <div key="2" style={fullScreenStyles}>
          <img
            style={{ display: 'none' }}
            key="3"
            alt="Player border skin"
            width={width}
            height={height}
          />
          <div
            key="4"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...fullScreenStyles,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    );

  return (
    <div key="1" className={cx(styles.root, className)}>
      <div
        className={styles.inner}
        style={{
          padding: `${gutter.top}% ${gutter.right}% ${gutter.bottom}% ${gutter.left}%`,
        }}
        key="2"
      >
        <picture>
          <source srcSet={image.webp} type="image/webp" />
          <img
            className={styles.image}
            src={image.original}
            key="3"
            alt="Player border skin"
            width={width}
            height={height}
          />
        </picture>
        <div
          className={cx(styles.content)}
          style={{
            borderRadius: borderRadius ? `${borderRadius}%` : undefined,
            ...borderContentStyle,
          }}
          key="4"
        >
          {children}
        </div>
      </div>
    </div>
  );
};
