import * as React from 'react';
import uploadImageIcon from '../../../svg/upload-image.svg';
import styled from 'styled-components';
import { CircularProgressCentered } from '@voomly/ui/player-deps';
import { Dim } from '@voomly/ui/player-deps';
import { ImageWithFallback } from '@voomly/ui/player-deps';
import { IHasImage } from '../../../types/types';

const ImgStyled = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
  user-drag: none;
`;

export function UploadableImageContent<Item extends IHasImage>({
  item,
  onClick,
  uploadProgress,
  className,
  style,
}: {
  item: Item;
  onClick: ((item: Item) => void) | undefined;
  uploadProgress: number | undefined;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <>
      <ImgStyled
        className={className}
        src={item.image.type === 'notSet' ? uploadImageIcon : item.image.src}
        onClick={() => onClick && onClick(item)}
        onMouseDown={(event) => {
          // It prevents image dragging in FF
          if (event.preventDefault) event.preventDefault();
        }}
        alt=""
        style={style}
      />

      {uploadProgress !== undefined && <Dim />}

      {uploadProgress !== undefined && (
        <CircularProgressCentered
          state={{ type: 'inProgress', value: uploadProgress }}
        />
      )}
    </>
  );
}
