import { delay, put, race, take, takeLatest } from 'redux-saga/effects';
import {
  toggleAlternativeDurationLine,
  updateAlternativeDurationLineProgress,
} from '../miscScreenObjects/actions';
import { dismissVideoItemOnScreen } from '../videoItems/actions';
import { requestPlay } from '../videoState/actions';

const tickTime = 300;
// The playing will be also blocked by sideEffectsSaga
function* lineWorker(action: ReturnType<typeof toggleAlternativeDurationLine>) {
  if (action.payload === undefined) return;

  const startTime = new Date().getTime();

  while (true) {
    const [, toggle]: [
      true | undefined,
      ReturnType<typeof toggleAlternativeDurationLine> | undefined
    ] = yield race([
      delay(tickTime, true),
      take(toggleAlternativeDurationLine),
    ]);

    // means cancelled
    if (toggle && toggle.payload === undefined) break;

    const timePassed = new Date().getTime() - startTime;

    const percents = (timePassed / (action.payload.duration * 1000)) * 100;

    if (percents > 100) {
      yield put(updateAlternativeDurationLineProgress(100));
      yield delay(300);
      yield put(requestPlay({}));
      yield put(
        dismissVideoItemOnScreen({
          id: action.payload.triggeredByItemId,
          remember: false,
        })
      );
      yield put(toggleAlternativeDurationLine(undefined));
    } else {
      yield put(updateAlternativeDurationLineProgress(percents));
    }
  }
}

export function* alternativeDurationLineSaga() {
  yield takeLatest(toggleAlternativeDurationLine, lineWorker);
}
