import { PublicPlayerExternalAPI } from './PlayerExternalAPI';

export class PlayerTimestamper {
  private readonly videoId: string;
  private playerExternalAPI: PublicPlayerExternalAPI;

  constructor(id: string, playerExternalAPI: PublicPlayerExternalAPI) {
    this.videoId = id;
    this.playerExternalAPI = playerExternalAPI;
  }

  public run = () => {
    this.playerExternalAPI.onAPIReady(() => {
      const unsubscribeOnReady = this.playerExternalAPI.onReady(() => {
        this.gotoTimestamp();

        unsubscribeOnReady();
      });
    });
  };

  private gotoTimestamp = () => {
    const timestamps = this.getTimestampsFromQueryString();
    if (!timestamps.length) {
      return;
    }

    if (this.isGlobalSeek(timestamps)) {
      this.globalSeek(timestamps);
      return;
    }

    this.perVideoSeek(timestamps);
  };

  private perVideoSeek = (timestamps: string[]) => {
    const videoTimestamp = timestamps.find((timestamp) => {
      return (
        this.isTimestampWithVideoId(timestamp) &&
        timestamp.split(',')[1] === this.videoId
      );
    });

    if (videoTimestamp) {
      const time = videoTimestamp.split(',')[0];
      this.seek(parseFloat(time));
    }
  };

  private globalSeek = (timestamps: string[]) => {
    this.seek(parseFloat(timestamps[0]));
  };

  private seek = (timestamp: number) => {
    this.playerExternalAPI.seek({ time: timestamp });
  };

  private isGlobalSeek = (timestamps: string[]) =>
    timestamps.length === 1 && !this.isTimestampWithVideoId(timestamps[0]);

  private isTimestampWithVideoId = (timestamp: string) => {
    // format: 10,id_string
    const timestampParts = timestamp.split(',');
    const time = timestampParts[0];
    const id = timestampParts[1];
    return timestampParts.length === 2 && id && parseFloat(time) > 0;
  };

  private getTimestampsFromQueryString = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.getAll('t');
  };
}
