import { Ref } from 'react';

export const mergeRefs =
  <T>(...refs: Array<Ref<any> | undefined>) =>
  (ref: T) => {
    refs.forEach((resolvableRef) => {
      if (!resolvableRef) {
        return;
      }

      if (typeof resolvableRef === 'function') {
        resolvableRef(ref);
      } else {
        (resolvableRef as any).current = ref;
      }
    });
  };
