import React from 'react';

import { PlayerPlainTextItem } from '../PlainText/PlayerPlainText';
import { PlayerButtonItem } from '../Button/PlayerButton';
import { PlayerImageAnnotationItem } from '../ImageAnnotation/PlayerImageAnnotation';
import { PlayerShareGateItem } from '../ShareGate/PlayerShareGateItem';
import { PlayerTurnstileItem } from '../Turnstile/PlayerTurnstile';
import { PlayerImageItem } from '../Image/PlayerImage';
import { PlayerGridItem } from '../Grid/PlayerGrid';
import { PlayerAnnotationItem } from '../Annotation/PlayerAnnotation';
import { PlayerSurveyItem } from '../Survey/PlayerSurvey';

export interface IComponentsToUseContext {
  PlainText: React.ComponentType<
    React.ComponentProps<typeof PlayerPlainTextItem>
  >;
  Button: React.ComponentType<React.ComponentProps<typeof PlayerButtonItem>>;
  Annotation: React.ComponentType<
    React.ComponentProps<typeof PlayerAnnotationItem>
  >;
  ShareGate: React.ComponentType<
    React.ComponentProps<typeof PlayerShareGateItem>
  >;
  Turnstile: React.ComponentType<
    React.ComponentProps<typeof PlayerTurnstileItem>
  >;
  Image: React.ComponentType<React.ComponentProps<typeof PlayerImageItem>>;
  ImageAnnotation: React.ComponentType<
    React.ComponentProps<typeof PlayerImageAnnotationItem>
  >;
  Grid: React.ComponentType<React.ComponentProps<typeof PlayerGridItem>>;
  Survey: React.ComponentType<React.ComponentProps<typeof PlayerSurveyItem>>;
}

export const ComponentsToUseContext = React.createContext<
  IComponentsToUseContext | undefined
>(undefined);
