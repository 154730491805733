// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".currentTime_root__1UApT{position:relative;width:40px;padding:0 5px;text-align:right;pointer-events:none;color:currentColor}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/ProgressBar/CurrentTime/currentTime.module.sass"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CACA,UAAA,CACA,aAAA,CACA,gBAAA,CACA,mBAAA,CACA,kBAAA","sourcesContent":[".root\n  position: relative\n  width: 40px\n  padding: 0 5px\n  text-align: right\n  pointer-events: none\n  color: currentColor"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "currentTime_root__1UApT"
};
export default ___CSS_LOADER_EXPORT___;
