import { apiRootUrl } from './config/env';
import { isEmpty } from 'lodash-es';
import queryString from 'qs';

export const makeUrl = (path: string, queryParams = {}) => {
  let finalPath = path
    ? path.substring(0, 1) === '/'
      ? path
      : `/${path}`
    : '';

  if (finalPath.substring(finalPath.length - 1, finalPath.length) === '/') {
    console.error(
      'New APIGateway does not allow having trailing `/`! We stripped it for you, but fix your code'
    );
    finalPath = finalPath.substring(0, finalPath.length - 1);
  }

  const basePath = `${apiRootUrl}${finalPath}`;

  if (isEmpty(queryParams)) return basePath;

  return `${basePath}?${queryString.stringify(queryParams)}`;
};
