import React from 'react';
import { secondsToHms } from '@voomly/utils';
import styles from './chapters.module.sass';

interface IProps {
  seek: (time: number) => void;
  title: string;
  timestampSecond: number;
}

class ChapterLine extends React.PureComponent<IProps> {
  private seek = () => {
    this.props.seek(this.props.timestampSecond);
  };

  public render() {
    const { title, timestampSecond } = this.props;
    return (
      <div className={styles.chapterLine} onClick={this.seek}>
        <div>{title}</div>
        <div>{secondsToHms(timestampSecond)}</div>
      </div>
    );
  }
}

export default ChapterLine;
