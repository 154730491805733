import * as React from 'react';

import { BaseAnnotationItem, IAnnotationItemProps } from './BaseAnnotation';
import appearAnimationHoc from './appearAnimationHoc';
import styled from 'styled-components';
import highlightItemHoc from '../playerHocs/highlightItemHoc';

const AnnotationStyled = styled(BaseAnnotationItem)<{ isClickable: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

const Component = (props: IAnnotationItemProps) => {
  return (
    <AnnotationStyled
      isClickable={props.item.actionOnClick.type !== 'doNothing'}
      {...props}
    />
  );
};

export const PlayerAnnotationItem = appearAnimationHoc()(
  highlightItemHoc()(Component)
);
