import * as React from 'react';
import { memo, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { actions } from '../../store/containerDimensions';
import { useResizableRatio } from '../../hooks/useResizableRatio';
import { IRatio } from '../../types/player';
import { getIsCursorVisible } from '../../store/videoControls/selectors';
import {
  selectIsFullScreen,
  selectVideoHeight,
  selectVideoWidth,
} from '../../store/containerDimensions/selectors';
import styles from './videoLayout.module.sass';

const VideoLayoutComponent = ({
  videoRatio,
  children,
}: {
  videoRatio: IRatio;
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const isFullScreen = useSelector(selectIsFullScreen);
  const videoDimensionsWidth = useSelector(selectVideoWidth);
  const videoDimensionsHeight = useSelector(selectVideoHeight);
  const isCursorVisible = useSelector(getIsCursorVisible);
  const [ref, { width, height }, isReady] = useResizableRatio({
    name: 'video',
    ratio: videoRatio,
    isFullScreen,
  });

  useLayoutEffect(() => {
    if (!width || !height) return;

    dispatch(actions.setVideoDimensions({ width, height }));
  }, [dispatch, width, height]);

  return (
    <div
      ref={ref}
      className={cx({
        [styles.ready]: isReady,
        [styles.hideCursor]: !isCursorVisible,
      })}
      style={{ width: videoDimensionsWidth, height: videoDimensionsHeight }}
    >
      {children}
    </div>
  );
};

export const VideoLayout = memo(VideoLayoutComponent);
