import { IGeneralVideoControlInterface } from '../components/Video/videoControlInterface';

// Promise.race isn't that widely supported, so writing this in vanilla JS
export const forceVideoBufferingWithMinDelay = async (
  videoApi: IGeneralVideoControlInterface,
  fromSecond: number,
  minDelay = 500
) => {
  return new Promise((resolve) => {
    const startedAt = new Date().getTime();
    const isMuted = videoApi.getMuted();
    videoApi.pauseReportingVolumeChanges();
    videoApi.setMuted(true);

    videoApi.seekAndBuffer(
      fromSecond,
      () => {
        const timeDiff = new Date().getTime() - startedAt;
        setTimeout(() => {
          if (!isMuted) {
            videoApi.setMuted(false);
          }
          videoApi.resumeReportingVolumeChanges();
          resolve(undefined);
        }, Math.max(minDelay - timeDiff, 0));
      },
      true
    );
  });
};
