import { channel, Channel } from 'redux-saga';
import { delay, fork } from 'redux-saga/effects';
import { getIsAnalyticsTrackingAllowed } from '../sourceConfiguration/selectors';
import { listenLatest } from '../utils';
import { eventsConverterSaga } from './eventsConverterSaga';
import {
  eventsSenderSaga,
  ISessionEventsWithConfig,
} from './eventsSender/eventsSenderSaga';
import { pageViewSenderSaga } from './eventsSender/pageViewSender';
import { eventsTrackerSaga } from './eventsTrackerSaga';
import { IInternalAnalyticEvent } from './types';

function* startAnalyticsTracking(
  isTrackingAllowed: ReturnType<typeof getIsAnalyticsTrackingAllowed>
) {
  if (!isTrackingAllowed) return;

  // eventsTrackerSaga will emit events to this channel, eventsConverterSaga will listen for them
  const internalEventsCh: Channel<IInternalAnalyticEvent> = yield channel();

  // eventsConverterSaga will emit events to this channel, eventsSenderSaga will listen for them
  const apiAnalyticsEventsCh: Channel<ISessionEventsWithConfig> =
    yield channel();

  yield fork(eventsTrackerSaga, internalEventsCh);

  // It converts internal raw events to that should be sent to api
  yield fork(eventsConverterSaga, internalEventsCh, apiAnalyticsEventsCh);

  // We will add the page view event, but after a short delay. Player might need some time
  // to configure itself and just overall, we shouldn't track page view if page is instantly closed
  yield delay(500);

  yield fork(eventsSenderSaga, apiAnalyticsEventsCh);
  yield fork(pageViewSenderSaga);
}

export function* analyticsRootSaga() {
  yield listenLatest(getIsAnalyticsTrackingAllowed, startAnalyticsTracking);
}
