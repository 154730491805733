import * as React from 'react';
import { IVideoItemSharedProps } from './sharedTypes';
import { ComponentsToUseContext } from './contextProviders/context';
import {
  ITimelineItem,
  ITimelineItemBase,
  ITimelineItemClickDescriptor,
  ITimelineItemDismissDescriptor,
} from '../../types/types';
import {
  detachableStoreHoc,
  IDetachableInnerProps,
} from './detachableStoreHoc';

interface IOwnProps {
  onTimelineItemClick: (item: ITimelineItemClickDescriptor) => void;
  onTimelineItemDismiss: (
    item: ITimelineItemDismissDescriptor,
    remember?: boolean
  ) => void;
  animationState: string;
}

export type ExtractType<Item, Type> = Item extends { type: Type }
  ? Item
  : never;

const extractTimelineItemType = <
  T extends ITimelineItem | ITimelineItemBase,
  P extends ITimelineItem['type']
>(
  item: T,
  type: P
): item is ExtractType<T, P> => (item.type as ITimelineItem['type']) === type;

class VideoComponentRendererComp extends React.PureComponent<
  IDetachableInnerProps<ITimelineItem> & IVideoItemSharedProps & IOwnProps
> {
  private itemCopy?: ITimelineItem;
  public render() {
    const {
      item: originalItem,
      onTimelineItemClick,
      onTimelineItemDismiss,
      ...rest
    } = this.props;
    // Why: when funnel changes video to the next one, originalItem becomes `undefined`
    // (because other video has no such item). But we need it for 1 more second to play the
    // exit animation
    if (originalItem) this.itemCopy = originalItem;
    const item = originalItem || this.itemCopy;
    if (!item) {
      return null;
    }

    return (
      <ComponentsToUseContext.Consumer>
        {(components) => {
          if (!components) {
            throw new Error(`Components context is not specified!`);
          }

          let renderer: React.ReactElement | null;
          if (extractTimelineItemType(item, 'text')) {
            renderer = <components.PlainText {...rest} item={item} />;
          } else if (extractTimelineItemType(item, 'button')) {
            renderer = (
              <components.Button
                {...rest}
                item={item}
                onClick={onTimelineItemClick}
                key={item.id}
              />
            );
          } else if (extractTimelineItemType(item, 'annotation')) {
            renderer = (
              <components.Annotation
                {...rest}
                item={item}
                onDismiss={onTimelineItemDismiss}
                onClick={onTimelineItemClick}
              />
            );
          } else if (extractTimelineItemType(item, 'imageAnnotation')) {
            renderer = (
              <components.ImageAnnotation
                {...rest}
                item={item}
                onDismiss={onTimelineItemDismiss}
                onClick={onTimelineItemClick}
              />
            );
          } else if (extractTimelineItemType(item, 'shareGate')) {
            renderer = (
              <components.ShareGate
                {...rest}
                item={item}
                onDismiss={onTimelineItemDismiss}
              />
            );
          } else if (extractTimelineItemType(item, 'turnstile')) {
            renderer = (
              <components.Turnstile
                {...rest}
                item={item}
                onDismiss={onTimelineItemDismiss}
              />
            );
          } else if (extractTimelineItemType(item, 'image')) {
            renderer = (
              <components.Image
                {...rest}
                item={item}
                onClick={onTimelineItemClick}
              />
            );
          } else if (extractTimelineItemType(item, 'grid')) {
            renderer = (
              <components.Grid
                {...rest}
                item={item}
                onButtonClick={onTimelineItemClick}
              />
            );
          } else if (extractTimelineItemType(item, 'survey')) {
            renderer = (
              <components.Survey
                {...rest}
                item={item}
                onButtonClick={onTimelineItemClick}
              />
            );
          } else {
            console.log('Do not know how to render', item.type);
            renderer = null;
          }

          return renderer;
        }}
      </ComponentsToUseContext.Consumer>
    );
  }
}

export const VideoComponentRenderer = detachableStoreHoc(
  VideoComponentRendererComp
);
