import { createStandardAction } from 'typesafe-actions';
import { ISourceConfiguration } from '../../components/Player/PlayerContainer';

export const setSourceConfiguration = createStandardAction(
  'sourceConfiguration/SET'
)<ISourceConfiguration>();

export const preloadNextVideo = createStandardAction(
  'sourceConfiguration/PRELOAD_NEXT'
)<ISourceConfiguration>();

export const promoteNextVideoToCurrent = createStandardAction(
  'sourceConfiguration/clearPreloadQueue'
)();

export const newSourceConfigurationEmitted = createStandardAction(
  'sourceConfiguration/newSourceConfigurationEmitted'
)<ISourceConfiguration>();

export const registerCallbacks = createStandardAction(
  'sourceConfiguration/REGISTER_CALLBACKS'
)<{
  onTimeChange?: (currentTime: number) => void;
  onNavigateToOtherNodeRequest?: (nodeId: string, timestamp?: number) => void;
  onVideoFinished?: () => void;
}>();

export const requestNavigateToOtherNode = createStandardAction(
  'sourceConfiguration/REQUEST_NAVIGATE_TO_OTHER_NODE'
)<{
  nodeId: string;
  timestamp?: number;
  muted: boolean;
  volume: number;
}>();
