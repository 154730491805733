import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { isIOS } from '@voomly/utils';

interface IDimensions {
  width: number;
  height: number;
}
interface IStateShape {
  containerDimensions: IDimensions;
  videoDimensions: IDimensions;
  forceAspectRatio: number | undefined;
  isFullScreen: boolean;
  isNativeFullScreenAvailabe: boolean;
}
const initialState: IStateShape = {
  // Dimensions of the WHOLE player, including bottom CTA buttons etc
  //
  // Don't set default size, because it causes flickering from 1920 to real container size
  // containerDimensions: { width: 1920, height: 1080 },
  containerDimensions: { width: 0, height: 0 },
  videoDimensions: { width: 0, height: 0 },

  //
  isFullScreen: false,
  isNativeFullScreenAvailabe: !isIOS,
  // For funnels: when the player plays multiple videos, they may have
  // different aspect ratios: that would make the player "shrink" and "expand"
  // accordingly, based on current video's width and height.
  // That's nice, but not the desired behaviour for funnels.
  forceAspectRatio: undefined,
};

type IAction = ActionType<typeof actions>;
export default function reducer(
  state = initialState,
  action: IAction
): IStateShape {
  switch (action.type) {
    case getType(actions.setContainerDimensions):
      return { ...state, containerDimensions: action.payload };

    case getType(actions.setVideoDimensions):
      return { ...state, videoDimensions: action.payload };

    case getType(actions.setIsFullScreen):
      return {
        ...state,
        isFullScreen: action.payload,
      };

    case getType(actions.setForceAspectRatio):
      return { ...state, forceAspectRatio: action.payload };
    case getType(actions.toggleFullScreen):
      return {
        ...state,
        isFullScreen: !state.isFullScreen,
      };

    default:
      return state;
  }
}
