let observer: MutationObserver | null = null;

const removeOperaPiP = () => {
  if (observer) return;
  if (!navigator.userAgent.indexOf('Opera')) return;
  if (!('MutationObserver' in window)) return;

  const callback = (allmutations) => {
    allmutations.forEach((mr) => {
      if (mr.type !== 'childList') return;
      if (mr.addedNodes && mr.addedNodes.length > 0) {
        const potentialPopOutButton = mr.addedNodes[0];
        if (!potentialPopOutButton || !potentialPopOutButton.shadowRoot) return;

        const childNodes = Array.from(
          potentialPopOutButton.shadowRoot
            .childNodes as any as Array<HTMLElement>
        );
        childNodes.forEach((childNode) => {
          if (childNode.nodeName === 'STYLE') return;
          const popOutButton =
            childNode.querySelector<HTMLElement>('.button-container');
          if (popOutButton) {
            popOutButton.style.display = 'none';
            console.log('Opera - popOutButton/pictureInPicture disabled!');
          }
        });
      }
    });
  };
  observer = new MutationObserver(callback);
  const html = document.getElementsByTagName('html');
  if (html && html.length > 0) observer.observe(html[0], { childList: true });
};

export default removeOperaPiP;
