import iphone from './images/iphone.png';
import iphoneWebp from './images/iphone.webp';
import macbook from './images/macbook.png';
import macbookWebp from './images/macbook.webp';
import skin1 from './images/skin-01.png';
import skin1Webp from './images/skin-01.webp';
import skin2 from './images/skin-02.png';
import skin2Webp from './images/skin-02.webp';
import skin3 from './images/skin-03.png';
import skin3Webp from './images/skin-03.webp';
import skin4 from './images/skin-04.png';
import skin4Webp from './images/skin-04.webp';
import skin5 from './images/skin-05.png';
import skin5Webp from './images/skin-05.webp';
import skin6 from './images/skin-06.png';
import skin6Webp from './images/skin-06.webp';
import skin7 from './images/skin-07.png';
import skin7Webp from './images/skin-07.webp';
import skin8 from './images/skin-08.png';
import skin8Webp from './images/skin-08.webp';
import skin9 from './images/skin-09.png';
import skin9Webp from './images/skin-09.webp';
import skin10 from './images/skin-10.png';
import skin10Webp from './images/skin-10.webp';

export interface IGutter {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface IBorderSkin {
  key: string;
  label: string;
  image: { original: string; webp: string };
  width: number;
  height: number;
  gutter: IGutter;
  borderRadius?: number;
}

export const findBorderSkin = (borderSkinKey: IBorderSkin['key']) => {
  const defaultSkin = borderSkins.find(({ key }) => key === 'iphone');

  if (!defaultSkin) {
    throw new Error(
      `Something went wrong. Could not find default border skin.`
    );
  }

  if (!borderSkinKey) {
    console.log('No border skin key, used default');
    return defaultSkin;
  }

  const skin = borderSkins.find((x) => x.key === borderSkinKey);

  if (!skin) {
    console.log(
      `No border skin found for the key ${borderSkinKey}, used default`
    );
    return defaultSkin;
  }

  return skin;
};

// NOTE: don't change order!!! It is ordered based on user plan
export const borderSkins: IBorderSkin[] = [
  {
    key: 'iphone',
    label: 'iPhone',
    image: { original: iphone, webp: iphoneWebp },
    width: 2590,
    height: 1295,
    // borderRadius: 6,
    borderRadius: 0,
    gutter: {
      // Close to corners
      // top: 3.23,
      // right: 2.95,
      // bottom: 3.19,
      // left: 2.89,

      // Far from corners, because controls are overlapping by iPhone corners
      top: 3.2,
      right: 6.2,
      bottom: 3.19,
      left: 6.99,
    },
  },
  {
    key: 'macbook',
    label: 'Macbook',
    image: { original: macbook, webp: macbookWebp },
    width: 3154,
    height: 1848,
    gutter: {
      top: 3.4,
      right: 10.4,
      bottom: 5.6,
      left: 10.2,
    },
  },
  {
    key: 'skin1',
    label: 'Skin 1',
    image: { original: skin1, webp: skin1Webp },
    width: 1000,
    height: 576,
    gutter: {
      top: 1.9,
      right: 2.3,
      bottom: 1.9,
      left: 2.4,
    },
  },
  {
    key: 'skin2',
    label: 'Skin 2',
    image: { original: skin2, webp: skin2Webp },
    width: 1006,
    height: 582,
    gutter: {
      top: 0.9,
      right: 1.4,
      bottom: 0.9,
      left: 1.1,
    },
  },
  {
    key: 'skin3',
    label: 'Skin 3',
    image: { original: skin3, webp: skin3Webp },
    width: 1010,
    height: 586,
    gutter: {
      top: 1.9,
      right: 3.3,
      bottom: 3.0,
      left: 2.4,
    },
  },
  {
    key: 'skin4',
    label: 'Skin 4',
    image: { original: skin4, webp: skin4Webp },
    width: 1001,
    height: 576,
    gutter: {
      top: 2.4,
      right: 4.3,
      bottom: 3.5,
      left: 4.3,
    },
  },
  {
    key: 'skin5',
    label: 'Skin 5',
    image: { original: skin5, webp: skin5Webp },
    width: 1002,
    height: 578,
    gutter: {
      top: 2,
      right: 2.3,
      bottom: 2.1,
      left: 2.5,
    },
  },
  {
    key: 'skin6',
    label: 'Skin 6',
    image: { original: skin6, webp: skin6Webp },
    width: 1000,
    height: 576,
    gutter: {
      top: 1.8,
      right: 2.2,
      bottom: 1.8,
      left: 2.2,
    },
  },
  {
    key: 'skin7',
    label: 'Skin 7',
    image: { original: skin7, webp: skin7Webp },
    width: 1004,
    height: 581,
    gutter: {
      top: 1.9,
      right: 2.5,
      bottom: 2.5,
      left: 2.5,
    },
  },
  {
    key: 'skin8',
    label: 'Skin 8',
    image: { original: skin8, webp: skin8Webp },
    width: 1000,
    height: 576,
    gutter: {
      top: 1.9,
      right: 2.3,
      bottom: 1.9,
      left: 2.3,
    },
  },
  {
    key: 'skin9',
    label: 'Skin 9',
    image: { original: skin9, webp: skin9Webp },
    width: 1000,
    height: 576,
    gutter: {
      top: 3.2,
      right: 5.5,
      bottom: 3.3,
      left: 3.6,
    },
  },
  {
    key: 'skin10',
    label: 'Skin 10',
    image: { original: skin10, webp: skin10Webp },
    width: 1000,
    height: 576,
    gutter: {
      top: 3.1,
      right: 3.3,
      bottom: 1.8,
      left: 3.3,
    },
  },
].map((skin) => {
  // HACK instead of making own loader for nextjs images
  // just transform it to non-nextjs format to fit player skins format
  // For reducing tech debt implement loader in future
  if (
    typeof skin.image.webp !== 'string' &&
    (skin.image.webp as { src: string }).src
  ) {
    skin.image.webp = (skin.image.webp as { src: string }).src;
  }
  if (
    typeof skin.image.original !== 'string' &&
    (skin.image.original as { src: string }).src
  ) {
    skin.image.original = (skin.image.original as { src: string }).src;
  }
  return skin;
});
