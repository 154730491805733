import { IPlayerRootReducerShape } from '../rootPlayerStore';

export const getIsThumbnailVisible = (state: IPlayerRootReducerShape) => {
  return state.thumbnail.isVisible;
};

export const getThumbnail = (state: IPlayerRootReducerShape) => {
  return state.thumbnail;
};

export const getThumbnailCustomVideo = (state: IPlayerRootReducerShape) => {
  return state.thumbnail.customVideo;
};
