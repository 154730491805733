import { isEqual } from 'lodash-es';
import { fork, put, select, take } from 'redux-saga/effects';
import { setForceAspectRatio } from '../../containerDimensions/actions';
import { getIsForceAspectRatio } from '../../containerDimensions/selectors';
import { setSourceConfiguration } from '../../sourceConfiguration/actions';
import {
  getIsVideoChanging,
  getPlaybackSettings,
} from '../../sourceConfiguration/selectors';
import { requestSeek } from '../actions';
import { getIsVideoReady } from '../selectors';

function* handlePlaybackSettingChange() {
  while (true) {
    const currentPlaybackSettings = getPlaybackSettings(yield select());
    const action: ReturnType<typeof setSourceConfiguration> = yield take(
      setSourceConfiguration
    );

    if (isEqual(action.payload.playbackSettings, currentPlaybackSettings))
      return;

    if (
      action.payload.playbackSettings.currentTime >= 0 &&
      getIsVideoReady(yield select()) &&
      !getIsVideoChanging(yield select())
    ) {
      yield put(
        requestSeek({
          type: 'toTime',
          initiatedBy: 'playWith',
          time: action.payload.playbackSettings.currentTime,
        })
      );
    }

    if (
      !getIsForceAspectRatio(yield select()) &&
      action.payload.playbackSettings.forceAspectRatio
    ) {
      yield put(
        setForceAspectRatio(action.payload.playbackSettings.forceAspectRatio)
      );
    }
  }
}

export function* playbackSettingsChangeSaga() {
  yield fork(handlePlaybackSettingChange);
}
