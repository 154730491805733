import { IFromPlayerApiEventName } from './emitters/FromPlayerEmitter';
import {
  toExternalEventName,
  VoomlyPayloadFromPlayer,
  VoomlyPlayerEventTypes,
} from './VoomlyPlayerEvent';
import {
  IToPlayerApiEventName,
  ToPlayerEventTypes,
} from './emitters/ToPlayerDispatcher';

export type VoomlyPayloadToPlayer<T extends IToPlayerApiEventName> =
  ToPlayerEventTypes[T];

/**
 * Message to send via postMessage to iframe
 */
export class VoomlyToPlayerMessage<T extends IToPlayerApiEventName> {
  public eventName: VoomlyPlayerEventTypes<T>;

  constructor(eventName: T, public payload: VoomlyPayloadToPlayer<T>) {
    this.eventName = toExternalEventName(eventName);
  }
}

/**
 * Message to send via postMessage from iframe
 */
export class VoomlyFromPlayerMessage<T extends IFromPlayerApiEventName> {
  public eventName: VoomlyPlayerEventTypes<T>;

  constructor(eventName: T, public payload: VoomlyPayloadFromPlayer<T>) {
    this.eventName = toExternalEventName(eventName);
  }
}
