import { DeepPartial } from 'ts-essentials';
import { difference, cloneDeep, pick } from 'lodash-es';
import {
  autofixPlayerChapters,
  autofixThumbnailItems,
  autofixTimelineItems,
} from './autofixTimeline';
import { Optional } from 'utility-types';
import {
  IPlayerForVideoSubtitlesItem,
  IPlayerTemplate,
  IPlayerWithVideo,
} from '../types/player';
import {
  DEFAULT_PLAYER_DATA,
  emptyPlayerConfig,
} from '../defaults/emptyPlayerConfig';
import { assertUnreachable } from '@voomly/utils';

const copyObjectOr = <T extends {}>(
  original: DeepPartial<T>,
  myDefault: T
): T => {
  const result = cloneDeep(myDefault);

  if (!original) return result;

  const defaultKeys = Object.keys(result);
  const originalKeys = Object.keys(original);
  const missingKeysInDefault = difference(originalKeys, defaultKeys);

  const extraData = pick(original, missingKeysInDefault);
  defaultKeys.forEach((key) => {
    if (typeof original[key] !== 'undefined') {
      result[key] = original[key];
    }
  });

  return {
    ...result,
    ...extraData,
  };
};

const autoFixActionOnEnd = (
  action: DeepPartial<IPlayerTemplate['general']['onEnd']> | undefined
): IPlayerTemplate['general']['onEnd'] => {
  const defaultValue = emptyPlayerConfig.general.onEnd;
  if (!action || !action.action) return cloneDeep(defaultValue);

  return action as IPlayerTemplate['general']['onEnd'];
};

const autofixPlayerGeneral = (
  general: DeepPartial<IPlayerTemplate['general']> | undefined
): IPlayerTemplate['general'] => {
  if (!general) return cloneDeep(emptyPlayerConfig.general);

  return {
    ...copyObjectOr<IPlayerTemplate['general']>(
      general,
      emptyPlayerConfig.general
    ),
    onEnd: autoFixActionOnEnd(general.onEnd),
  };
};

const autofixPlayerSkin = (
  skin: DeepPartial<IPlayerTemplate['skin']> | undefined
): IPlayerTemplate['skin'] => {
  const defaultValue = emptyPlayerConfig.skin;

  if (!skin) return cloneDeep(defaultValue);

  return {
    ...copyObjectOr<IPlayerTemplate['skin']>(skin, defaultValue),
    captions: { ...DEFAULT_PLAYER_DATA.skin.captions, ...skin.captions },
    customLogoUrl: skin.customLogoUrl,
    customUrl: skin.customUrl,
  };
};

type IServerThumb = {
  useCustom: boolean;
  frameTime?: number;
  // almost always will be populated. Can be undefined during generation (show spinner in that case)
  frameUrl: string | undefined;
  customUrl: string | undefined;
};

export const autofixPlayerThumbnail = (
  thumbnail:
    | DeepPartial<IPlayerWithVideo['thumbnail']>
    | IServerThumb
    | undefined
): IPlayerWithVideo['thumbnail'] => {
  if (!thumbnail) {
    return;
  }

  if ('useCustom' in thumbnail) {
    if (thumbnail.useCustom) {
      if (!thumbnail.customUrl) return;

      return {
        type: 'customImage',
        url: thumbnail.customUrl,
      };
    } else {
      if (!thumbnail.frameUrl) return;

      return {
        type: 'frameFromVideo',
        frameTime: thumbnail.frameTime ?? 0,
        url: thumbnail.frameUrl,
      };
    }
  } else {
    return thumbnail as IPlayerWithVideo['thumbnail'];
  }
};

export const autofixPlayerSecurity = (
  protection: DeepPartial<IPlayerTemplate['security']> | undefined
): IPlayerTemplate['security'] => {
  const defaultValue = emptyPlayerConfig.security;

  if (!protection) return cloneDeep(defaultValue);

  return {
    enabled: protection.enabled ?? defaultValue.enabled,
    password: protection.password,
    title: protection.title ?? defaultValue.title,
    enterPasswordText:
      protection.enterPasswordText || 'Please enter password to view video:',
    submitButtonText: protection.submitButtonText || 'Unlock',
    submitButtonLoadingText: protection.submitButtonLoadingText || 'Loading...',
    emptyPasswordErrorText:
      protection.emptyPasswordErrorText || 'Password is a required field',
    wrongPasswordErrorText:
      protection.wrongPasswordErrorText || 'Wrong password',
  };
};

const autofixPlayerControls = (
  controls: DeepPartial<IPlayerTemplate['controls']> | undefined
): IPlayerTemplate['controls'] => {
  const defaultValue = emptyPlayerConfig.controls;

  if (!controls) return cloneDeep(defaultValue);

  return {
    enableSoundButtonLocation: 'top-right',
    customPlayButtonUrl: controls.customPlayButtonUrl,
    ...copyObjectOr<
      Optional<
        IPlayerTemplate['controls'],
        'enableSoundButtonLocation' | 'customPlayButtonUrl'
      >
    >(controls, defaultValue),
  };
};

export const autofixShareVideo = (
  shareVideo:
    | DeepPartial<IPlayerWithVideo['shareVideo'] | undefined>
    | undefined
): IPlayerWithVideo['shareVideo'] => {
  return {
    allowComments: false,
    logoInHeader: 'voomlyLogo',
    customLogoUrl: undefined,
    pageTheme: 'systemTheme',
    ...shareVideo,
  };
};

const autofixPlayerSubtitlesItems = (
  items: Array<DeepPartial<IPlayerForVideoSubtitlesItem>> | undefined
): IPlayerForVideoSubtitlesItem[] => {
  if (!items) return [];

  return items
    .map((item) => {
      if (!item.subtitlesId) {
        console.error('Subtitles item without subtitlesId', item);
      }

      return {
        ...item,
        isAutogenerated: item.isAutogenerated ?? false,
      };
    })
    .filter((item) => !!item.subtitlesId) as IPlayerForVideoSubtitlesItem[];
};

export const autofixPlayerSubtitles = (
  subtitles: DeepPartial<IPlayerWithVideo['subtitles']> | undefined
): IPlayerWithVideo['subtitles'] => {
  return {
    defaultSubtitlesId: subtitles?.defaultSubtitlesId,
    items: autofixPlayerSubtitlesItems(subtitles?.items),
  };
};

export const autofixPlayerFloatingModal = (
  floatingModal: DeepPartial<IPlayerWithVideo['floatingModal']> | undefined
): IPlayerWithVideo['floatingModal'] => {
  const defaultValue = emptyPlayerConfig.floatingModal;
  if (!floatingModal) {
    return cloneDeep(defaultValue);
  }

  return {
    ...copyObjectOr<IPlayerWithVideo['floatingModal']>(
      floatingModal,
      defaultValue
    ),
  };
};

export const autofixPlayerOpenInModal = (
  openInModal: DeepPartial<IPlayerWithVideo['openInModal']> | undefined
): IPlayerWithVideo['openInModal'] => {
  const defaultValue = emptyPlayerConfig.openInModal;
  if (!openInModal) {
    return cloneDeep(defaultValue);
  }

  return {
    ...copyObjectOr<IPlayerWithVideo['openInModal']>(openInModal, defaultValue),
  };
};

export const autofixPlayerTemplateConfig = (
  playerConfig: DeepPartial<IPlayerTemplate> | undefined
): IPlayerTemplate => {
  if (!playerConfig) {
    return cloneDeep(emptyPlayerConfig);
  }

  return {
    name: playerConfig.name ?? 'untitled player',
    createdAt: playerConfig.createdAt ?? '2010-01-01T00:00:00.000Z',
    updatedAt: playerConfig.updatedAt ?? '2010-01-01T00:00:00.000Z',
    deletedAt: playerConfig.deletedAt ?? '2010-01-01T00:00:00.000Z',

    general: autofixPlayerGeneral(playerConfig.general),
    skin: autofixPlayerSkin(playerConfig.skin),
    controls: autofixPlayerControls(playerConfig.controls),
    security: autofixPlayerSecurity(playerConfig.security),
    floatingModal: autofixPlayerFloatingModal(playerConfig.floatingModal),
    openInModal: autofixPlayerOpenInModal(playerConfig.openInModal),
  };
};

export const autofixAttachedPlayerTemplates = (
  attachedPlayerTemplates:
    | DeepPartial<IPlayerWithVideo['attachedPlayerTemplates']>
    | undefined
): IPlayerWithVideo['attachedPlayerTemplates'] => {
  if (!attachedPlayerTemplates) {
    return [];
  }

  return attachedPlayerTemplates.filter((item) => {
    if (!item?.section) return false;
    if (!item?.playerTemplateId) return false;

    switch (item.section) {
      case 'general':
      case 'skin':
      case 'controls':
      case 'security':
      case 'floatingModal':
      case 'openInModal':
        return true;
      default:
        assertUnreachable(item.section);
    }

    return true;
  }) as IPlayerWithVideo['attachedPlayerTemplates'];
};

export const autofixPlayerWithVideoConfig = (
  playerConfig: DeepPartial<IPlayerWithVideo>
): IPlayerWithVideo => {
  const basePlayer = autofixPlayerTemplateConfig(playerConfig);

  if (!playerConfig.videoId) {
    throw new Error('videoId is required');
  }

  return {
    ...basePlayer,
    videoId: playerConfig.videoId,
    timelineItems: autofixTimelineItems(playerConfig.timelineItems),
    thumbnailItems: autofixThumbnailItems(playerConfig.thumbnailItems),
    thumbnail: autofixPlayerThumbnail(playerConfig.thumbnail),
    chapters: autofixPlayerChapters(playerConfig.chapters),
    shareVideo: autofixShareVideo(playerConfig.shareVideo),
    subtitles: autofixPlayerSubtitles(playerConfig.subtitles),
    attachedPlayerTemplates: autofixAttachedPlayerTemplates(
      playerConfig.attachedPlayerTemplates
    ),
  };
};
