import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import * as videoStateActions from '../videoState/actions';
import { IAskToResumeOnVisitOverlay } from './types';

export interface IAskToResumeOverlay {
  visibleAskToResumeOverlay?: IAskToResumeOnVisitOverlay;
}

const initialState: IAskToResumeOverlay = {};

type IAction = ActionType<typeof actions | typeof videoStateActions>;
const reducer = (
  state: IAskToResumeOverlay = initialState,
  action: IAction
): IAskToResumeOverlay => {
  switch (action.type) {
    case getType(videoStateActions.playRequested):
      return {
        visibleAskToResumeOverlay: undefined,
      };
    case getType(actions.updateAskToResumeOnVisitOverlay):
      return {
        ...state,
        visibleAskToResumeOverlay: action.payload,
      };
  }

  return state;
};

export default reducer;
