import * as React from 'react';
import styled from 'styled-components';
import appearAnimationHoc from '../Annotation/appearAnimationHoc';
import { ImageAnnotationItem, IImageAnnotationItemProps } from './Base';
import highlightItemHoc from '../playerHocs/highlightItemHoc';

const ImageAnnotationItemStyled = styled(ImageAnnotationItem)<{
  isClickable: boolean;
}>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`;

const Component = (props: IImageAnnotationItemProps) => {
  return (
    <ImageAnnotationItemStyled
      isClickable={props.item.actionOnClick.type !== 'doNothing'}
      {...props}
    />
  );
};

export const PlayerImageAnnotationItem = appearAnimationHoc()(
  highlightItemHoc()(Component)
);
