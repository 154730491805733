import { fork, put, select, take } from 'redux-saga/effects';
import {
  getIsVideoChanging,
  getNextSourceConfigurationTime,
} from '../../sourceConfiguration/selectors';
import {
  requestPause,
  requestPlay,
  requestSeek,
} from '../../videoState/actions';
import { getCurrentTime, getIsVideoPlaying } from '../../videoState/selectors';
import { waitForChange } from '../../utils';
import { getFirstBlockingSecond, getIsPlaybackBlocked } from '../../selectors';

function* handleBlock() {
  while (true) {
    yield take();

    if (getIsVideoChanging(yield select())) continue;

    const blockingSecond = getFirstBlockingSecond(yield select());

    const time =
      getNextSourceConfigurationTime(yield select()) ??
      getCurrentTime(yield select());

    if (blockingSecond === undefined || time < blockingSecond) {
      continue;
    }

    if (getIsVideoPlaying(yield select())) {
      yield put(requestPause({}));
    }

    if (Math.abs(time - blockingSecond) > 1) {
      yield put(requestSeek({ time: blockingSecond, type: 'toTime' }));
    }
  }
}

function* resumePlaying() {
  while (true) {
    const isPlaybackBlocked = yield waitForChange(getIsPlaybackBlocked);

    if (!isPlaybackBlocked) {
      yield put(requestPlay({}));
    }
  }
}
export function* playBlockerSaga() {
  yield fork(handleBlock);
  yield fork(resumePlaying);
}
