import { isEqual } from 'lodash-es';
import { Task } from 'redux-saga';
import {
  cancel,
  fork,
  HelperWorkerParameters,
  select,
  take,
} from 'redux-saga/effects';

export type DistributiveOmit<T, K extends keyof any> = T extends any
  ? Omit<T, K>
  : never;

export function* waitForChangeWithValue<T>(
  currentValue: T,
  selector: (s: any) => T
) {
  while (true) {
    yield take();

    const res = selector(yield select());

    if (isEqual(res, currentValue)) continue;

    return res;
  }
}
export function* waitForChange<T>(selector: (s: any) => T) {
  const currentValue = selector(yield select());

  while (true) {
    yield take();

    const newValue = selector(yield select());
    if (currentValue !== newValue) {
      return newValue;
    }
  }
}

export function* waitForChangeWithDiff<T>(selector: (s: any) => T) {
  const currentValue = selector(yield select());

  while (true) {
    yield take();

    const newValue = selector(yield select());
    if (currentValue !== newValue) {
      return [currentValue, newValue];
    }
  }
}

// export function* waitToEqual<T>(selector: (s: any) => T, toEqual: T) {
//   const currentValue = selector(yield select());

//   if (currentValue === toEqual) return;

//   while (true) {
//     yield take();

//     const currentValue = selector(yield select());

//     if (currentValue === toEqual) return;
//   }
// }

// It woks like takeLatest but with selectors
//
// Actually such pattern is note preferred by redux-saga community
// https://github.com/redux-saga/redux-saga/issues/547#issuecomment-247364119
// But I still not sure how should we track that state changed? It is not always
// possible to determine based on actions what should we do. Only maybe via mapping one UI action to another internal business logic action(like at https://medium.com/@marcelschulze/using-react-native-with-redux-and-redux-saga-a-new-proposal-ba71f151546f#.mpuwlwfeo )
//
// And the redux way is about listening of the state(like react components do), not the actions.
// Cause we can always miss to define which action saga should listen
//
// Such way is not against this:
// > If you do things properly decoupled, you could switch to backbone and reuse the exact same unmodified sagas.
// And we are not coupled too heavy to the redux store if we will be using selectors.
// Also, another potential solution - use such helper only on the root sagas, and not in child sagas
//
// And another potential way to solve this - listen all actions, and map them to saga internal actions https://medium.com/@marcelschulze/using-react-native-with-redux-and-redux-saga-a-new-proposal-ba71f151546f#.mpuwlwfeo .
// But I think it will give too much boilerplate code
export function listenLatest<T, Fn extends (...args: any[]) => any>(
  selector: (s: any) => T,
  fn: Fn,
  ...args: HelperWorkerParameters<T, Fn>
) {
  return fork(function* () {
    let currentValue = selector(yield select());

    let currentTask: Task = yield fork(
      fn as any,
      ...args,
      selector(yield select())
    );

    while (true) {
      yield take();

      const newValue = selector(yield select());

      if (isEqual(currentValue, newValue)) continue;

      yield cancel(currentTask);

      currentTask = yield fork(fn as any, ...args, selector(yield select()));
      currentValue = newValue;
    }
  });
}
