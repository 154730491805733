import { IPlayerRootReducerShape } from '../rootPlayerStore';

export const subtitlesIsVisibleSelector = (state: IPlayerRootReducerShape) => {
  return state.subtitles.isVisible;
};

export const currentSubtitlesNameSelector = (
  state: IPlayerRootReducerShape
) => {
  return state.subtitles.currentSubtitlesName;
};
