export class VideoStateForLoaderListener {
  private delayTimeoutHandle?: ReturnType<typeof setTimeout>;
  private checkIntervalHandle?: ReturnType<typeof setInterval>;

  private listener?: (isBuffering: boolean) => void;

  private isBuffering = (videoState: number) => {
    return videoState === 2 || videoState === 1;
  };

  private clearCheckInterval = () => {
    this.checkIntervalHandle && clearInterval(this.checkIntervalHandle);
    this.checkIntervalHandle = undefined;
  };

  private clearDelayTimeout = () => {
    this.delayTimeoutHandle && clearTimeout(this.delayTimeoutHandle);
    this.delayTimeoutHandle = undefined;
  };

  public listen = (cb: (isBuffering: boolean) => void) => {
    this.listener = cb;
  };

  public notify = (videoStateChecker: () => number) => {
    if (this.isBuffering(videoStateChecker())) {
      // Don't show loader right away, do it after short delay
      this.delayTimeoutHandle = setTimeout(() => {
        if (!this.delayTimeoutHandle) {
          return;
        }

        this.clearDelayTimeout();

        this.listener?.(true);

        // Check if the loader should be switched off
        // if video is not buffering anymore
        setInterval(() => {
          if (this.isBuffering(videoStateChecker())) {
            return;
          }

          this.clearCheckInterval();
          this.listener?.(false);
        }, 1000);
      }, 500);
    } else {
      this.clearDelayTimeout();
      this.clearCheckInterval();

      this.listener?.(false);
    }
  };
}
