// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@nrwl/web/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popoverTip_root__1K0Sx{position:absolute;top:100%;left:50%;transform:translateX(-50%);height:0;width:0;border:solid transparent;border-top-color:currentColor;border-width:5px;pointer-events:none}", "",{"version":3,"sources":["webpack://./../../libs/player/src/lib/components/Controls/PopoverTip/popoverTip.module.sass"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CACA,QAAA,CACA,QAAA,CACA,0BAAA,CACA,QAAA,CACA,OAAA,CACA,wBAAA,CACA,6BAAA,CACA,gBAAA,CACA,mBAAA","sourcesContent":[".root\n  position: absolute\n  top: 100%\n  left: 50%\n  transform: translateX(-50%)\n  height: 0\n  width: 0\n  border: solid transparent\n  border-top-color: currentColor\n  border-width: 5px\n  pointer-events: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "popoverTip_root__1K0Sx"
};
export default ___CSS_LOADER_EXPORT___;
