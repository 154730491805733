import { IPlayerRootReducerShape } from '../rootPlayerStore';

const DEFAULT_VIDEO_ASPECT_RATIO = 16 / 9;

export const getVideoAspectRatio = (
  file: { metadata: { video: { width: number; height: number } } } | undefined
) => {
  if (!file) {
    return DEFAULT_VIDEO_ASPECT_RATIO;
  }

  return file.metadata.video.width / file.metadata.video.height;
};

// that's the width of the video in the timeline editor. Can be any number really
export const EDITOR_WIDTH = 530;

export const selectScale = (videoWidth: number): number => {
  // if the video is 8K then, probably, it's going to be played
  // on a smaller screen than 8K anyway, but buttons would get rendered as for
  // 8K sized player. Thus, we need to shrink it accordingly.
  return videoWidth / EDITOR_WIDTH;
};

export const getIsForceAspectRatio = (state: IPlayerRootReducerShape) => {
  return state.dimensions.forceAspectRatio;
};

export const selectIsFullScreen = (state: IPlayerRootReducerShape) =>
  state.dimensions.isFullScreen;

// Container dimensions
export const selectContainerWidth = (state: IPlayerRootReducerShape) =>
  state.dimensions.containerDimensions.width;
export const selectContainerHeight = (state: IPlayerRootReducerShape) =>
  state.dimensions.containerDimensions.height;

// Video dimensions
export const selectVideoWidth = (state: IPlayerRootReducerShape) =>
  state.dimensions.videoDimensions.width;
export const selectVideoHeight = (state: IPlayerRootReducerShape) =>
  state.dimensions.videoDimensions.height;

export const selectButtonsScale = (state: IPlayerRootReducerShape) =>
  selectScale(selectVideoWidth(state));

export const selectContainerButtonsScale = (state: IPlayerRootReducerShape) =>
  selectScale(selectContainerWidth(state));
