import * as React from 'react';
import { createPlainTextItemStyled, IPlainTextItemProps } from './Base';
import positionHoc from '../playerHocs/positionHoc';
import { appearAnimationHoc } from '../playerHocs/appearAnimationHoc';
import styled from 'styled-components';
import highlightItemHoc from '../playerHocs/highlightItemHoc';

const Block = createPlainTextItemStyled(styled.div);

const UnEditablePlainText = ({
  onWrapperMouseDown,
  onWrapperDoubleClick,
  ...props
}: IPlainTextItemProps) => <Block {...props}>{props.item.label}</Block>;

export const PlayerPlainTextItem = appearAnimationHoc()(
  positionHoc({ isInteractable: false })(
    highlightItemHoc()(UnEditablePlainText)
  )
);
