import * as React from 'react';

interface IProps
  extends Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'rel' | 'target'
  > {
  openInNewTab?: boolean;
}

// This component is used to have default values for 'rel' and 'target' props

export const A = ({ openInNewTab, ...props }: IProps) => {
  return (
    <a
      {...props}
      target={openInNewTab ? '_blank' : '_top'}
      // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
      rel="nofollow noopener noreferrer"
    >
      {props.children}
    </a>
  );
};
