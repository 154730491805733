import * as React from 'react';
import { IVideoItemSharedProps } from '../sharedTypes';
import { VideoItemCommonButton as ButtonWrapper } from '../shared/commonComponents';
import { ITimelineButton } from '../../../types/types';
import { IDetachableInnerProps } from '../detachableStoreHoc';

export type IBaseButtonItemProps = IDetachableInnerProps<ITimelineButton> &
  IVideoItemSharedProps & {
    onClick: (item: ITimelineButton) => void;
    className?: string;
  };

export class BaseItemButton extends React.PureComponent<
  IBaseButtonItemProps,
  {}
> {
  private handleOnClick = () => {
    const { onClick, item } = this.props;

    if (onClick) {
      onClick(item);
    }
  };

  public render() {
    const { item, buttonsScale, className } = this.props;

    return (
      <ButtonWrapper
        {...item}
        className={className}
        buttonsScale={buttonsScale}
        onClick={this.handleOnClick}
      >
        {item.label}
      </ButtonWrapper>
    );
  }
}
