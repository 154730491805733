import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';

const useIsMounted = (): (() => boolean) => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return function cleanup(): void {
      isMounted.current = false;
    };
  }, []);
  return useCallback((): boolean => {
    return isMounted.current;
  }, []);
};

type IErrorState<T> = Partial<Record<keyof T, string> & { base?: string }>;
type IErrorKeys<T> = keyof IErrorState<T>;

export const useTinyForm = <T extends Record<string, unknown>>(
  initial: T,
  submit: (arg: {
    values: T;
    setError: (key: IErrorKeys<T>, val: string) => void;
    resetFields: () => void;
  }) => Promise<unknown>
) => {
  const isMounted = useIsMounted();

  const [errors, setErrors] = useState<IErrorState<T>>({});
  const [values, setValues] = useState<T>(initial);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const setError = useCallback(
    (key: IErrorKeys<T>, val: unknown) => {
      setErrors({ ...errors, [key]: val });
    },
    [errors]
  );

  const resetErrors = useCallback(() => {
    setErrors({});
  }, []);

  const setValue = useCallback(
    <K extends keyof T>(key: K, val: T[K]) => {
      setValues({ ...values, [key]: val });
    },
    [values]
  );

  const submitRef = useRef(submit);

  useEffect(() => {
    submitRef.current = submit;
  }, [submit]);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();

        resetErrors();
        setIsSubmitting(true);

        await submitRef.current({
          values: values,
          setError: setError,
          resetFields: () => setValues(initial),
        });
      } finally {
        if (isMounted()) {
          setIsSubmitting(false);
        }
      }
    },
    [values, initial, isMounted, resetErrors, setError]
  );

  return {
    values,
    setError,
    errors,
    resetErrors,
    isSubmitting,
    setIsSubmitting,
    setValue,
    handleSubmit,
  };
};
