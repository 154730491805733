import * as React from 'react';
import { memo, useState } from 'react';
import styled, { ThemedStyledFunction } from 'styled-components';
import { IFontStyle, pickAlign, pickAlignFlex } from '@voomly/ui/player-deps';
import { pickVerticalAlign, IVerticalAlign } from '@voomly/ui/player-deps';
import { isUndefined } from 'lodash-es';
import tinycolor from '@ctrl/tinycolor';

interface IDynamicProps {
  buttonsScale: number; // Required prop: every component should be scalable

  borderRadius?: number;
  rounded?: number;

  borderColor?: string;
  borderSize?: number;

  backgroundColor?: string;
  buttonColor?: string;

  width?: number;
  height?: number;
  padding?: string;
  margin?: string;
  opacity?: number;
  zIndex?: number;

  fontSize?: number;
  fontStyle?: IFontStyle[];
  verticalAlign?: IVerticalAlign;
  color?: string;
  fontColor?: string;
}

export const dynamicVideoItemsProps = ({
  buttonsScale,

  borderRadius = 0,
  rounded,

  borderColor = 'transparent',
  borderSize = 0,

  buttonColor = 'none',
  backgroundColor,

  width,
  height,
  padding,
  margin,
  opacity,
  zIndex,

  fontSize = 14,
  fontStyle = ['align-left'],
  verticalAlign = 'vertical-align-top',
  color = '#fff',
  fontColor,
}: IDynamicProps): {
  style: React.CSSProperties;
} => ({
  style: {
    borderRadius:
      rounded || borderRadius
        ? `${Math.round((rounded || borderRadius) * buttonsScale)}px`
        : 0,
    borderColor,
    borderWidth: borderSize * buttonsScale,
    borderStyle: 'solid',
    backgroundColor: backgroundColor || buttonColor,
    color: fontColor || color,
    fill: fontColor || color, // Applies on svg elements
    width: width ? `${width * buttonsScale}px` : 'auto',
    height: height ? `${height * buttonsScale}px` : 'auto',
    padding: isUndefined(padding) ? `${5 * buttonsScale}px` : padding,
    margin: isUndefined(margin) ? 0 : margin,
    fontSize: `${fontSize * buttonsScale}px`,
    lineHeight: `${fontSize * 1.1 * buttonsScale}px`,
    fontWeight: fontStyle && fontStyle.includes('bold') ? 'bold' : undefined,
    fontStyle: fontStyle && fontStyle.includes('italic') ? 'italic' : undefined,
    textDecoration: `${fontStyle.includes('underline') ? 'underline' : ''} ${
      fontStyle.includes('line-through') ? 'line-through' : ''
    }`,

    justifyContent: pickAlignFlex(fontStyle),
    alignItems: pickVerticalAlign(verticalAlign),
    textAlign: pickAlign(fontStyle),
    opacity: opacity ? opacity / 100 : undefined,
    zIndex,
  },
});

export const createStyledDynamicVideoItemComponent = (
  item: ThemedStyledFunction<any, any, any, any>
) => {
  return item.attrs(dynamicVideoItemsProps);
};

const StyledDynamicButton = createStyledDynamicVideoItemComponent(
  styled.button
)<IDynamicProps>`
  cursor: pointer;
  outline: 0 !important;
  border: 0;
  box-sizing: border-box;
  appearance: none;
  white-space: normal;
  overflow: hidden;
  border-style: solid;
  /* user-select: none !important; */
  display: block; // Button shouldn't be anything else then 'block', since it's single element wrapper with other elements

  > span {
    display: flex;
    width: 100%;
    height: 100%;
    /* Button can't be display: flex */
    /* https://stackoverflow.com/questions/35464067/flex-grid-layouts-not-working-on-button-or-fieldset-elements */
    justify-content: ${({ fontStyle = ['align-left'] }) =>
      pickAlignFlex(fontStyle)};
    align-items: ${({ verticalAlign = 'vertical-align-top' }) =>
      pickVerticalAlign(verticalAlign)};
  }
`;

type IVideoItemCommonButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  IDynamicProps;

const VideoItemCommonButtonComponent = (
  props: IVideoItemCommonButtonProps,
  ref
) => {
  const [isOver, setIsOver] = useState(false);
  const bgColor = props.backgroundColor || props.buttonColor || '#008eff';

  return (
    <StyledDynamicButton
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      ref={ref as any}
      type="button"
      verticalAlign="vertical-align-center"
      fontStyle={['align-center']}
      {...props}
      backgroundColor={
        props.disabled
          ? tinycolor(bgColor).desaturate(70).toHexString()
          : isOver
          ? tinycolor(bgColor).desaturate(25).toHexString()
          : bgColor
      }
    >
      <span>{props.children}</span>
    </StyledDynamicButton>
  );
};

export const VideoItemCommonButton = memo(
  React.forwardRef<HTMLButtonElement, IVideoItemCommonButtonProps>(
    VideoItemCommonButtonComponent
  )
);

const StyledDynamicText = createStyledDynamicVideoItemComponent(styled.div)`
  display: inline-flex;
  box-sizing: border-box;
  white-space: normal;
  word-break: break-word;
  align-items: center;
  border-style: solid;
  position: relative;
  line-height: 1.3;
`;

export type IVideoItemTextProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  IDynamicProps;

export const VideoItemCommonText = memo(
  React.forwardRef<HTMLDivElement, IVideoItemTextProps>((props, ref) => {
    return <StyledDynamicText ref={ref as any} {...props} />;
  })
);
