import { useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';

export const useFontFamily = (fontFamily: string) => {
  const lastFontLink = useRef<string | undefined>('');
  const [fontFamilyLink, setFontFamilyLink] = useState<string | undefined>(
    undefined
  );

  useMount(() => {
    if (!fontFamily) return;

    if (document.getElementById('voomly-subtitles-google-fonts-preconnect')) {
      return;
    }
    const link = document.createElement('link');
    link.id = 'voomly-subtitles-google-fonts-preconnect';
    link.rel = 'preconnect';
    link.href = 'https://fonts.googleapis.com';
    document.head.appendChild(link);
  });

  useEffect(() => {
    if (!fontFamily) return;

    (async () => {
      const fontFamilyArray: Set<string> = new Set<string>();
      const createFontLinkStringByFamily = await import(
        './createFontLinkStringByFamily'
      );

      fontFamilyArray.add(
        createFontLinkStringByFamily.createFontLinkStringByFamily(fontFamily)
      );

      const linkHref = [
        'https://fonts.googleapis.com/css2?',
        Array.from(fontFamilyArray).join('&'),
        '&display=swap',
      ].join('');

      const linkGoogleFonts = document.getElementById(
        'voomly-subtitles-google-fonts'
      ) as HTMLLinkElement | undefined;

      if (linkHref === lastFontLink.current) {
        return lastFontLink.current;
      }
      lastFontLink.current = linkHref;

      if (linkGoogleFonts) {
        linkGoogleFonts.href = linkHref;
      } else {
        const link = document.createElement('link');
        link.id = 'google-fonts';
        link.rel = 'stylesheet';
        link.href = linkHref;

        document.head.appendChild(link);
      }

      setFontFamilyLink(linkHref);
    })();
  }, [fontFamily]);

  return fontFamilyLink;
};
